
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal);


const alert = (message, color, icon, closeTimer) => {

    MySwal.fire({
        title: <p className={'py-2 text-center font-weight-bold text-' + color}>{message}</p>,
        showCancelButton: true,
        cancelButtonText: 'بستن',
        showConfirmButton: false,
        icon: icon,
        timer: closeTimer ? closeTimer * 1000 : undefined
    });

}


export default alert;


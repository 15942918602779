export const repeatedCharRegExp = new RegExp(/^(.)\1{1,}$/);        
export const lengthRegExp = new RegExp(/^[^:]{2,12}$/);
export const persianCharsRegExp = new RegExp(/^[\u0600-\u06FF\s]+$/);
export const emailRegExp = new RegExp(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/);
export const phoneRegExp = new RegExp(/^09\d{9}$/);
export const hasPersianChars = (string) => {
    for (const x of string.replace(/\s/g, '')) {
        if (persianCharsRegExp.test(x)) {
            return true
        }
      
    }
}

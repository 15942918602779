import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import './Dashboard.css';
import Provinces from '../../Utils/Provinces';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  useLocation,
  Navigate
} from 'react-router-dom';




import moment from 'jalali-moment';




import { apiClient } from '../../Services/Api';


import { AuthContext } from '../../Context/AuthContext';
import { UserContext } from '../../Context/UserContext';


import { fixNumbers } from '../../Utils/FixNumbers';


import alert from '../../Components/Alert';
import { commas } from '../../Utils/Commas';



import { phoneRegExp, repeatedCharRegExp, lengthRegExp, persianCharsRegExp, emailRegExp } from '../../Utils/RegExp';




export default function Dashboard() {






  const Auth = useContext(AuthContext);
  const User = useContext(UserContext);


  const location = useLocation();
  const navigate = useNavigate();

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");

  const [details, setDetails] = useState("");
  const [postalCode, setPostalCode] = useState("");


  const [userAddresses, setUserAddresses] = useState([]);


  const [userOrders, setUserOrders] = useState([]);


  const [update, setUpdate] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);


  const [page, setPage] = useState(1);
  const [num, setNum] = useState(8);
  const [isEmpty, setIsEmpty] = useState(false);


  const [selectedOrder, setSelectedOrder] = useState(null);




  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');




  function hasPaidInvoice(data) {
    // Check if any invoice has a 'paid_at' value that is not null
    return data.invoices.some(invoice => invoice.paid_at !== null);
  }

  function getPaidInvoices(data) {
    // Filter and return all invoices that have a 'paid_at' value that is not null
    return data.invoices.filter(invoice => invoice.paid_at !== null);
  }





  useEffect(() => {

    if (User.data) {
      setFirstname(User.data.first_name);
      setLastname(User.data.last_name);
      setEmail(User.data.email);
    }

  }, [User.data]);




  const handleNavClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const handleProvinceChange = (e) => {
    setProvince(e.target.value);
    setCity(""); // Reset city when province changes
  };
  const selectedProvince = Provinces.find(p => p.name === province);



  // useEffect(() => {
  //     console.log(province, city);
  // }, [province, city]);



  useEffect(() => {

    // console.log(location.hash);

    if (location.hash === '#' || location.hash === '') {
      navigate('/dashboard#tab-dashboard', { replace: true });
    }

  }, []);





  useEffect(() => {




    if (location.hash === '#tab-orders') {



      setUserOrders(null);
      setIsEmpty(false);
      setIsLoading(true);

      setShowDetails(false);
      setSelectedOrder(null);



      // setTimeout(() => {


      //   setUserOrders([
      //     {
      //       "tracking_code": 531034083343,
      //       "shipment_type": "پست سفارشی",
      //       "cart_total_price": 2300000,
      //       "cart_payable_price": 1800000,
      //       "shipment_price": 400000,
      //       "discount_amount": 200000,
      //       "deposit_paid_at": "2024-09-26 11:23:23",
      //       "fully_paid_at": "2024-09-27 07:08:48",
      //       "is_payable": false,
      //       "created_at": "2024-09-26",
      //       "status": "در حال بررسی",
      //       "status_color": "#000000",
      //       "status_bg_color": "#fdce23",
      //       "address": {
      //         "id": "7e7a503b-a783-4ef8-a067-a30630b64516",
      //         "province": null,
      //         "city": null,
      //         "address": "ایلام - ایلام - خیابان سلیمان خاطر",
      //         "postal_code": "6931113445",
      //         "details": null,
      //         "landline": null,
      //         "latitude": null,
      //         "longitude": null
      //       },
      //       "items": [
      //         {
      //           "base_price ": 2000000,
      //           "discounted_base_price": 1500000,
      //           "quantity": 1,
      //           "product": {
      //             "name": "محصول 2",
      //             "code": "product-2",
      //             "category_title": null,
      //             "category_slug": null,
      //             "price": 2000000,
      //             "discounted_price": 1500000,
      //             "image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U.jpg",
      //             "extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q.jpg",
      //             "thumbnail": "https://tailor.7modir.com/storage/product/image/YBiVRaOxCcTvxCmRWqEJeif1pZhTqwSUVU6AbL5h.jpg",
      //             "inventory": 8
      //           },
      //           "attributes": [
      //             {
      //               "name": "دور ران",
      //               "slug": "dor-ran",
      //               "value": "30"
      //             },
      //             {
      //               "id": "13",
      //               "name": "رنگ",
      //               "slug": "color",
      //               "value": "قرمز",
      //               "extra_price": 0
      //             },
      //             {
      //               "name": "دور کمر",
      //               "slug": "dor-kamar",
      //               "value": "40"
      //             },
      //             {
      //               "id": 9,
      //               "name": "قد تنه",
      //               "slug": "qad-tane",
      //               "value": "50 سانت",
      //               "extra_price": 0
      //             },
      //             {
      //               "id": 5,
      //               "name": "سایز",
      //               "slug": "size",
      //               "value": "شخصی دوز",
      //               "extra_price": 300000
      //             }
      //           ]
      //         }
      //       ],
      //       "invoices": [              {
      //         "invoice_number": 804223057643858,
      //         "total_amount": 5700000,
      //         "payable_amount": 5600000,
      //         "paid_at": '2024-11-01',
      //         "status": "پرداخت نشده",
      //         "status_color": "#104a60",
      //         "status_bg_color": "#fdce23",
      //         "payment_url": "https://tailor.7modir.com/payment/start/dev/804223057643858"
      //       },
      //       {
      //         "invoice_number": 804223057643858,
      //         "total_amount": 5700000,
      //         "payable_amount": 5600000,
      //         "paid_at": '2024-12-01',
      //         "status": "پرداخت نشده",
      //         "status_color": "#104a60",
      //         "status_bg_color": "#fdce23",
      //         "payment_url": "https://tailor.7modir.com/payment/start/dev/804223057643858"
      //       }
      //     ]
      //     },
      //     {
      //       "tracking_code": 750961621606,
      //       "shipment_type": "پست پیشتاز",
      //       "cart_total_price": 12900000,
      //       "cart_payable_price": 11000000,
      //       "shipment_price": 300000,
      //       "discount_amount": 400000,
      //       "deposit_paid_at": "2024-09-26 10:38:09",
      //       "fully_paid_at": null,
      //       "is_payable": true,
      //       "created_at": "2024-09-26",
      //       "status": "در حال بررسی",
      //       "status_color": "#000000",
      //       "status_bg_color": "#fdce23",
      //       "address": {
      //         "id": "7e7a503b-a783-4ef8-a067-a30630b64516",
      //         "province": null,
      //         "city": null,
      //         "address": "ایلام - ایلام - خیابان سلیمان خاطر",
      //         "postal_code": "6931113445",
      //         "details": null,
      //         "landline": null,
      //         "latitude": null,
      //         "longitude": null
      //       },
      //       "items": [
      //         {
      //           "base_price ": 2000000,
      //           "discounted_base_price": 1500000,
      //           "quantity": 2,
      //           "product": {
      //             "name": "محصول 2",
      //             "code": "product-2",
      //             "category_title": null,
      //             "category_slug": null,
      //             "price": 2000000,
      //             "discounted_price": 1500000,
      //             "image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U.jpg",
      //             "extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q.jpg",
      //             "thumbnail": "https://tailor.7modir.com/storage/product/image/YBiVRaOxCcTvxCmRWqEJeif1pZhTqwSUVU6AbL5h.jpg",
      //             "inventory": 8
      //           },
      //           "attributes": [
      //             {
      //               "name": "دور ران",
      //               "slug": "dor-ran",
      //               "value": "30"
      //             },
      //             {
      //               "id": "14",
      //               "name": "رنگ",
      //               "slug": "color",
      //               "value": "آبی",
      //               "extra_price": 0
      //             },
      //             {
      //               "name": "دور کمر",
      //               "slug": "dor-kamar",
      //               "value": "40"
      //             },
      //             {
      //               "id": 1,
      //               "name": "سایز",
      //               "slug": "size",
      //               "value": "1",
      //               "extra_price": 0
      //             }
      //           ]
      //         },
      //         {
      //           "base_price ": 2000000,
      //           "discounted_base_price": 1500000,
      //           "quantity": 1,
      //           "product": {
      //             "name": "محصول 2",
      //             "code": "product-2",
      //             "category_title": null,
      //             "category_slug": null,
      //             "price": 2000000,
      //             "discounted_price": 1500000,
      //             "image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U.jpg",
      //             "extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q.jpg",
      //             "thumbnail": "https://tailor.7modir.com/storage/product/image/YBiVRaOxCcTvxCmRWqEJeif1pZhTqwSUVU6AbL5h.jpg",
      //             "inventory": 8
      //           },
      //           "attributes": [
      //             {
      //               "name": "دور ران",
      //               "slug": "dor-ran",
      //               "value": "30"
      //             },
      //             {
      //               "id": "13",
      //               "name": "رنگ",
      //               "slug": "color",
      //               "value": "قرمز",
      //               "extra_price": 0
      //             },
      //             {
      //               "name": "دور کمر",
      //               "slug": "dor-kamar",
      //               "value": "40"
      //             },
      //             {
      //               "id": 4,
      //               "name": "سایز",
      //               "slug": "size",
      //               "value": "4",
      //               "extra_price": 200000
      //             }
      //           ]
      //         },
      //         {
      //           "base_price ": 1500000,
      //           "discounted_base_price": 1300000,
      //           "quantity": 2,
      //           "product": {
      //             "name": "محصول 1",
      //             "code": "product-1",
      //             "category_title": null,
      //             "category_slug": null,
      //             "price": 1500000,
      //             "discounted_price": 1300000,
      //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
      //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
      //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
      //             "inventory": 1
      //           },
      //           "attributes": [
      //             {
      //               "id": 14,
      //               "name": "رنگ",
      //               "slug": "color",
      //               "value": "آبی",
      //               "extra_price": 0
      //             },
      //             {
      //               "id": "11",
      //               "name": "قد تنه",
      //               "slug": "qad-tane",
      //               "value": "70 سانت",
      //               "extra_price": 50000
      //             },
      //             {
      //               "name": "دور کمر",
      //               "slug": "dor-kamar",
      //               "value": "45"
      //             },
      //             {
      //               "id": "8",
      //               "name": "قد",
      //               "slug": "qad",
      //               "value": "120 سانت",
      //               "extra_price": 1500000
      //             },
      //             {
      //               "id": "5",
      //               "name": "سایز",
      //               "slug": "size",
      //               "value": "شخصی دوز",
      //               "extra_price": 300000
      //             }
      //           ]
      //         }
      //       ],
      //       "invoices": [
      //         {
      //           "invoice_number": 804223057643858,
      //           "total_amount": 5700000,
      //           "payable_amount": 5600000,
      //           "paid_at": null,
      //           "status": "پرداخت نشده",
      //           "status_color": "#104a60",
      //           "status_bg_color": "#fdce23",
      //           "payment_url": "https://tailor.7modir.com/payment/start/dev/804223057643858"
      //         }
      //       ]
      //     },
      //     {
      //       "tracking_code": 413901582034,
      //       "shipment_type": "پست سفارشی",
      //       "cart_total_price": 3350000,
      //       "cart_payable_price": 3150000,
      //       "shipment_price": 400000,
      //       "discount_amount": 300000,
      //       "deposit_paid_at": null,
      //       "fully_paid_at": "2024-09-26 11:17:04",
      //       "is_payable": false,
      //       "created_at": "2024-09-26",
      //       "status": "ارسال به مشتری",
      //       "status_color": "#000000",
      //       "status_bg_color": "#00c9d1",
      //       "address": {
      //         "id": "7e7a503b-a783-4ef8-a067-a30630b64516",
      //         "province": null,
      //         "city": null,
      //         "address": "ایلام - ایلام - خیابان سلیمان خاطر",
      //         "postal_code": "6931113445",
      //         "details": null,
      //         "landline": null,
      //         "latitude": null,
      //         "longitude": null
      //       },
      //       "items": [
      //         {
      //           "base_price ": 1500000,
      //           "discounted_base_price": 1300000,
      //           "quantity": 1,
      //           "product": {
      //             "name": "محصول 1",
      //             "code": "product-1",
      //             "category_title": null,
      //             "category_slug": null,
      //             "price": 1500000,
      //             "discounted_price": 1300000,
      //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
      //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
      //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
      //             "inventory": 1
      //           },
      //           "attributes": [
      //             {
      //               "id": 13,
      //               "name": "رنگ",
      //               "slug": "color",
      //               "value": "قرمز",
      //               "extra_price": 0
      //             },
      //             {
      //               "id": "11",
      //               "name": "قد تنه",
      //               "slug": "qad-tane",
      //               "value": "70 سانت",
      //               "extra_price": 50000
      //             },
      //             {
      //               "name": "دور کمر",
      //               "slug": "dor-kamar",
      //               "value": "40"
      //             },
      //             {
      //               "id": "8",
      //               "name": "قد",
      //               "slug": "qad",
      //               "value": "120 سانت",
      //               "extra_price": 1500000
      //             },
      //             {
      //               "id": "5",
      //               "name": "سایز",
      //               "slug": "size",
      //               "value": "شخصی دوز",
      //               "extra_price": 300000
      //             }
      //           ]
      //         }
      //       ],
      //       "invoices": []
      //     }
      //   ]);



      //   setIsLoading(false);


      // }, 300);




      Auth.maandaanaaLoggedIn && apiClient.get('order' + '?page=' + page + '&num=' + num).then(response => {
        if (response.request.status >= 200 || response.request.status <= 204) {
          !response.data.data.length && setIsEmpty(true);
          setUserOrders(response.data.data);
        }
      }).catch(error => {
        if (error.response) {
          // nothing
        }
      }).finally(() => {
        setIsLoading(false);
      });


    }

    if (location.hash === '#tab-address') {


      // setTimeout(() => {

      //     setUserAddresses(
      //         [
      //             {
      //                 id: 1,
      //                 address: 'ایلام - ایلام - سعدی - تختی',
      //                 postal_code: 12323123434
      //             },
      //             {
      //                 id: 2,
      //                 address: 'ایلام - ایلام - انقلاب - شمشاد',
      //                 postal_code: 8786867543
      //             },
      //         ]
      //     );

      // }, 300);

      Auth.maandaanaaLoggedIn && apiClient.get('address').then(response => {
        if (response.request.status === 200) {
          setUserAddresses(response.data.data);
        }
      }).catch(error => {
        if (error.response) {
          // nothing
        }
      }).finally(() => {
      });

    }



  }, [Auth.maandaanaaLoggedIn, location.hash, update, page]);



  const handleAddress = () => {

    if (province.length && city.length && (details.length > 5 && details.length < 150) && (postalCode.length === 10)) {

      // alert('ثبت موفق.', 'success', 'success', 3);

      apiClient.post('address', {
        address: province + ' - ' + city + ' - ' + details,
        postal_code: postalCode
      }).then(response => {
        if (response.request.status >= 200 || response.request.status <= 204) {

          alert('با موفقیت ثبت شد.', 'success', 'success', 3);

          setUpdate(setUpdate + 1);

          setDetails("");
          setPostalCode("");
        }
      }).catch(error => {

        alert('ثبت با خطا مواجه شد.', 'danger', 'error', 5);

        if (error.response) {
          // nothing
        }
      }).finally(() => {
      });


    } else {
      alert('لطفا اطلاعات را بررسی کنید. استان و شهر انتخاب شود. آدرس بیش از 5 کاراکتر، کدپستی 10 رقمی و بدون خط تیره باشد.', 'danger', 'error', 7);
    }

  }




  return (
    <CommonLAyout>

      <main className="main">
        <div className="page-header text-center" style={{ backgroundImage: "url('/images/page-header-bg.jpg')" }}>
          <div className="container">
            <h1 className="page-title text-center">داشبورد<span className="text-center">مزون ماندانا</span></h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" className="breadcrumb-nav mb-3">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">خانه</Link></li>
              {/* <li className="breadcrumb-item"><Link to="/categories"> </Link></li> */}
              <li className="breadcrumb-item active" aria-current="page">داشبورد</li>
            </ol>
          </div>
        </nav>
        <div className="page-content">
          <div className="dashboard">
            <div className="container">
              <div className="row">
                <aside className="col-md-4 col-lg-3">
                  <ul className="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
                    {['dashboard', 'orders', 'address', 'account'].map((tab, index) => (
                      <li className="nav-item" key={index}>
                        <Link
                          className={`nav-link ${location.hash === `#tab-${tab}` ? 'active' : ''}`}
                          to={`#tab-${tab}`}
                          role="tab"
                          aria-controls={`tab-${tab}`}
                          aria-selected={location.hash === `#tab-${tab}`}>
                          {tab === 'dashboard' && 'داشبورد'}
                          {tab === 'orders' && 'سفارشات'}
                          {tab === 'address' && 'آدرس های من'}
                          {tab === 'account' && 'ویرایش اطلاعات'}
                        </Link>
                      </li>
                    ))}
                    <li className="nav-item">
                      <Link className="nav-link" to="/" onClick={(e) => { if (Auth.maandaanaaLoggedIn) { e.preventDefault(); Auth.logout(); } }}>خروج از حساب کاربری</Link>
                    </li>
                  </ul>
                </aside>
                <div className="col-md-8 col-lg-9">
                  <div className="tab-content">
                    {['dashboard', 'orders', 'address', 'account'].map((tab, index) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${location.hash === `#tab-${tab}` ? 'show active' : ''}`}
                        id={`tab-${tab}`}
                        role="tabpanel"
                        aria-labelledby={`tab-${tab}-link`}>
                        {tab === 'dashboard' && (
                          <p>
                            سلام
                            <br />
                            <span className="font-weight-normal text-dark">کاربر عزیز</span>
                            <br />
                            شما در اینجا میتوانید
                            <Link to="/" onClick={(e) => handleNavClick(e, '/dashboard#tab-orders')} className="px-2 d-inline-block tab-trigger-link link-underline">سفارشات خود و وضعیت آنها را مشاهده و پیگیری کنید</Link>
                            ،
                            <br />
                            <Link to="/" onClick={(e) => handleNavClick(e, '/dashboard#tab-address')} className="px-2 d-inline-block tab-trigger-link link-underline">آدرس های خود را مدیریت کنید</Link>
                            و همچنین
                            <Link to="/" onClick={(e) => handleNavClick(e, '/dashboard#tab-account')} className="px-2 d-inline-block tab-trigger-link link-underline">می توانید مشخصات خود را مشاهده و ویرایش کنید</Link>.
                          </p>
                        )}
                        {
                          tab === 'orders' &&
                          (!showDetails
                            ?
                            <div>
                              <div className='text-center'>
                                {
                                  isLoading
                                    ?
                                    // <img src="/img/spinner.gif" style={{ width: '100px' }} />
                                    <div className="text-center">لطفا صبر کنید...</div>
                                    :
                                    (
                                      !userOrders
                                        ?
                                        <p className="text-center text-dark-blue bold">خطا در دریافت اطلاعات. دوباره امتحان کنید.</p>
                                        :
                                        (
                                          isEmpty
                                            ?
                                            <p className="text-center text-dark-blue bold">
                                              {
                                                page === 1
                                                  ?
                                                  'هیچ موردی یافت نشد.'
                                                  :
                                                  'مورد بیشتری یافت نشد.'
                                              }
                                            </p>
                                            :
                                            userOrders.map(
                                              order => {
                                                return (

                                                  <div className="shadow-sm d-flex justify-content-between align-items-start rounded rounded-sm p-3 bg-light my-3"
                                                  >
                                                    {/* {order.cart_payable_price} */}

                                                    <div className="x">



                                                      <div className="d-inline-block" style={{ fontSize: '10px', fontWeight: 'bold', padding: '1px 4px', borderRadius: '5px', color: order.status_color, backgroundColor: order.status_bg_color }}>
                                                        {order.status}
                                                      </div>


                                                      <div className="d-flex align-items-center mt-1">

                                                        <div className="small font-weight-bold px-1">شماره سفارش:</div>
                                                        <div className="font-weight-bold px-1">{order.tracking_code}</div>

                                                      </div>


                                                      <div className="d-flex align-items-center">

                                                        <div className="small font-weight-bold px-1">تاریخ سفارش:</div>
                                                        <div className="font-weight-bold px-1">{moment(order.created_at, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD')}</div>

                                                      </div>




                                                      <div className="d-flex">
                                                        {
                                                          order.items.map(
                                                            (item, i) => {
                                                              if (i < 2) {
                                                                return (


                                                                  <img className="img-thumbnail rounded rounded-sm d-inline-block mx-1" style={{ width: '50px', height: '50px' }} src={item.product.thumbnail} />
                                                                )
                                                              } else if (i === 2) {
                                                                return <div className="m-auto font-weight-bold">{(order.items.length - 2) + '+'}</div>
                                                              }
                                                            }
                                                          )
                                                        }
                                                      </div>




                                                    </div>
                                                    <div className="py-1 d-flex flex-column justify-content-between align-items-end">







                                                      <div className="d-flex align-items-center">
                                                        <div className="font-weight-bold px-1">{commas(order.cart_payable_price - order.discount_amount + order.shipment_price) + ' تومان'}</div>
                                                      </div>


                                                      <div className="btn btn-outline-primary-2 btn-order float-left btncart2" style={{ marginTop: '69px' }}
                                                        onClick={() => { setShowDetails(true); setSelectedOrder(order); }}
                                                      >

                                                        <span>مشاهده</span><i className="icon-long-arrow-left"></i>
                                                      </div>




                                                    </div>
                                                  </div>
                                                )
                                              }
                                            )



                                        )
                                    )
                                }
                              </div>
                              <div className="d-flex w-100 justify-content-between">
                                {/* <span
                                  className={'icon-lg opacity-hover pointer-hover transition ' + ((page === 1 || isLoading || !userOrders) && ' disabled')}
                                  style={{
                                    backgroundImage: `url("/svg/arrow-right-square.svg")`
                                  }}
                                  onClick={
                                    () => {
                                      if (page > 1 && !isLoading && userOrders) {
                                        setPage(page - 1)
                                      }
                                    }
                                  }
                                >
                                  قبل
                                </span> */}

                                <div className={'btn btn-outline-dark btn-rounded opacity-hover pointer-hover transition ' + ((page === 1 || isLoading || !userOrders) && ' disabled')}
                                  onClick={
                                    () => {
                                      if (page > 1 && !isLoading && userOrders) {
                                        setPage(page - 1)
                                      }
                                    }
                                  }
                                ><i className="icon-long-arrow-right"></i><span>قبل</span></div>

                                <div className={
                                  'btn btn-outline-dark btn-rounded opacity-hover pointer-hover transition ' +
                                  ((
                                    isEmpty ||
                                    (userOrders && userOrders.length < num) ||
                                    !userOrders ||
                                    isLoading
                                  )
                                    && ' disabled')
                                }
                                  onClick={
                                    () => {
                                      if (
                                        !isEmpty &&
                                        !isLoading &&
                                        userOrders &&
                                        userOrders.length === num
                                      ) {
                                        setPage(page + 1)
                                      }
                                    }
                                  }
                                ><span>بعد</span><i className="icon-long-arrow-left"></i></div>



                                {/* <span
                                  className={
                                    'icon-lg opacity-hover pointer-hover transition rotate-180 ' +
                                    ((
                                      isEmpty ||
                                      (userOrders && userOrders.length < num) ||
                                      !userOrders ||
                                      isLoading
                                    )
                                      && ' disabled')
                                  }
                                  style={{
                                    backgroundImage: `url("/svg/arrow-right-square.svg")`
                                  }}
                                  onClick={
                                    () => {
                                      if (
                                        !isEmpty &&
                                        !isLoading &&
                                        userOrders &&
                                        userOrders.length === num
                                      ) {
                                        setPage(page + 1)
                                      }
                                    }
                                  }
                                >
                                  صفحه بعد
                                </span> */}
                              </div>
                            </div>
                            :
                            <div>



                              {/* <div onClick={() => { setShowDetails(false) }}>برگشت</div> */}
                              <div className="float-none btn btn-outline-dark btn-rounded" onClick={() => { setShowDetails(false) }}>
                                <i className="icon-long-arrow-right"></i><span>برگشت</span>
                              </div>


                              <div className="mt-1 font-weight-bold">
                                جزییات سفارش:
                              </div>
                              <div className="shadow-sm bg-light rounded rounded-sm p-3 mb-1">

                                <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold px-1">قیمت عادی:</div>
                                  <div className="font-weight-bold px-1">{commas(selectedOrder.cart_total_price) + ' تومان'}</div>

                                </div>

                                <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold text-primary px-1">تخفیف ویژه:</div>
                                  <div className="font-weight-bold text-primary px-1">{commas(selectedOrder.cart_total_price - selectedOrder.cart_payable_price) + ' تومان'}</div>

                                </div>

                                <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold px-1">قیمت ویژه:</div>
                                  <div className="font-weight-bold px-1">{commas(selectedOrder.cart_payable_price) + ' تومان'}</div>

                                </div>

                                <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold text-primary px-1">تخفیف سفارش:</div>
                                  <div className="font-weight-bold text-primary px-1">{commas(selectedOrder.discount_amount) + ' تومان'}</div>

                                </div>

                                <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold px-1">هزینه ارسال:</div>
                                  <div className="font-weight-bold px-1">{commas(selectedOrder.shipment_price) + ' تومان'}</div>

                                </div>

                                <div className="px-2 py-1 d-flex justify-content-between align-items-center">

                                  <div className="font-weight-bold text-success px-1">قیمت نهایی:</div>
                                  <div className="font-weight-bold text-success px-1">{commas(selectedOrder.cart_payable_price + selectedOrder.shipment_price - selectedOrder.discount_amount) + ' تومان'}</div>

                                </div>

                              </div>


                              <div className="font-weight-bold">
                                وضعیت سفارش:
                              </div>

                              <div className="dates shadow-sm bg-light rounded rounded-sm p-3 mb-1">


                                <div className="px-2 mb-1 py-1 d-flex justify-content-between align-items-center">

                                  <div className="small font-weight-bold px-1"> وضعیت:</div>
                                  <div className="font-weight-bold px-1">
                                    {/* {selectedOrder.status} */}
                                    <div className="d-inline-block" style={{ fontSize: '12px', fontWeight: 'bold', padding: '1px 5px', borderRadius: '5px', color: selectedOrder.status_color, backgroundColor: selectedOrder.status_bg_color }}>
                                      {selectedOrder.status}
                                    </div>
                                  </div>

                                </div>


                                <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold px-1">شماره سفارش:</div>
                                  <div className="font-weight-bold px-1">{selectedOrder.tracking_code}</div>

                                </div>

                                <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold px-1">تاریخ سفارش:</div>
                                  <div className="font-weight-bold px-1">{moment(selectedOrder.created_at, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD')}</div>

                                </div>

                                
                                {selectedOrder.ship_follow_code ? <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold px-1">کد رهگیری مرسوله:</div>
                                  <div className="font-weight-bold px-1">{selectedOrder.tracking_code}</div>

                                </div> : ''}

                                {selectedOrder.sent_at ? <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                  <div className="small font-weight-bold px-1">تاریخ ارسال:</div>
                                  <div className="font-weight-bold px-1">{moment(selectedOrder.sent_at, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD')}</div>

                                </div> : ''}
                                {/* {
                                  selectedOrder.deposit_paid_at
                                    ?
                                    <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                      <div className="small font-weight-bold px-1">تاریخ پیش پرداخت:</div>
                                      <div className="font-weight-bold px-1">{moment(selectedOrder.deposit_paid_at, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD')}</div>

                                    </div>
                                    :
                                    ''
                                } */}

                                {/* {
                                  selectedOrder.fully_paid_at
                                    ?
                                    <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">

                                      <div className="small font-weight-bold px-1">تاریخ تسویه:</div>
                                      <div className="font-weight-bold px-1">{moment(selectedOrder.fully_paid_at, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD')}</div>

                                    </div>
                                    :
                                    ''
                                } */}

                              </div>

                              {
                                (selectedOrder.is_payable && selectedOrder.invoices.length)
                                  ?
                                  <div className="x">
                                    <div className="y font-weight-bold">
                                      بدهی سفارش:
                                    </div>
                                    {
                                      selectedOrder.invoices.map(
                                        invoice => {
                                          if (!invoice.paid_at) {
                                            return (
                                              <div className="shadow-sm rounded rounded-sm p-3 mt-0 mb-1" style={{ backgroundColor: 'rgba(248, 255, 0,  0.1)' }}>

                                                <div className="d-flex justify-content-between align-items-center">

                                                  <div className="d-flex flex-column align-items-start">
                                                    <div className="font-weight-bold px-1">مبلغ فاکتور:</div>
                                                    <div className="font-weight-bold px-1">{commas(invoice.payable_amount) + ' تومان'}</div>
                                                  </div>


                                                  <div className="btn btn-outline-primary-2 btn-order mt-0 float-left btncart2"
                                                    onClick={() => { window.location.assign(invoice.payment_url) }}
                                                  >

                                                    <span>پرداخت</span><i className="icon-long-arrow-left"></i>
                                                  </div>


                                                </div>
                                                {/* 
                                                <div className="text-center" onClick={() => { window.location.assign(invoice.payment_url) }}>
                                                  پرداخت
                                                </div> */}

                                              </div>
                                            )
                                          }
                                        }
                                      )
                                    }
                                  </div>
                                  :
                                  ''
                              }

                              {
                                (hasPaidInvoice(selectedOrder))
                                  ?
                                  <div className="x">
                                    <div className="y font-weight-bold">
                                      تراکنش های سفارش:
                                    </div>
                                    {
                                      getPaidInvoices(selectedOrder).map(
                                        invoice => {
                                          if (true) {
                                            return (
                                              <div className="shadow-sm rounded rounded-sm p-3 mt-0 mb-1" style={{ backgroundColor: '#e3ffde' }}>

                                                <div className="d-flex justify-content-between align-items-center">

                                                  <div className="d-flex flex-column align-items-start">
                                                    <div className="font-weight-bold px-1">مبلغ:</div>
                                                    <div className="font-weight-bold px-1">{commas(invoice.payable_amount) + ' تومان'}</div>
                                                  </div>


                                                  <div className="d-flex flex-column align-items-start">
                                                    <div className="font-weight-bold px-1">تاریخ:</div>
                                                    <div className="font-weight-bold px-1">{moment(invoice.paid_at, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD')}</div>
                                                  </div>


                                                  {/* <div className="btn btn-outline-primary-2 btn-order mt-0 float-left btncart2"
                                                    onClick={() => { window.location.assign(invoice.payment_url) }}
                                                  >

                                                    <span>پرداخت</span><i className="icon-long-arrow-left"></i>
                                                  </div> */}


                                                </div>
                                                {/* 
                                                <div className="text-center" onClick={() => { window.location.assign(invoice.payment_url) }}>
                                                  پرداخت
                                                </div> */}

                                              </div>
                                            )
                                          }
                                        }
                                      )
                                    }
                                  </div>
                                  :
                                  ''
                              }




                              <div className="x">
                                <div className="font-weight-bold">
                                  سبد محصول:
                                </div>
                                <div className="x">
                                  {
                                    selectedOrder.items.map(item => {
                                      // Destructure necessary properties from the item
                                      const { base_price, discounted_base_price, attributes, quantity, product } = item;



                                      // Calculate extra prices from attributes
                                      const extraPrices = attributes.reduce((total, attribute) => {
                                        return total + (attribute.extra_price || 0);
                                      }, 0);

                                      // Calculate total price based on the existence of discounted_base_price
                                      const firstTotalPrice = (base_price) + extraPrices;
                                      const totalPrice = (discounted_base_price || base_price) + extraPrices;

                                      return (
                                        <div className="bg-light shadow-sm rounded rounded-sm p-3 my-1" key={product.code}>
                                          <div className="d-flex align-items-center">


                                            <div className="w-100">
                                              {/* Product Name */}

                                              <Link to={'/product?slug=' + product.code}><div className="font-weight-bold text-primary">{product.name}</div></Link>


                                              {/* Product Attributes */}
                                              {/* <ul>
                                                {attributes.map(attribute => (
                                                  <li key={attribute.slug}>
                                                    {attribute.name}: {attribute.value}
                                                  </li>
                                                ))}
                                              </ul> */}


                                              {/* Product Attributes */}
                                              <span>
                                                {attributes.map(attribute => `${attribute.name}: ${attribute.value}`).join(' ︱ ')}
                                              </span>





                                              <div className="d-flex justify-content-between align-items-end">

                                                {/* Product Image */}
                                                <Link to={'/product?slug=' + product.code}>
                                                  <img
                                                    src={product.thumbnail}
                                                    alt={product.name}
                                                    style={{ width: '75px', height: '75px' }}
                                                    className="img-thumbnail ml-3"
                                                  />
                                                </Link>

                                                <div className="d-flex flex-column align-items-end">

                                                  {/* Total Price and Quantity */}
                                                  <p className="position-relative font-weight-bold mb-0">
                                                    {(firstTotalPrice > totalPrice) ? <div className="old-price small font-weight-bold"
                                                      style={{ position: 'absolute', left: '0px', top: '-16px' }}>{commas(firstTotalPrice)}</div> : ''}
                                                    قیمت: {commas(totalPrice)} تومان
                                                  </p>
                                                  <p className="font-weight-bold mb-0">
                                                    تعداد: {quantity}
                                                  </p>

                                                </div>

                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  }

                                </div>
                              </div>



                              <div className="x">

                                <div className="mt-1 font-weight-bold">
                                  نشانی مرسوله:
                                </div>

                                <div className="shadow-sm bg-light rounded rounded-sm p-3 mt-0 mb-1">
                                  <div className="px-2 py-1 d-flex justify-content-between align-items-center border-bottom">
                                    <div className="small font-weight-bold px-1">آدرس:</div>
                                    <div className="font-weight-bold px-1">{selectedOrder.address.address}</div>
                                  </div>
                                  <div className="px-2 py-1 d-flex justify-content-between align-items-center">
                                    <div className="small font-weight-bold px-1">کد پستی:</div>
                                    <div className="font-weight-bold px-1">{selectedOrder.address.postal_code}</div>
                                  </div>
                                </div>

                              </div>


                            </div>
                          )

                        }
                        {tab === 'address' && (

                          <>
                            <ul className="list-group">
                              {
                                userAddresses && userAddresses.length
                                  ?
                                  userAddresses.map((a, i) => {
                                    return (<li key={a.id} className={'list-group-item d-flex align-items-center justify-content-between ' + (!(i % 2) ? 'list-group-item-light' : 'list-group-item-secondary')}>
                                      <div className="d-flex flex-column">
                                        <span>{a.address}</span>
                                        <span className="font-weight-bold">{'کدپستی: ' + a.postal_code}</span>
                                      </div>
                                      <div className="btn btn-primary btn-rounded" style={{ minWidth: '40px', padding: '.5rem' }} onClick={() => {
                                        apiClient.delete('address/' + a.id).then(response => {
                                          if (response.request.status >= 200 || response.request.status <= 204) {
                                            alert('با موفقیت حذف شد.', 'success', 'success', 3);
                                            setUpdate(update + 1);
                                          }
                                        }).catch(error => {
                                          alert('ثبت با خطا مواجه شد.', 'danger', 'error', 5);
                                          if (error.response) {
                                            // nothing
                                          }
                                        }).finally(() => {
                                        });
                                      }}>×</div>
                                    </li>)
                                  })
                                  :
                                  <p className="text-center font-weight-bold my-2">آدرس ثبت شده ای وجود ندارد.</p>
                              }
                            </ul>



                            <div>

                              <div>
                                <h4>ثبت آدرس جدید:</h4>
                                <div className="details-filter-row">
                                  <label htmlFor="province" style={{ width: '70px' }}>استان:</label>
                                  <div className="select-custom ml-4">
                                    <select name="province" id="province" defaultValue="" className="form-control" onChange={handleProvinceChange}>
                                      <option value="" disabled>انتخاب کنید</option>
                                      {
                                        Provinces.map(province => (
                                          <option key={'province-' + province.id} value={province.name}>{province.name}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                </div>
                                <div className="details-filter-row">
                                  <label htmlFor="city" style={{ width: '70px' }}>شهرستان:</label>
                                  <div className="select-custom ml-4">
                                    <select name="city" id="city" value={city} className="form-control" onChange={(e) => { setCity(e.target.value) }}>
                                      <option value="" disabled>انتخاب کنید</option>
                                      {
                                        selectedProvince ? selectedProvince.cities.map(city => (
                                          <option key={'city-' + city.id} value={city.name}>{city.name}</option>
                                        )) : null
                                      }
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <label>آدرس:</label>
                                    <input type="text" value={details} onChange={e => setDetails(e.target.value)} className="form-control" />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <label>کدپستی:</label>
                                    <input type="text" value={postalCode} onChange={e => setPostalCode(fixNumbers(e.target.value))} className="form-control" />
                                  </div>
                                </div>

                                <button type="button" className="btn btn-primary btn-rounded mx-auto" onClick={handleAddress}>ثبت</button>

                              </div>


                            </div>

                          </>







                          // <>
                          //     <p>آدرسی که اینجا ثبت شده است برای ارسال محصولات به شما استفاده می شود.</p>
                          //     <div className="row">
                          //         <div className="col-lg-12">
                          //             <div className="card card-dashboard">
                          //                 <div className="card-body">
                          //                     <h3 className="card-title">اطلاعات شما</h3>
                          //                     <p>
                          //                         نام و نام خانوادگی: زهرا رحیمی
                          //                         <br />
                          //                         شماره همراه: 09181436267
                          //                         <br />
                          //                         آدرس: تهران - خیابان آزادی - پلاک 7
                          //                         <br />
                          //                         کد پستی: 6931975677
                          //                         <br />
                          //                         ایمیل: yourmail@mail.com
                          //                         <br />
                          //                         <Link to="/" onClick={(e) => handleNavClick(e, '/dashboard#tab-account')} className="tab-trigger-link">ویرایش <i className="icon-edit"></i></Link>
                          //                     </p>
                          //                 </div>
                          //             </div>
                          //         </div>
                          //     </div>
                          // </>
                        )}
                        {tab === 'account' && (
                          <div>
                            {(User && User.data) ? <>

                              <div className="row">
                                <div className="col-sm-6">
                                  <label>نام *</label>
                                  <input type="text" className="form-control" value={firstname} onChange={e => setFirstname(e.target.value)} />
                                </div>
                                <div className="col-sm-6">
                                  <label>نام خانوادگی *</label>
                                  <input type="text" className="form-control" value={lastname} onChange={e => setLastname(e.target.value)} />
                                </div>
                              </div>
                              <div className="row">
                                {/* <div className="col-sm-6">
                                <label>شماره همراه *</label>
                                <input type="tel" className="form-control" value="09181436267" readOnly required />
                              </div> */}
                                <div className="col-sm-6">
                                  <label>ایمیل</label>
                                  <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                              </div>
                              {/* <label>آدرس *</label>
                            <input type="text" className="form-control" required />
                            <div className="row">
                              <div className="col-sm-6">
                                <label>کدپستی *</label>
                                <input type="number" className="form-control" required />
                              </div>
                            </div> */}
                              <button type="button" className="btn btn-outline-primary-2 float-left"

                                onClick={() => {

                                  let validFirstname = (!repeatedCharRegExp.test(firstname) && lengthRegExp.test(firstname) && persianCharsRegExp.test(firstname));
                                  let validLastname = (!repeatedCharRegExp.test(lastname) && lengthRegExp.test(lastname) && persianCharsRegExp.test(lastname));
                                  let validEmail = emailRegExp.test(email);
                                  if (validFirstname && validLastname && validEmail && !isLoading) {
                                    setIsLoading(true);


                                    apiClient.put('profile', {
                                      first_name: firstname,
                                      last_name: lastname,
                                      email: email,
                                    }).then(response => {

                                      if (response.request.status >= 200 && response.request.status <= 204) {


                                        alert('با موفقیت ثبت شد.', 'success', 'success', 3);


                                      }

                                    }).catch(error => {

                                      alert('ثبت با خطا مواجه شد.', 'danger', 'error', 5);

                                    }).finally(
                                      () => {
                                        setIsLoading(false);
                                      }
                                    );


                                  } else {
                                    alert('در ورود اطلاعات دقت کنید!', 'danger', 'error', 5);
                                  }
                                }}

                              >
                                <span>ذخیره تغییرات</span>
                                <i className="icon-long-arrow-left"></i>
                              </button>
                            </> : ''}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </CommonLAyout>
  );
};
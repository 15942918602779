import { useState, createContext, useContext, useEffect } from "react";
import {AuthContext}  from './AuthContext';
import { apiClient } from '../Services/Api';
export const UserContext = createContext();
export function UserProvider({ children }) {
    const Auth = useContext(AuthContext);
    const [data, setData] = useState(null);
    const getData = () => {
        if (Auth.maandaanaaLoggedIn) {

            // setTimeout(() => {
            //     setData(
            //         {
            //             "first_name": "محمد",
            //             "last_name": "استکی",
            //             "email": null,
            //             "mobile": "09188450740",
            //             "is_known": true
            //           }
            //     );
            // }, 350);


            apiClient.get('profile').then(response => {
                if (response.request.status >= 200 && response.request.status <= 204) {
                    if ( JSON.stringify(data) !== JSON.stringify(response.data.data) ) {
                        setData(response.data.data);
                    }
                } else {
                    if (!Auth.checkLogin) {
                        Auth.logout();
                    }
                }
            }).catch(error => {
                if (!Auth.checkLogin) {
                    Auth.logout();
                }
                if (error.response && error.response.request.status === 401) {
                    Auth.logout();
                }
            });



        }
    }
    return (
        <UserContext.Provider value={{data, getData}}>
            {children}
        </UserContext.Provider>
    );
}

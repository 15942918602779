import { React, useState, useEffect, useContext, useRef } from 'react';
import {
    Link
} from 'react-router-dom';


import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';



import { apiClient } from '../../Services/Api';


import { commas } from '../../Utils/Commas';


export default function Popular() {

    const [tab, setTab] = useState(1);
    const [all, setAll] = useState([]);
    const [popular, setPopular] = useState([]);
    const [randoms, setRandoms] = useState([]);
    const [categories, setCategories] = useState([]);
    const [slug, setSlug] = useState('');
    const [randomTitle, setRandomTitle] = useState('');


    const carouselRef1 = useRef(null);
    const carouselRef2 = useRef(null);
    const carouselRef3 = useRef(null);




    useEffect(() => {


        // setTimeout(() => {
        //     setAll([
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 0,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 0
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 0,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 0
        //         }
        //     ]
        //     );
        // }, 150);


        apiClient.get('product?page=1&num=12').then(response => {
            if (response.request.status === 200) {
                setAll(response.data.data);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });


        apiClient.get('category').then(response => {
            if (response.request.status === 200) {
                setCategories(response.data.data);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });




    }, []);



    useEffect(() => {
        if (categories.length) {
            setSlug(selectRandomSlug(categories));
        }
    }, [categories]);

    useEffect(() => {
        if (slug.length) {

            apiClient.get('product?page=1&num=12&category=' + slug).then(response => {
                if (response.request.status === 200) {
                    setRandoms(response.data.data);
                }
            }).catch(error => {
                if (error.response) {
                    // nothing
                }
            }).finally(() => {
            });

            apiClient.get('product?page=1&num=12&popular=1').then(response => {
                if (response.request.status === 200) {
                    setPopular(response.data.data);
                }
            }).catch(error => {
                if (error.response) {
                    // nothing
                }
            }).finally(() => {
            });

            setRandomTitle(findTitleBySlug(categories, slug));

        }
    }, [slug]);




    function findMatchingSlugs(data) {
        let matchingSlugs = [];

        data.forEach(item => {
            if ((item.attributes && item.attributes.length) && !(item.children && item.children.length)) {
                matchingSlugs.push(item.slug);
            }
            if (item.children && item.children.length > 0) {
                matchingSlugs = matchingSlugs.concat(findMatchingSlugs(item.children));
            }
        });

        return matchingSlugs;
    }

    function selectRandomSlug(data) {
        const matchingSlugs = findMatchingSlugs(data);

        if (matchingSlugs.length === 0) {
            return null;
        }

        const randomIndex = Math.floor(Math.random() * matchingSlugs.length);
        return matchingSlugs[randomIndex];
    }


    function findTitleBySlug(data, slug) {
        for (const item of data) {
            if (item.slug === slug) {
                return item.title;
            }
            if (item.children && item.children.length > 0) {
                const childTitle = findTitleBySlug(item.children, slug);
                if (childTitle) {
                    return childTitle;
                }
            }
        }
        return null;
    }






    const options = {
        items: 4,
        margin: 20,
        loop: false,
        rtl: true,
        nav: false,
        dots: false,
        responsive: {
            0: { items: 2 },
            480: { items: 2 },
            768: { items: 3 },
            992: { items: 4 },
            1200: { items: 4 }
        }
    };



    return (
        <div className="container">

            <div className="heading heading-center mb-3">
                <h2 className="title text-center">محصولات برتر</h2>

                <ul className="nav nav-pills justify-content-center" role="tablist">
                    <li className="nav-item">
                        <span className={(tab === 1) ? 'nav-link pointer-hover opacity-hover active' : 'nav-link pointer-hover opacity-hover'} onClick={() => { setTab(1); }}>همه</span>
                    </li>
                    <li className="nav-item">
                        <span className={(tab === 2) ? 'nav-link pointer-hover opacity-hover active' : 'nav-link pointer-hover opacity-hover'} onClick={() => { setTab(2); }}>محبوب</span>
                    </li>
                    <li className="nav-item">
                        <span className={(tab === 3) ? 'nav-link pointer-hover opacity-hover active' : 'nav-link pointer-hover opacity-hover'} onClick={() => { setTab(3); }}>{randomTitle}</span>
                    </li>
                </ul>
            </div>

            <div className="tab-content tab-content-carousel">
                <div className={(tab === 1) ? 'tab-pane p-0 fade show active' : 'tab-pane p-0 fade'}>



                    <div className="custom-carousel-container">

                        {(all.length > 3) ? <div style={{ minWidth: 'auto', fontSize: '1.75rem', padding: '.5rem 0', lineHeight: '1rem' }} onClick={() => carouselRef1.current.prev()} className="mt-0 custom-nav custom-nav-left btn btn-outline-primary-2 btn-order float-left btncart2"><i className="icon-long-arrow-left"></i></div> : ''}

                        <OwlCarousel options={options} ref={carouselRef1}>

                            {
                                all.length
                                    ?
                                    <>
                                        {
                                            all.map(item => {
                                                return <div className="product product-7 text-center">
                                                    <figure className="product-media">
                                                        {
                                                            (item.inventory && item.discounted_price)
                                                                ?
                                                                <span className="product-label label-sale">فروش ویژه</span>
                                                                :
                                                                ''
                                                        }
                                                        <Link to={'/product?slug=' + item.code}>
                                                            <img src={item.image} alt="تصویر محصول" className="product-image" />
                                                            <img src={item.extra_image} alt="تصویر محصول" className="product-image-hover" />
                                                        </Link>

                                                        <div className="product-action-vertical">
                                                            <Link to="#" className="btn-product-icon btn-wishlist btn-expandable"><span>افزودن به لیست علاقه مندی</span></Link>
                                                        </div>

                                                        <div className="product-action">
                                                            <Link to={'/product?slug=' + item.code} className="btn-product btn-cart"><span>افزودن به سبد خرید</span></Link>
                                                        </div>
                                                    </figure>

                                                    <div className="product-body">
                                                        <div className="product-cat text-center">
                                                            <Link to={'/categories?slug=' + item.category_slug}>{item.category_title}</Link>
                                                        </div>
                                                        <h3 className="product-title text-center"><Link to={'/product?slug=' + item.code}>{item.name}</Link></h3>
                                                        {
                                                            item.inventory
                                                                ?
                                                                (
                                                                    item.discounted_price
                                                                        ?
                                                                        <div className="product-price">
                                                                            <span className="new-price">{commas(parseInt(item.discounted_price)) + ' تومان'}</span>
                                                                            <span className="old-price">{commas(parseInt(item.price))}</span>
                                                                        </div>
                                                                        :
                                                                        <div className="product-price">
                                                                            <div className="new-price">
                                                                                {commas(parseInt(item.price)) + ' تومان'}
                                                                            </div>
                                                                        </div>

                                                                )
                                                                :
                                                                <div className="product-price">
                                                                    <div className="new-price">
                                                                        ناموجود
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            )
                                        }
                                    </>
                                    :
                                    ''
                            }

                        </OwlCarousel>


                        {(all.length > 3) ? <div style={{ minWidth: 'auto', fontSize: '1.75rem', padding: '.5rem 0', lineHeight: '1rem' }} onClick={() => carouselRef1.current.next()} className="mt-0 custom-nav custom-nav-right btn btn-outline-primary-2 btn-order float-left btncart2"><i className="icon-long-arrow-right"></i></div> : ''}

                    </div>


                </div>
                <div className={(tab === 2) ? 'tab-pane p-0 fade show active' : 'tab-pane p-0 fade'}>


                    <div className="custom-carousel-container">

                        {(popular.length > 3) ? <div style={{ minWidth: 'auto', fontSize: '1.75rem', padding: '.5rem 0', lineHeight: '1rem' }} onClick={() => carouselRef2.current.prev()} className="mt-0 custom-nav custom-nav-left btn btn-outline-primary-2 btn-order float-left btncart2"><i className="icon-long-arrow-left"></i></div> : ''}


                        <OwlCarousel options={options} ref={carouselRef2}>

                            {
                                popular.length
                                    ?
                                    <>
                                        {
                                            popular.map(item => {
                                                return <div className="product product-7 text-center">
                                                    <figure className="product-media">
                                                        {
                                                            (item.inventory && item.discounted_price)
                                                                ?
                                                                <span className="product-label label-sale">فروش ویژه</span>
                                                                :
                                                                ''
                                                        }
                                                        <Link to={'/product?slug=' + item.code}>
                                                            <img src={item.image} alt="تصویر محصول" className="product-image" />
                                                            <img src={item.extra_image} alt="تصویر محصول" className="product-image-hover" />
                                                        </Link>

                                                        <div className="product-action-vertical">
                                                            <Link to="#" className="btn-product-icon btn-wishlist btn-expandable"><span>افزودن به لیست علاقه مندی</span></Link>
                                                        </div>

                                                        <div className="product-action">
                                                            <Link to={'/product/' + item.code} className="btn-product btn-cart"><span>افزودن به سبد خرید</span></Link>
                                                        </div>
                                                    </figure>

                                                    <div className="product-body">
                                                        <div className="product-cat text-center">
                                                            <Link to={'/categories?slug=' + item.category_slug}>{item.category_title}</Link>
                                                        </div>
                                                        <h3 className="product-title text-center"><Link to={'/product?slug=' + item.code}>{item.name}</Link></h3>
                                                        {
                                                            item.inventory
                                                                ?
                                                                (
                                                                    item.discounted_price
                                                                        ?
                                                                        <div className="product-price">
                                                                            <span className="new-price">{commas(parseInt(item.discounted_price)) + ' تومان'}</span>
                                                                            <span className="old-price">{commas(parseInt(item.price))}</span>
                                                                        </div>
                                                                        :
                                                                        <div className="product-price">
                                                                            <div className="new-price">
                                                                                {commas(parseInt(item.price)) + ' تومان'}
                                                                            </div>
                                                                        </div>

                                                                )
                                                                :
                                                                <div className="product-price">
                                                                    <div className="new-price">
                                                                        ناموجود
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            )
                                        }
                                    </>
                                    :
                                    ''
                            }

                        </OwlCarousel>

                        {(popular.length > 3) ? <div style={{ minWidth: 'auto', fontSize: '1.75rem', padding: '.5rem 0', lineHeight: '1rem' }} onClick={() => carouselRef2.current.next()} className="mt-0 custom-nav custom-nav-right btn btn-outline-primary-2 btn-order float-left btncart2"><i className="icon-long-arrow-right"></i></div> : ''}

                    </div>



                </div>

                <div className={(tab === 3) ? 'tab-pane p-0 fade show active' : 'tab-pane p-0 fade'}>

                    <div className="custom-carousel-container">

                        {(randoms.length > 3) ? <div style={{ minWidth: 'auto', fontSize: '1.75rem', padding: '.5rem 0', lineHeight: '1rem' }} onClick={() => carouselRef3.current.prev()} className="mt-0 custom-nav custom-nav-left btn btn-outline-primary-2 btn-order float-left btncart2"><i className="icon-long-arrow-left"></i></div> : ''}

                        <OwlCarousel options={options} ref={carouselRef3}>

                            {
                                randoms.length
                                    ?
                                    <>
                                        {
                                            randoms.map(item => {
                                                return <div className="product product-7 text-center">
                                                    <figure className="product-media">
                                                        {
                                                            (item.inventory && item.discounted_price)
                                                                ?
                                                                <span className="product-label label-sale">فروش ویژه</span>
                                                                :
                                                                ''
                                                        }
                                                        <Link to={'/product?slug=' + item.code}>
                                                            <img src={item.image} alt="تصویر محصول" className="product-image" />
                                                            <img src={item.extra_image} alt="تصویر محصول" className="product-image-hover" />
                                                        </Link>

                                                        <div className="product-action-vertical">
                                                            <Link to="#" className="btn-product-icon btn-wishlist btn-expandable"><span>افزودن به لیست علاقه مندی</span></Link>
                                                        </div>

                                                        <div className="product-action">
                                                            <Link to={'/product/' + item.code} className="btn-product btn-cart"><span>افزودن به سبد خرید</span></Link>
                                                        </div>
                                                    </figure>

                                                    <div className="product-body">
                                                        <div className="product-cat text-center">
                                                            <Link to={'/categories?slug=' + item.category_slug}>{item.category_title}</Link>
                                                        </div>
                                                        <h3 className="product-title text-center"><Link to={'/product?slug=' + item.code}>{item.name}</Link></h3>
                                                        {
                                                            item.inventory
                                                                ?
                                                                (
                                                                    item.discounted_price
                                                                        ?
                                                                        <div className="product-price">
                                                                            <span className="new-price">{commas(parseInt(item.discounted_price)) + ' تومان'}</span>
                                                                            <span className="old-price">{commas(parseInt(item.price))}</span>
                                                                        </div>
                                                                        :
                                                                        <div className="product-price">
                                                                            <div className="new-price">
                                                                                {commas(parseInt(item.price)) + ' تومان'}
                                                                            </div>
                                                                        </div>

                                                                )
                                                                :
                                                                <div className="product-price">
                                                                    <div className="new-price">
                                                                        ناموجود
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            )
                                        }
                                    </>
                                    :
                                    ''
                            }

                        </OwlCarousel>

                        {(randoms.length > 3) ? <div style={{ minWidth: 'auto', fontSize: '1.75rem', padding: '.5rem 0', lineHeight: '1rem' }} onClick={() => carouselRef3.current.next()} className="mt-0 custom-nav custom-nav-right btn btn-outline-primary-2 btn-order float-left btncart2"><i className="icon-long-arrow-right"></i></div> : ''}

                    </div>

                </div>

            </div>
        </div>

    );
};
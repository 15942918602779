import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import './About.css';


import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
    useParams,
    useLocation,
    Navigate
  } from 'react-router-dom';
  
  


export default function About() {


    return (
        <CommonLAyout>



<main className="main">
      <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/index-1">خانه</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/categories">فروشگاه</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              درباره ما
            </li>
          </ol>
        </div>
      </nav>
      <div className="container">
        <div
          className="page-header page-header-big text-center"
          style={{ backgroundImage: "url('/images/about-header-bg.jpg')" }}
        >
          <h1 className="page-title text-white text-center">
            درباره ما<span className="text-white text-center">مزون ماندانا</span>
          </h1>
        </div>
      </div>

      <div className="page-content pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <h2 className="title">دید ما</h2>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم، لورم ایپسوم متن ساختگی با تولید سادگی
                نامفهوملورم ایپسوم متن ساختگی با تولید سادگی نامفهوم، لورم ایپسوم متن ساختگی با تولید
                سادگی نامفهوم. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با
                تولید سادگی نامفهوم! لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم.
              </p>
            </div>

            <div className="col-lg-6">
              <h2 className="title">ماموریت ما</h2>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی
                نامفهوم، لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم. لورم ایپسوم متن ساختگی با تولید
                سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با
                تولید سادگی نامفهوم! لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم.
              </p>
            </div>
          </div>

          <div className="mb-5"></div>
        </div>

        <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mb-3 mb-lg-0">
                <h2 className="title">ما که هستیم</h2>
                <p className="lead text-primary mb-3">
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                  <br />
                  لورم ایپسوم متن ساختگی با تولید سادگی
                </p>
                <p className="mb-2">
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید
                  سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن
                  ساختگی با تولید سادگی نامفهوم.
                </p>

                <Link to="/categories" className="btn btn-sm btn-minwidth btn-outline-primary-2 float-right">
                  <span>مشاهده محصولات</span>
                  <i className="icon-long-arrow-left"></i>
                </Link>
              </div>

              <div className="col-lg-6 offset-lg-1">
                <div className="about-images">
                  <img src="/images/about/img-1.jpg" alt="" className="about-img-front" />
                  <img src="/images/about/img-2.jpg" alt="" className="about-img-back" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <hr className="mt-4 mb-6" />

          <h2 className="title text-center mb-4">آشنایی با تیم ما</h2>

          <div className="row">
            <div className="col-md-4">
              <div className="member member-anim text-center">
                <figure className="member-media">
                  <img src="/images/team/member-1.jpg" alt="توضیح عکس" />

                  <figcaption className="member-overlay">
                    <div className="member-overlay-content">
                      <h3 className="member-title">
                        نرگس رسولی<span>جستجو و سئو</span>
                      </h3>
                      <p>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم، لورم ایپسوم متن ساختگی با
                        تولید سادگی نامفهوم
                      </p>
                      <div className="social-icons social-icons-simple">
                        <Link to="#" className="social-icon" title="فیسبوک" target="_blank">
                          <i className="icon-facebook-f"></i>
                        </Link>
                        <Link to="#" className="social-icon" title="توییتر" target="_blank">
                          <i className="icon-twitter"></i>
                        </Link>
                        <Link to="#" className="social-icon" title="اینستاگرام" target="_blank">
                          <i className="icon-instagram"></i>
                        </Link>
                      </div>
                    </div>
                  </figcaption>
                </figure>
                <div className="member-content">
                  <h3 className="member-title">
                    نرگس رسولی<span>جستجو و سئو</span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="member member-anim text-center">
                <figure className="member-media">
                  <img src="/images/team/member-2.jpg" alt="توضیح عکس" />

                  <figcaption className="member-overlay">
                    <div className="member-overlay-content">
                      <h3 className="member-title">
                        رضا کریمی<span>مدیریت فروش</span>
                      </h3>
                      <p>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم، لورم ایپسوم متن ساختگی با
                        تولید سادگی نامفهوم
                      </p>
                      <div className="social-icons social-icons-simple">
                        <Link to="#" className="social-icon" title="فیسبوک" target="_blank">
                          <i className="icon-facebook-f"></i>
                        </Link>
                        <Link to="#" className="social-icon" title="توییتر" target="_blank">
                          <i className="icon-twitter"></i>
                        </Link>
                        <Link to="#" className="social-icon" title="اینستاگرام" target="_blank">
                          <i className="icon-instagram"></i>
                        </Link>
                      </div>
                    </div>
                  </figcaption>
                </figure>
                <div className="member-content">
                  <h3 className="member-title">
                    رضا کریمی<span>مدیریت فروش</span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="member member-anim text-center">
                <figure className="member-media">
                  <img src="/images/team/member-3.jpg" alt="توضیح عکس" />

                  <figcaption className="member-overlay">
                    <div className="member-overlay-content">
                      <h3 className="member-title">
                        الهام افشار<span>مدیریت محصولات</span>
                      </h3>
                      <p>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم، لورم ایپسوم متن ساختگی با
                        تولید سادگی نامفهوم
                      </p>
                      <div className="social-icons social-icons-simple">
                        <Link to="#" className="social-icon" title="فیسبوک" target="_blank">
                          <i className="icon-facebook-f"></i>
                        </Link>
                        <Link to="#" className="social-icon" title="توییتر" target="_blank">
                          <i className="icon-twitter"></i>
                        </Link>
                        <Link to="#" className="social-icon" title="اینستاگرام" target="_blank">
                          <i className="icon-instagram"></i>
                        </Link>
                      </div>
                    </div>
                  </figcaption>
                </figure>
                <div className="member-content">
                  <h3 className="member-title">
                    الهام افشار<span>مدیریت محصولات</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-2"></div>
      </div>
    </main>


        </CommonLAyout>
    );
};
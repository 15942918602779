import { React, useState, useEffect, useContext } from 'react';
import {
    Link
} from 'react-router-dom';

import { apiClient } from '../../Services/Api';


import { commas } from '../../Utils/Commas';


export default function Recent() {

    const [latest, setLatest] = useState([]);



    useEffect(() => {


        // setTimeout(() => {
        //     setLatest([
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 0,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 0
        //         }
        //     ]
        //     );
        // }, 150);


        apiClient.get('product?page=1&num=16&new=1').then(response => {
            if (response.request.status === 200) {
                setLatest(response.data.data);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });


    }, [])





    return (
        <div className="container">
            <h2 className="title text-center mb-4">محصولات جدید</h2>
            <div className="products">
                <div className="row justify-content-center">
                    {/* product */}


                    {
                        latest.length
                            ?
                            <>
                                {
                                    latest.map(item => {
                                        return <div className="col-6 col-md-4 col-lg-3">
                                            <div className="product product-7 text-center">
                                                <figure className="product-media">
                                                    {
                                                        (item.inventory && item.discounted_price)
                                                            ?
                                                            <span className="product-label label-sale">فروش ویژه</span>
                                                            :
                                                            ''
                                                    }                              
                                                    <Link to={'/product?slug=' + item.code}>
                                                        <img src={item.image} alt="تصویر محصول" className="product-image" />
                                                        <img src={item.extra_image} alt="تصویر محصول" className="product-image-hover" />
                                                    </Link>
                                                    <div className="product-action-vertical">
                                                        <Link to="#" className="btn-product-icon btn-wishlist btn-expandable"><span>افزودن به لیست علاقه مندی</span></Link>
                                                    </div>
                                                    <div className="product-action">
                                                        <Link to={'/product?slug=' + item.code} className="btn-product btn-cart"><span>افزودن به سبد خرید</span></Link>
                                                    </div>
                                                </figure>
                                                <div className="product-body">
                                                    <div className="product-cat text-center">
                                                    <Link to={'/categories?slug=' + item.category_slug}>{item.category_title}</Link>
                                                    </div>
                                                    <h3 className="product-title text-center"><Link to={'/product?slug=' + item.code}>{item.name}</Link></h3>
                                                    {
                                                        item.inventory
                                                            ?
                                                            (
                                                                item.discounted_price
                                                                    ?
                                                                    <div className="product-price">
                                                                        <span className="new-price">{commas(parseInt(item.discounted_price)) + ' تومان'}</span>
                                                                        <span className="old-price">{commas(parseInt(item.price))}</span>
                                                                    </div>
                                                                    :
                                                                    <div className="product-price">
                                                                        <div className="new-price">
                                                                            {commas(parseInt(item.price)) + ' تومان'}
                                                                        </div>
                                                                    </div>

                                                            )
                                                            :
                                                            <div className="product-price">
                                                                <div className="new-price">
                                                                    ناموجود
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    )
                                }
                            </>
                            :
                            ''
                    }



                    {/* <div className="col-6 col-md-4 col-lg-3">
                       <div className="product product-7 text-center">
                          <figure className="product-media">
                              <span className="product-label label-sale">فروش ویژه</span>
                              <Link to="product.html">
                                  <img src="https://picsum.photos/276/376?random=50" alt="تصویر محصول" className="product-image" />
                                   <img src="https://picsum.photos/276/376?random=51" alt="تصویر محصول" className="product-image-hover" />
                              </Link>
                               <div className="product-action-vertical">
                                   <Link to="#" className="btn-product-icon btn-wishlist btn-expandable"><span>افزودن به لیست علاقه مندی</span></Link>
                              </div>
                              <div className="product-action">
                                    <Link to="#" className="btn-product btn-cart"><span>افزودن به سبد خرید</span></Link>
                                </div>
                         </figure>
                           <div className="product-body">
                                <div className="product-cat text-center">
                                    <Link to="#">لباس</Link>
                                </div>
                                <h3 className="product-title text-center"><Link to="product.html">تاپ طرح گوره خری</Link></h3>
                               <div className="product-price">
                                   <span className="new-price">3,000 تومان</span>
                                    <span className="old-price">6,000</span>
                                </div>
                            </div>
                         </div>
                    </div> */}
                    {/* Add other product elements similarly */}

                </div>
            </div>
            <div className="more-container text-center mt-2">
                <Link to="/categories" className="btn btn-outline-dark-2 btn-more"><span>نمایش بیشتر</span></Link>
            </div>
        </div>
    )

}
import React, { useState, useEffect, useContext } from 'react';
import { commas } from '../../Utils/Commas';


import alert from '../../Components/Alert';



import { useCookies } from 'react-cookie';
import { AuthContext } from '../../Context/AuthContext';





import { apiClient } from '../../Services/Api';


import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
    useParams,
    useLocation,
    Navigate
} from 'react-router-dom';

// (query-string) from npm
const queryString = require('query-string');






const ProductOptions = (props) => {


    const navigate = useNavigate();


    let location = useLocation();

    const parsed = queryString.parse(location.search);

    // const product = props.product;
    // const basePrice = props.basePrice;
    // const additional = props.additional;
    // const setAdditional = props.setAdditional;

    const { product, basePrice, additional, setAdditional, quantity, setQuantity, sewingTypes, triggerFunction } = props;


    const [isCustom, setIsCustom] = useState(false);


    const [data, setData] = useState({});


    const Auth = useContext(AuthContext);
    const [cookies, setCookie, removeCookie] = useCookies(['maandaanaaCart']);

    const [hasPrepayment, setHasPrepayment] = useState(false);



    triggerFunction.current = addToCart;



    const handleNotCustomNumberChange = (event) => {
        const { name, value, min, max } = event.target;

        setData({
            ...data,
            [name]: { 'is_custom': false, 'value': value, 'min': min, 'max': max, 'default': event.target.getAttribute('pish') }
        });

    };

    const handleCustomNumberChange = (event) => {
        const { name, value, min, max } = event.target;

        setData({
            ...data,
            [name]: { 'is_custom': true, 'value': value, 'min': min, 'max': max, 'default': event.target.getAttribute('pish') }
        });

    };


    const handleNotCustomOptionsChange = (event) => {

        if (event.target.name === 'size') {
            if (event.target.value === 'شخصی دوز') {
                setIsCustom(true);
            } else {
                setIsCustom(false);
            }
        }

        setData({
            ...data,
            [event.target.name]: { is_custom: false, id: event.target.options[event.target.selectedIndex].getAttribute('id'), price: event.target.options[event.target.selectedIndex].getAttribute('price'), value: event.target.value }

        });

    };

    const handleCustomOptionsChange = (event) => {

        setData({
            ...data,
            [event.target.name]: { is_custom: true, id: event.target.options[event.target.selectedIndex].getAttribute('id'), price: event.target.options[event.target.selectedIndex].getAttribute('price'), value: event.target.value }

        });

    };

    function addDefaultNumbersToData(attributes) {
        let result = {};
        for (let attribute of attributes) {
            if (attribute.default) {
                result[attribute.slug] = {
                    'is_custom': !attribute.is_common,
                    'default': attribute.default,
                    'value': attribute.default,
                    'min': attribute.min_value,
                    'max': attribute.max_value,

                };
            }
        }
        return result;
    }


    function validateData(attributes, data) {
        let isValid = true;

        for (let attr of attributes) {
            const { slug, input_type, min_value, max_value, options } = attr;
            const userValue = data[slug];

            if (!userValue) {
                console.error(`Attribute ${slug} is not provided.`);
                isValid = false;
                continue;
            }

            if (input_type === 'number') {
                const value = parseFloat(userValue.value);
                if (isNaN(value) || (min_value !== null && value < min_value) || (max_value !== null && value > max_value)) {
                    console.error(`Attribute ${slug} has value ${value} which is out of range [${min_value}, ${max_value}].`);
                    isValid = false;
                }
            } else if (input_type === 'single_select') {
                // const validOption = options.some(option => option.value === userValue.value && option.id === userValue.id);
                const validOption = options.some(option => 
                    String(option.value) === String(userValue.value) && 
                    String(option.id) === String(userValue.id)
                  );
                  
                if (!validOption) {
                    console.error(`Attribute ${slug} has value ${userValue.value} which is not in the options list.`);
                    isValid = false;
                }
            }
        }

        return isValid;
    }


    function removeCustomData(obj) {
        const result = {};
        for (const key in obj) {
            if (obj[key].is_custom !== true) {
                result[key] = obj[key];
            }
        }
        return result;
    }

    function removeCustomAttributes(items) {
        return items.filter(item => item.is_common);
    }


    function sumAllPrices(obj) {
        let result = 0;

        for (let key in obj) {
            if (obj[key].hasOwnProperty('price')) {
                result += parseInt(obj[key].price);
            }
        }

        return result;
    }


    const addCartQueryString = (newCartCount) => {
        // Parse the current query string
        const currentQuery = parsed;

        // Update the shipment_type in the query parameters
        const updatedQuery = {
            ...currentQuery,
            cart_count: newCartCount,
        };

        // Stringify the updated query parameters
        const newQueryString = queryString.stringify(updatedQuery);

        // Navigate to the current pathname with the updated query string
        navigate(`${location.pathname}?${newQueryString}`, { replace: true });
    };




    function addToCart() {
        if (isCustom) {
            // console.log(product.attributes);
            // console.log(data);
            if (validateData(product.attributes, data)) {
                console.log('add to cart');

                apiClient.post('cart', {
                    'code': product.code,
                    'quantity': quantity,
                    'type': getSewingTypeId(isCustom, sewingTypes),
                    'attributes': transformDataToAttributes(data),
                    'token': cookies["maandaanaaCart"] && cookies["maandaanaaCart"] !== "undefined" && cookies["maandaanaaCart"] !== "null"
                    ? cookies["maandaanaaCart"]
                    : null
                }).then(response => {
                    if (response.request.status === 200) {
                        !Auth.maandaanaaLoggedIn && response.data.data.token && setCookie('maandaanaaCart', response.data.data.token, { path: '/', expires: new Date(Date.now() + 86400000000) });
                        alert('با موفقیت به سبد خرید اضافه شد.', 'success', 'success', 3);

                        if (response.data.data.cart_items_count) {
                            addCartQueryString(response.data.data.cart_items_count);
                        }

                    }
                }).catch(error => {
                    if (error.response) {
                        alert('ثبت با خطا مواجه شد.', 'danger', 'error', 3);
                    }
                }).finally(() => {
                });

            } else {
                console.log('not valid');
                alert('لطفا اطلاعات را تکمیل کنید.', 'danger', 'error', 3);
            }
        }  else {
            // console.log(removeCustomAttributes(product.attributes));
            // console.log(removeCustomData(data));
            if (validateData(removeCustomAttributes(product.attributes), removeCustomData(data))) {
                console.log('add to cart');

                apiClient.post('cart', {
                    'code': product.code,
                    'quantity': quantity,
                    'type': getSewingTypeId(isCustom, sewingTypes),
                    'attributes': transformDataToAttributes(removeCustomData(data)),
                    'token': cookies["maandaanaaCart"] && cookies["maandaanaaCart"] !== "undefined" && cookies["maandaanaaCart"] !== "null"
                    ? cookies["maandaanaaCart"]
                    : null
                }).then(response => {
                    if (response.request.status === 200) {
                        !Auth.maandaanaaLoggedIn && response.data.data.token && setCookie('maandaanaaCart', response.data.data.token, { path: '/', expires: new Date(Date.now() + 86400000000) });
                        alert('با موفقیت به سبد خرید اضافه شد.', 'success', 'success', 3);

                        if (response.data.data.cart_items_count) {
                            addCartQueryString(response.data.data.cart_items_count);
                        }
                    }
                }).catch(error => {
                    if (error.response) {
                        alert('ثبت با خطا مواجه شد.', 'danger', 'error', 3);
                    }
                }).finally(() => {
                });

            } else {
                
                console.log('not valid');
                alert('لطفا اطلاعات را تکمیل کنید.', 'danger', 'error', 3);
            }
        }
    }


    function transformDataToAttributes(input) {
        let output = [];
    
        for (let key in input) {
            let obj = {
                "slug": key,
                "value": input[key].value
            };
    
            // Add "id" property if it exists in the input object
            if (input[key].id) {
                obj.id = input[key].id;
            }
    
            output.push(obj);
        }
    
        return output;
    }


    // in eshtebahe, bayad ye properti dige be esme hasPrePayment befrestam va type ro ham joda taskhsis bedam befrestam
    // function getSewingTypeId(sewingTypes, hasPrePayment) {
    //     for (let type of sewingTypes) {
    //         if ((hasPrePayment && type.price > 0) || (!hasPrePayment && !(type.price > 0))) {
    //             return type.id;
    //         }
    //     }
    //     return null; // in case no matching type is found    
    // }



    // ok, faqat properti has pre payment va check boxesh badan ezafe beshe
    function getSewingTypeId(input, types) {
        if (input) {
            // Return the id of the object that has a non-zero price
            const nonZeroPriceObject = types.find(type => type.price > 0);
            return nonZeroPriceObject ? nonZeroPriceObject.id : null;
        } else {
            // Return the id of the object that has a zero price
            const zeroPriceObject = types.find(type => !(type.price > 0));
            return zeroPriceObject ? zeroPriceObject.id : null;
        }
    }



    useEffect(() => {

        if (product.name && !Object.keys(data).length) {
            setData(addDefaultNumbersToData(product.attributes));
        }

    }, [data, product]);


    useEffect(() => {

        if (isCustom) {
            setAdditional(sumAllPrices(data));
        } else {
            setAdditional(sumAllPrices(removeCustomData(data)));
        }


    }, [isCustom, data]);







    return (

        <div className="product-details">
            <h1 className="product-title mt-md-0">{product.name}</h1>

            {
                product.inventory
                    ?
                    (
                        product.discounted_price
                            ?
                            <div className="product-price">
                                <span className="new-price">{commas(parseInt(product.discounted_price) + parseInt(additional)) + ' تومان'}</span>
                                <span className="old-price">{commas(parseInt(product.price) + parseInt(additional)) + ' تومان'}</span>
                            </div>
                            :
                            <div className="product-price">
                                {commas(parseInt(product.price) + parseInt(additional)) + ' تومان'}
                            </div>
                    )
                    :
                    (
                    product && product.name && !product.inventory
                    ?
                    <div className="product-price">
                        ناموجود
                    </div>
                    :
                    ''
                    )
            }

            <div className="d-flex flex-wrap general-inputs">
                {
                    (product.attributes && product.attributes.length)
                        ?
                        product.attributes.map(
                            a => {
                                if (a.input_type === 'single_select' && a.slug === 'size') {
                                    return <div className="details-filter-row" key={a.slug}>
                                        <label htmlFor={a.slug}>{a.name + ' :'}</label>
                                        <div className="select-custom ml-4">
                                            <select name={a.slug} id={a.slug} defaultValue="" className="form-control" onChange={handleNotCustomOptionsChange}>


                                                <option value="" disabled readOnly>{a.description}</option>

                                                {
                                                    (a.options && a.options.length)
                                                        ?
                                                        a.options.map(
                                                            o => {
                                                                return <option key={o.id} id={o.id} price={o.extra_price} value={o.value}>{o.value}</option>
                                                            }
                                                        )
                                                        :
                                                        ''
                                                }
                                            </select>
                                        </div>
                                    </div>
                                } else if (a.input_type === 'single_select' && a.is_common) {

                                    return <div className="details-filter-row" key={a.slug}>
                                        <label htmlFor={a.slug}>{a.name + ' :'}</label>
                                        <div className="select-custom ml-4">
                                            <select name={a.slug} id={a.slug} defaultValue="" className="form-control" onChange={handleNotCustomOptionsChange}>
                                                <option value="" disabled readOnly>{a.description}</option>

                                                {
                                                    (a.options && a.options.length)
                                                        ?
                                                        a.options.map(
                                                            o => {
                                                                return <option key={o.id} id={o.id} price={o.extra_price} value={o.value}>{o.value}</option>
                                                            }
                                                        )
                                                        :
                                                        ''
                                                }
                                            </select>
                                        </div>
                                    </div>

                                } else if (a.input_type === 'number' && a.is_common) {

                                    return <div className="details-filter-row" key={a.slug}>
                                        <label htmlFor={a.slug}>{a.name + ' :'}</label>
                                        <div className="custom-input ml-4">
                                            <input type="number" id={a.slug} name={a.slug} className="form-control" value={data[a.slug] ? data[a.slug].value : a.default}
                                                min={a.min_value} max={a.max_value} step={a.step} pish={a.default} onChange={handleNotCustomNumberChange} data-decimals="0" required />
                                        </div>
                                    </div>

                                } else if (a.input_type === 'single_select' && !a.is_common && isCustom) {

                                    return <div className="details-filter-row" key={a.slug}>
                                        <label htmlFor={a.slug}>{a.name + ' :'}</label>
                                        <div className="select-custom ml-4">
                                            <select name={a.slug} id={a.slug} defaultValue="" className="form-control" onChange={handleCustomOptionsChange}>
                                                <option value="" disabled readOnly>{a.description}</option>

                                                {
                                                    (a.options && a.options.length)
                                                        ?
                                                        a.options.map(
                                                            o => {
                                                                return <option key={o.id} id={o.id} price={o.extra_price} value={o.value}>{o.value}</option>
                                                            }
                                                        )
                                                        :
                                                        ''
                                                }
                                            </select>
                                        </div>
                                    </div>

                                } else if (a.input_type === 'number' && !a.is_common && isCustom) {


                                    return <div className="details-filter-row" key={a.slug}>
                                        <label htmlFor={a.slug}>{a.name + ' :'}</label>
                                        <div className="custom-input ml-4">
                                            <input type="number" id={a.slug} name={a.slug} className="form-control" value={data[a.slug] ? data[a.slug].value : a.default}
                                                min={a.min_value} max={a.max_value} step={a.step} pish={a.default} onChange={handleCustomNumberChange} data-decimals="0" required />
                                        </div>
                                    </div>

                                }
                            }
                        )
                        :
                        ''
                }
            </div>


            {(product && product.inventory && (quantity + 10000000)) && <div className="details-filter-row">
                <label htmlFor="qty">تعداد : </label>
                <div className="custom-input ml-4">
                    <input type="number" id="quantity" className="form-control" value={quantity} onChange={(e) => { setQuantity(e.target.value) }} step="1" data-decimals="0" required />
                </div>
            </div>}

            {/* اگر محصول انتخاب رنگ داشت */}
            {/* <div className="details-filter-row">
                <label>رنگ : </label>
                <div className="product-nav product-nav-dots">
                    <a href="#" className="active color" style={{ background: '#333333' }}><span className="sr-only">#333333</span></a>
                    <a href="#" className="color" style={{ background: '#efe7db' }}><span className="sr-only">#efe7db</span></a>
                </div>
            </div> */}




            {((product && product.inventory) && isCustom && false) ? <div className="unique-inputs">
                <div className="details-filter-row mb-0">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" onChange={e => e.target.checked ? setHasPrepayment(true) : setHasPrepayment(false)} className="custom-control-input" id="signin-remember" />
                        <label className="custom-control-label w-auto" htmlFor="signin-remember">
                            فعلا بیعانه پرداخت می کنم.
                        </label>
                    </div>
                </div>
                <div className="details-filter-row">
                    <div className="small font-weight-bold">
                        {
                            sewingTypes.map(
                                s => {
                                    if (s.price > 0) {
                                        return <span key={s.price}>{commas(s.price) + ' برای هر سفارش'}</span>
                                    }
                                }
                            )
                        }
                    </div>
                </div>
            </div>
                :
                ''}

            <div className="product-details-action">
                {(product && product.inventory) ? <a href='#' onClick={(e) => {e.preventDefault(); addToCart();}} className="btn-product btn-cart"><span>افزودن به سبد خرید</span></a> : ''}
                <div className="details-action-wrapper">
                    <a href="#" className="btn-product btn-wishlist" title="لیست علاقه مندی"><span>افزودن به علاقه مندی</span></a>
                </div>
            </div>

            <div className="product-details-footer">
                <div className="social-icons social-icons-sm">
                    <span className="social-label">اشتراک گذاری : </span>
                    <a href="#" className="social-icon" title="اینستاگرام" target="_blank"><i className="icon-instagram"></i></a>
                    <a href="#" className="social-icon" title="واتسپ" target="_blank"><i className="icon-whatsapp"></i></a>
                    <a href="#" className="social-icon" title="تلگرام" target="_blank"><i className="icon-telegram"></i></a>
                </div>
            </div>
        </div>
    )


}



export default ProductOptions;

import { useEffect, useRef } from 'react';
import { persianCharsRegExp, hasPersianChars } from '../../Utils/RegExp';


export default function SignupForm(props) {
    const isLoading = props.isLoading;
    const name = props.name;
    const setName = props.setName;
    const lastname = props.lastname;
    const setLastname = props.setLastname;
    const email = props.email;
    const setEmail = props.setEmail;
    const handleSignup = props.handleSignup;
    const hasSignupError = props.hasSignupError;
    const hasNameError = props.hasNameError;
    const hasLastnameError = props.hasLastnameError;
    const hasEmailError = props.hasEmailError;
    const generalError = props.generalError;
    const customError = props.customError;
    const inputRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);
    return (


        <div className="form-box">
            <div className="form-tab">
                <ul className="nav nav-pills nav-fill" role="tablist">
                    <li className="nav-item">
                        <div className="nav-link active" id="signin-tab" to="#signin" role="tab"
                            aria-controls="signin" aria-selected="true">ورود / ثبت نام</div>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                        <form>
                            <div className="form-group">
                                <label htmlFor="firstname">نام:</label>
                                <input type="text" className={'form-control ' + ((hasNameError || generalError || customError) && 'danger')} id="phone"
                                    name="firstname" ref={inputRef} value={name} onChange={e => setName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">نام خانوادگی:</label>
                                <input type="text" className={'form-control ' + ((hasLastnameError || generalError || customError) && 'danger')} id="phone"
                                    name="lastname" value={lastname} onChange={e => setLastname(e.target.value)} />
                            </div>
                            <div className="form-footer">
                                <button type="submit" className="btn btn-outline-primary" ref={buttonRef} onClick={handleSignup}>
                                    <span>
                                        {isLoading ? 'لطفا صبر کنید' : 'ثبت'}
                                    </span>
                                    <i className="icon-long-arrow-left"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )

}
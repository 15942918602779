import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';

import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  useLocation,
  Navigate
} from 'react-router-dom';



import { AuthContext } from './Context/AuthContext';
import { UserContext } from './Context/UserContext';

import Home from './Pages/Home';
import Login from './Pages/Login';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import Dashboard from './Pages/Dashboard';
import Order from './Pages/Order';
import Categories from './Pages/Categories';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Faq from './Pages/Faq';
import BankResult from './Pages/BankResult';



import LoggedInRoute from './Utils/LoggedInRoute';



// (query-string) from npm
const queryString = require('query-string');



function App() {

  const Auth = useContext(AuthContext);
  const User = useContext(UserContext);


  let location = useLocation();
  const parsed = queryString.parse(location.search);
  let navigate = useNavigate();
















  useEffect(() => {
    if (
      Auth.goHome &&
      !location.pathname.includes("/login")
    ) {
      navigate("/");
      Auth.setGoHome(false);
    } else if (
      Auth.goHome &&
      location.pathname.includes("/login")
    ) {
      Auth.setGoHome(false);
    }
  }, [Auth.goHome]);




  useEffect(() => {
    User.getData();
  }, [Auth.maandaanaaLoggedIn]);


  useEffect(() => {
    User.getData();
  }, [location]);





  return (


    <>
      <Routes>
        {/* {
          Auth.maandaanaaLoggedIn
            ?
            (
              location.pathname === '/login'
                ?
                <Route path="/login" element={<Navigate replace to="/dashboard" />} />
                :
                <Route path="/login" element={<Navigate replace to={location.pathname.replace('/login', '') + location.search + location.hash} />} />
            )
            :
            <Route path="/login/*" element={<Login />} />
        } */}

        {/* {Auth.maandaanaaLoggedIn ? (
          location.pathname === '/login' ? (
            <Route path="/login" element={<Navigate replace to="/dashboard" />} />
          ) : (
            <Route
              path="/login/*"
              element={<Navigate replace to={location.pathname.replace('/login', '') + location.search + location.hash} />}
            />
          )
        ) : (
          <Route path="/login/*" element={<Login />} />
        )} */}

        <Route
          path="/login/*"
          element={
            Auth.maandaanaaLoggedIn ? (
              location.pathname === '/login'
                ? <Navigate replace to="/dashboard" />
                : <Navigate replace to={location.pathname.replace('/login', '') + location.search + location.hash} />
            ) : (
              <Login />
            )
          }
        />
        {/* <Route path="/product/*" element={<Product />} /> */}
        <Route path="/product" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/bank-result" element={<BankResult />} />

        {/* <LoggedInRoute path="/order" element={<Order />} />
        <LoggedInRoute path="/dashboard" element={<Dashboard />} /> */}


        {/* <Route path="/order" element={ <Order />} />
        <Route path="/dashboard" element={<Dashboard />} /> */}

        <Route path="/order" element={<LoggedInRoute element={<Order />} />} />
        <Route path="/dashboard" element={<LoggedInRoute element={<Dashboard />} />} />




        {/* <Route path="/dashboard" element={<Link to="/">Go to Home</Link>} /> */}


        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/homepage" element={<Home />} />
        <Route path="/index" element={<Home />} />
        <Route path="/default" element={<Home />} />

      </Routes>



    </>

  );
}

export default App;

import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import { Link, useLocation } from 'react-router-dom';

import { apiClient } from '../../Services/Api';
import { activeUrl } from '../../Utils/ActiveUrl';

import { AuthContext } from '../../Context/AuthContext';


import { phoneRegExp, repeatedCharRegExp, lengthRegExp, persianCharsRegExp, emailRegExp } from '../../Utils/RegExp';
import { fixNumbers } from '../../Utils/FixNumbers';



import alert from '../../Components/Alert';



import PhoneForm from './PhoneForm';
import OtpForm from './OtpForm';
import SignupForm from './SignupForm';


import './Login.css';



export default function Login() {


    const Auth = useContext(AuthContext);

    const [attempt, setAttempt] = useState(0);
    let location = useLocation();

    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasPhoneError, setHasPhoneError] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const initTimer = 60;
    const [timer, setTimer] = useState(initTimer);
    const [otp, setOtp] = useState(0);
    const [hasOtpError, setHasOtpError] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [hasSignupError, setHasSignupError] = useState(false);
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [hasNameError, setHasNameError] = useState(false);
    const [hasLastnameError, setHasLastnameError] = useState(false);
    const [hasEmailError, setHasEmailError] = useState(false);
    const [nonce, setNonce] = useState(null);
    const [generalError, setGeneralError] = useState(null);
    const [customError, setCustomError] = useState(null);



    const handlePhone = (e) => {
        e.preventDefault();
        let validPhone = phoneRegExp.test(phone);
        if (validPhone && !isLoading) {
            setIsLoading(true);
            setHasPhoneError(false);
            setGeneralError(null);
            setCustomError(null);
            setOtp(0);
            setHasOtpError(false);
            setTimer(initTimer);



            // setTimeout(() => {
            //     setNonce('test1');
            //     setShowOtp(true);
            //     setIsLoading(false);
            // }, 300);


            apiClient.post('auth/send', {
                mobile: phone
            }).then(response => {
                setIsLoading(false);
                if (response.request.status >= 200 && response.request.status <= 204) {
                    setNonce(response.data.data.nonce);
                    response.data.data.token && alert(response.data.data.token);
                    setShowOtp(true);
                } else {
                    setGeneralError(true);
                }
            }).catch(error => {
                setIsLoading(false);
                if (error.response && (error.response.request.status === 422 || error.response.request.status === 429)) {
                    setCustomError(error.response);
                } else {
                    setGeneralError(true);
                }
            });




        } else if (!isLoading) {
            setHasPhoneError(true);
        }
    }




    const handleEdit = (e) => {
        e.preventDefault();
        setPhone('');
        setShowOtp(false);
        setHasOtpError(false);
        setHasPhoneError(false);
        setGeneralError(null);
        setCustomError(null);
    }



    const handleOtp = (e) => {
        e.preventDefault();
        if (!isLoading) {
            setIsLoading(true);
            setHasOtpError(false);
            setGeneralError(null);
            setCustomError(null);
            if (typeof otp === "string" && otp.replace(/\s/g, '').length === 5) {




                // setTimeout(() => {

                //     if (phone === '09181436267') {
                //         setShowOtp(false);
                //         Auth.login();
                //     } else if (otp == '12345') {
                //         let phoneCopy = phone;
                //         setPhone(0);
                //         setShowOtp(false);
                //         setShowSignup(true);
                //         setPhone(phoneCopy);
                //     } else {
                //         setGeneralError(true);
                //     }
                //     setIsLoading(false);

                // }, 400);


                apiClient.post('auth/verify', {
                    mobile: phone,
                    token: fixNumbers(otp),
                    nonce
                }).then(response => {    
                    setIsLoading(false);
                    if (response.request.status >= 200 && response.request.status <= 204) {
                        setOtp(0);
                        apiClient.get('profile').then(response => {
                            if (response.request.status >= 200 && response.request.status <= 204) {
                                if (response.data.data.is_known) {
                                    Auth.login();                              
                                    // history.replace("/dashboard");
                                } else {
                                    let phoneCopy = phone;  
                                    setPhone(0);          
                                    setShowOtp(false);           
                                    setShowSignup(true);           
                                    setPhone(phoneCopy);
                                }             
                            } else {
                                setGeneralError(true);
                            }
                        }).catch(error => {
                            setGeneralError(true);
                        });
                    } else {
                        setGeneralError(true);
                    }
                }).catch(error => {
                    setIsLoading(false);
                    if (error.response && (error.response.request.status === 406 || error.response.request.status === 429)) {
                        setCustomError(error.response);
                    } else {
                        setGeneralError(true);
                    }
                });





            } else {
                setIsLoading(false);
                setHasOtpError(true);
            }
        }
    }



    const handleResend = (e) => {
        e.preventDefault();
        apiClient.post('auth/send', {
            mobile: fixNumbers(phone)
        }).then(response => {
            if (response.request.status >= 200 && response.request.status <= 204) {
                setTimer(initTimer);
                setNonce(response.data.data.nonce);
            } else {
                setGeneralError(true);
            }
        }).catch(error => {
            if (error.response) {
                setGeneralError(true);
            }
        });
    }


    const handleSignup = (e) => {
        e.preventDefault();
        let validName = (!repeatedCharRegExp.test(name) && lengthRegExp.test(name) && persianCharsRegExp.test(name));
        let validLastname = (!repeatedCharRegExp.test(lastname) && lengthRegExp.test(lastname) && persianCharsRegExp.test(lastname));
        // let validEmail = (emailRegExp.test(email) && Boolean(email));
        let validEmail = emailRegExp.test(email);
        validName ? setHasNameError(false) : setHasNameError(true);
        validLastname ? setHasLastnameError(false) : setHasLastnameError(true);
        validEmail ? setHasEmailError(false) : setHasEmailError(true);
        if (validName && validLastname && validEmail) {
            if (!isLoading) {
                setIsLoading(true);


                // setTimeout(() => {
                //     Auth.login();
                //     setIsLoading(false);
                // }, 350);

                apiClient.put('profile', {
                    first_name: name,
                    last_name: lastname,
                    email: email
                }).then(response => {
                    setIsLoading(false);
                    if (response.request.status >= 200 && response.request.status <= 204) {
                        setHasSignupError(false);
                        setGeneralError(null);
                        setCustomError(null);
                        Auth.login();
                        // history.replace("/dashboard");
                    } else {
                        setGeneralError(true);
                    }
                }).catch(error => {
                    setIsLoading(false);
                    if (error.response && error.response.request.status === 422) {
                        setCustomError(error.response);
                    } else {
                        setGeneralError(true);
                    }
                });




            }
        }
    }











    useEffect(() => {
        if (!!location.pathname.includes("/login")) {
            Auth.initFunction(() => {
                apiClient.get(activeUrl + '/sanctum/csrf-cookie')
                    .then(response => {
                        // it's OK
                        // console.log('attempts OK:' + attempt);
                    }).catch(error => {
                        // console.log('attempts NOK:' + attempt);
                        Auth.reset();
                        (attempt < 7) && setAttempt(attempt + 1);
                    }).finally(() => {
                        // console.log('attempts:' + attempt);
                    });
            });
        }
    }, [attempt]);




    useEffect(() => {

        if (hasPhoneError) {
            alert('شماره وارد شده صحیح نمی باشد', 'danger', 'error', 3);
        } else if (hasOtpError) {
            alert('کد وارد شده صحیح نمی باشد', 'danger', 'error', 3)
        } else if (hasNameError) {
            alert('نام را اصلاح کنید.', 'danger', 'error', 3)
        } else if (hasLastnameError) {
            alert('نام خانوادگی را اصلاح کنید.', 'danger', 'error', 3)
        } else if (hasSignupError) {
            alert('خطا در ثبت نام. لطفا دوباره امتحان کنید.', 'danger', 'error', 3)
        }
         else if (generalError) {
            alert('خطا در دریافت پاسخ. لطفا دوباره امتحان کنید.', 'danger', 'error', 3);
        } else if (customError) {
            alert((customError.data && customError.data.message) && customError.data.message, 'danger', 'error', 3);
        }

        if (hasPhoneError || hasOtpError || hasNameError || hasLastnameError || hasSignupError || generalError || customError) {
            setHasPhoneError(false);
            setHasOtpError(false);
            setHasNameError(false);
            setHasLastnameError(false);
            setHasSignupError(false);
            setGeneralError(null);
            setCustomError(null);
        }

    }, [hasPhoneError, , hasOtpError, hasNameError, hasLastnameError, hasSignupError, generalError, customError]);




    return (
        <CommonLAyout>


            <main className="main">
                <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
                    style={{ backgroundImage: "url('../images/backgrounds/login-bg.jpg')" }}>
                    <div className="container">


                        {(!showOtp && !showSignup) && <PhoneForm handlePhone={handlePhone} phone={phone}
                            setPhone={setPhone} hasPhoneError={hasPhoneError}
                            generalError={generalError}
                            customError={customError}
                            isLoading={isLoading} />}


                        {(showOtp && !showSignup) && <OtpForm handleOtp={handleOtp} handleEdit={handleEdit}
                            showOtp={showOtp} handleResend={handleResend} timer={timer} setTimer={setTimer} phone={phone}
                            otp={otp} setOtp={setOtp} isLoading={isLoading} hasOtpError={hasOtpError} setHasOtpError={setHasOtpError}
                            generalError={generalError}
                            customError={customError} />}



                        {showSignup && <SignupForm name={name} setName={setName} lastname={lastname} setLastname={setLastname}
                            email={email} setEmail={setEmail} hasSignupError={hasSignupError} setHasSignupError={setHasSignupError}
                            hasNameError={hasNameError} setHasNameError={setHasNameError}
                            hasLastnameError={hasLastnameError} setHasLastnameError={setHasLastnameError}
                            hasEmailError={hasEmailError} setHasEmailError={setHasEmailError}
                            handleSignup={handleSignup}
                            generalError={generalError}
                            customError={customError}
                            isLoading={isLoading}
                        />}



                    </div>
                </div>
            </main>


        </CommonLAyout>
    );
};
import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import './Categories.css';


import Provinces from '../../Utils/Provinces';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
    useParams,
    useLocation,
    Navigate
} from 'react-router-dom';




import { apiClient } from '../../Services/Api';


import { AuthContext } from '../../Context/AuthContext';


import { fixNumbers } from '../../Utils/FixNumbers';
import { commas } from '../../Utils/Commas';


import alert from '../../Components/Alert';



// (query-string) from npm
const queryString = require('query-string');





export default function Categories() {



    const navigate = useNavigate();


    let location = useLocation();

    const parsed = queryString.parse(location.search);



    const [selectedCategory, setSelectedCategory] = useState('');


    const [isLoading, setIsLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [num, setNum] = useState(12);
    const [isEmpty, setIsEmpty] = useState(false);


    const [products, setProducts] = useState([]);



    const [categories, setCategories] = useState([]);





    // const categories = [
    //     { slug: 'cat-1', name: 'لباس' },
    //     { slug: 'cat-2', name: 'تی شرت' },
    //     { slug: 'cat-3', name: 'کیف' },
    //     { slug: 'cat-4', name: 'ژاکت' },
    //     { slug: 'cat-5', name: 'کفش' },
    //     { slug: 'cat-6', name: 'شال و روسری' },
    //     { slug: 'cat-7', name: 'لی' },
    //     { slug: 'cat-8', name: 'لباس ورزشی' }
    // ];


    function getCategoriesWithZeroChildrenAndNonZeroAttributes(data) {
        let result = [];

        // Recursive function to traverse categories and sub-categories
        function traverse(categories) {
            categories.forEach(category => {
                // Safely check if `attributes` exists and is an array
                const hasAttributes = Array.isArray(category.attributes) && category.attributes.length > 0;
                const hasNoChildren = !Array.isArray(category.children) || category.children.length === 0;

                // If the category has non-zero attributes and zero children
                if (hasAttributes && hasNoChildren) {
                    result.push({ slug: category.slug, name: category.title });
                }

                // If the category has children, traverse them recursively
                if (Array.isArray(category.children) && category.children.length > 0) {
                    traverse(category.children);
                }
            });
        }

        // Start the traversal from the root data
        traverse(data);
        return result;
    }



    useEffect(() => {



        // setCategories([
        //     { slug: 'cat-1', name: 'لباس' },
        //     { slug: 'cat-2', name: 'تی شرت' },
        //     { slug: 'cat-3', name: 'کیف' },
        //     { slug: 'cat-4', name: 'ژاکت' },
        //     { slug: 'cat-5', name: 'کفش' },
        //     { slug: 'cat-6', name: 'شال و روسری' },
        //     { slug: 'cat-7', name: 'لی' },
        //     { slug: 'cat-8', name: 'لباس ورزشی' }
        // ]);


        apiClient.get('category').then(response => {
            if (response.request.status === 200) {
                response.data.data.length
                    ?
                    setCategories(getCategoriesWithZeroChildrenAndNonZeroAttributes(response.data.data))
                    :
                    setCategories([]);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });


    }, []);


    useEffect(() => {


        setSelectedCategory(parsed.slug);


    }, [location]);


    useEffect(() => {


        // setTimeout(() => {
        //     setProducts([
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 0,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 0
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 0,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 10
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 0
        //         },
        //         {
        //             "name": "محصول 1",
        //             "code": "product-1",
        //             "category_title": "دامن",
        //             "category_slug": "daaman",
        //             "price": 1500000,
        //             "discounted_price": 1300000,
        //             "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //             "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //             "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //             "inventory": 0
        //         }
        //     ]
        //     );
        // }, 150);



        setProducts(null);
        setIsEmpty(false);
        setIsLoading(true);


        if (selectedCategory && selectedCategory.length > 2) {


            apiClient.get('product' + '?page=' + page + '&num=' + num + '&category=' + selectedCategory).then(response => {
                if (response.request.status >= 200 || response.request.status <= 204) {
                    !response.data.data.length && setIsEmpty(true);
                    setProducts(response.data.data);
                }
            }).catch(error => {
                if (error.response) {
                    // nothing
                }
            }).finally(() => {
                setIsLoading(false);
            });


        } else {



            apiClient.get('product' + '?page=' + page + '&num=' + num).then(response => {
                if (response.request.status >= 200 || response.request.status <= 204) {
                    !response.data.data.length && setIsEmpty(true);
                    setProducts(response.data.data);
                }
            }).catch(error => {
                if (error.response) {
                    // nothing
                }
            }).finally(() => {
                setIsLoading(false);
            });


        }





    }, [selectedCategory, page]);








    return (
        <CommonLAyout>

            <main className="main">
                <div className="page-header text-center" style={{ backgroundImage: "url('/images/page-header-bg.jpg')" }}>
                    <div className="container">
                        <h1 className="page-title text-center">محصولات<span className="text-center">مزون ماندانا</span></h1>
                    </div>
                </div>

                <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="index-1.html">خانه</Link>
                            </li>
                            {/* <li className="breadcrumb-item">
                                <Link to="#">فروشگاه</Link>
                            </li> */}
                            <li className="breadcrumb-item active" aria-current="page">محصولات</li>
                        </ol>
                    </div>
                </nav>

                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="products mb-0">
                                    <div className="row justify-content-center">


                                        <>
                                            {
                                                isLoading
                                                    ?
                                                    // <img src="/img/spinner.gif" style={{ width: '100px' }} />
                                                    <div className="text-center">لطفا صبر کنید...</div>
                                                    :
                                                    (
                                                        !products
                                                            ?
                                                            <p className="text-center text-dark-blue bold">خطا در دریافت اطلاعات. دوباره امتحان کنید.</p>
                                                            :
                                                            (
                                                                isEmpty
                                                                    ?
                                                                    <p className="text-center text-dark-blue bold">
                                                                        {
                                                                            page === 1
                                                                                ?
                                                                                'هیچ موردی یافت نشد.'
                                                                                :
                                                                                'مورد بیشتری یافت نشد.'
                                                                        }
                                                                    </p>
                                                                    :
                                                                    products.map(
                                                                        item => {
                                                                            return (

                                                                                <div className="col-6 col-md-4 col-lg-4">
                                                                                    <div className="product product-7 text-center">
                                                                                        <figure className="product-media">
                                                                                            {
                                                                                                (item.inventory && item.discounted_price)
                                                                                                    ?
                                                                                                    <span className="product-label label-sale">فروش ویژه</span>
                                                                                                    :
                                                                                                    ''
                                                                                            }
                                                                                            <Link to={'/product?slug=' + item.code}>
                                                                                                <img src={item.image} alt="تصویر محصول" className="product-image" />
                                                                                                <img src={item.extra_image} alt="تصویر محصول" className="product-image-hover" />
                                                                                            </Link>

                                                                                            <div className="product-action-vertical">
                                                                                                <Link to="#" className="btn-product-icon btn-wishlist btn-expandable"><span>افزودن به لیست علاقه مندی</span></Link>
                                                                                            </div>

                                                                                            <div className="product-action">
                                                                                                <Link to={'/product?slug=' + item.code} className="btn-product btn-cart"><span>افزودن به سبد خرید</span></Link>
                                                                                            </div>
                                                                                        </figure>

                                                                                        <div className="product-body">
                                                                                            <div className="product-cat text-center">
                                                                                                <Link to={'/categories?slug=' + item.category_slug}>{item.category_title}</Link>
                                                                                            </div>
                                                                                            <h3 className="product-title text-center"><Link to={'/product?slug=' + item.code}>{item.name}</Link></h3>
                                                                                            {
                                                                                                item.inventory
                                                                                                    ?
                                                                                                    (
                                                                                                        item.discounted_price
                                                                                                            ?
                                                                                                            <div className="product-price">
                                                                                                                <span className="new-price">{commas(parseInt(item.discounted_price)) + ' تومان'}</span>
                                                                                                                <span className="old-price">{commas(parseInt(item.price))}</span>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="product-price">
                                                                                                                <div className="new-price">
                                                                                                                    {commas(parseInt(item.price)) + ' تومان'}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                    )
                                                                                                    :
                                                                                                    <div className="product-price">
                                                                                                        <div className="new-price">
                                                                                                            ناموجود
                                                                                                        </div>
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    )



                                                            )
                                                    )
                                            }
                                        </>

                                    </div>
                                </div>



                                <div className="d-flex w-100 justify-content-between">


                                    <div className={'btn btn-outline-dark btn-rounded opacity-hover pointer-hover transition ' + ((page === 1 || isLoading || !products) && ' disabled')}
                                        onClick={
                                            () => {
                                                if (page > 1 && !isLoading && products) {
                                                    setPage(page - 1)
                                                }
                                            }
                                        }
                                    ><i className="icon-long-arrow-right"></i><span>قبل</span></div>

                                    <div className={
                                        'btn btn-outline-dark btn-rounded opacity-hover pointer-hover transition ' +
                                        ((
                                            isEmpty ||
                                            (products && products.length < num) ||
                                            !products ||
                                            isLoading
                                        )
                                            && ' disabled')
                                    }
                                        onClick={
                                            () => {
                                                if (
                                                    !isEmpty &&
                                                    !isLoading &&
                                                    products &&
                                                    products.length === num
                                                ) {
                                                    setPage(page + 1)
                                                }
                                            }
                                        }
                                    ><span>بعد</span><i className="icon-long-arrow-left"></i></div>


                                </div>


                                {/* <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                            <Link className="page-link page-link-prev" to="#" aria-label="Previous" tabIndex="-1" aria-disabled="true">
                                                <span aria-hidden="true">
                                                    <i className="icon-long-arrow-right"></i>
                                                </span>
                                                قبلی
                                            </Link>
                                        </li>
                                        <li className="page-item">
                                            <Link className="page-link page-link-next" to="#" aria-label="Next">
                                                بعدی
                                                <span aria-hidden="true">
                                                    <i className="icon-long-arrow-left"></i>
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav> */}

                            </div>

                            <aside className="col-lg-3 order-lg-first">
                                <div className="sidebar sidebar-shop">
                                    <div className="widget widget-collapsible">
                                        <h3 className="widget-title">
                                            {/* <Link data-toggle="collapse" to="#widget-1" role="button" aria-expanded="true" aria-controls="widget-1">
                                                دسته بندی
                                            </Link> */}
                                            <div>
                                                دسته بندی
                                            </div>
                                        </h3>

                                        <div className="collapse show" id="widget-1">
                                            <div className="widget-body">


                                                <div className="filter-items filter-items-count">
                                                    {categories.length ? categories.map((category) => (
                                                        <div className="filter-item" key={category.slug}>
                                                            <div className="custom-control custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    className="custom-control-input"
                                                                    id={category.slug}
                                                                    name="category" // Name must be the same to ensure only one can be selected
                                                                    checked={selectedCategory === category.slug}
                                                                    onChange={() => navigate('/categories?slug=' + category.slug, { replace: true })}
                                                                />
                                                                <label
                                                                    // className="custom-control-label"
                                                                    className={`custom-control-label ${selectedCategory === category.slug ? 'selected' : ''}`} // Apply 'selected' class to the label

                                                                    htmlFor={category.slug}>
                                                                    {category.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))
                                                        :
                                                        ''
                                                    }


                                                    {
                                                        (categories.length && selectedCategory && selectedCategory.length > 2) ? <div className="filter-item">
                                                            <div className="custom-control custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    className="custom-control-input"
                                                                    id="cat-all"
                                                                    name="category"
                                                                    // checked={selectedCategory === ''}
                                                                    onChange={() => navigate('/categories', { replace: true })}                                                                />
                                                                <label
                                                                    className={`custom-control-label`}
                                                                    htmlFor="cat-all"
                                                                >
                                                                    <i class="icon-close"></i> پاک کردن
                                                                </label>
                                                            </div>
                                                        </div>
                                                            : ''
                                                    }


                                                </div>




                                                {/* <div className="filter-items filter-items-count">
                                                    <div className="filter-item">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="cat-1" />
                                                            <label className="custom-control-label" htmlFor="cat-1">لباس</label>
                                                        </div>
                                                        <span className="item-count">3</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </main>


        </CommonLAyout>
    );
};
import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import Slider from './Slider';
import Popular from './Popular';
import Recent from './Recent';
import './Home.css';




export default function Home() {


    return (
        <CommonLAyout>
            <Slider />
            <div className="mb-5"></div>
            <Popular />
            <div className="mb-5"></div>
            <div className="pt-4 pb-3" style={{ backgroundColor: '#222' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-sm-6">
                            <div className="icon-box text-center">
                                <span className="icon-box-icon">
                                    <i className="icon-truck"></i>
                                </span>
                                <div className="icon-box-content">
                                    <h3 className="icon-box-title text-center">پرداخت و ارسال</h3>
                                    <p className="text-center">ارسال رایگان برای سفارشات بالای 50 هزار تومان</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="icon-box text-center">
                                <span className="icon-box-icon">
                                    <i className="icon-unlock"></i>
                                </span>
                                <div className="icon-box-content">
                                    <h3 className="icon-box-title text-center">پرداخت امن</h3>
                                    <p className="text-center">100% پرداخت امن</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="icon-box text-center">
                                <span className="icon-box-icon">
                                    <i className="icon-headphones"></i>
                                </span>
                                <div className="icon-box-content">
                                    <h3 className="icon-box-title text-center">پشتیبانی حرفه ای</h3>
                                    <p className="text-center">پشتبانی آنلاین به صورت 7روز هفته/24ساعته</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6"></div>
            <Recent />
            <div className="pb-3">
                <div className="mb-5 mb-lg-7"></div>
                <div className="container newsletter">
                    <div className="cta cta-horizontal cta-horizontal-box bg-image mb-5"
                        style={{ backgroundImage: 'url(assets/images/backgrounds/cta/bg-1.jpg)', backgroundPosition: 'center right' }}>
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-xl-3 offset-xl-1">
                                <h3 className="cta-title text-right">عضویت در خبرنامه ما</h3>
                                <p className="cta-desc">لورم ایپسوم متن ساختگی با تولید سادگی</p>
                            </div>
                            <div className="col-lg-8 col-xl-7">
                                <form action="#">
                                    <div className="input-group">
                                        <input type="email" className="form-control" placeholder="ایمیل خود را وارد کنید"
                                            aria-label="Email Adress" required />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary btn-rounded" type="submit"><span>عضویت</span><i className="icon-long-arrow-left"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </CommonLAyout>
    );
};
import { useEffect, useRef } from 'react';
import { persianCharsRegExp, hasPersianChars } from '../../Utils/RegExp';
import { fixNumbers } from '../../Utils/FixNumbers';



export default function OtpForm(props) {
    const phone = props.phone;
    const handleOtp = props.handleOtp;
    const handleEdit = props.handleEdit;
    const handleResend = props.handleResend;
    const showOtp = props.showOtp;
    const timer = props.timer;
    const setTimer = props.setTimer;
    const isLoading = props.isLoading;
    const otp = props.otp;
    const setOtp = props.setOtp;
    const buttonRef = useRef(null);
    const hasOtpError = props.hasOtpError;
    const generalError = props.generalError;
    const customError = props.customError;
    let x;
    const inputRef = useRef(null);



    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        if (timer) {
            x = setTimeout(() => setTimer(timer - 1), 1000);
        }
        return (() => {
            if (!showOtp) {
                clearInterval(x);
            }
        })

    }, [timer, showOtp]);
    useEffect(() => {
        if (typeof otp === "string" && otp.replace(/\s/g, '').length === 5 && !hasOtpError && !generalError && !customError) {
            buttonRef.current.focus();
            buttonRef.current.click();
        }
    }, [otp]);



    return (
        <div className="form-box">
            <div className="form-tab">
                <ul className="nav nav-pills nav-fill" role="tablist">
                    <li className="nav-item">
                        <div className="nav-link active" id="signin-tab" to="#signin" role="tab"
                            aria-controls="signin" aria-selected="true">ورود / ثبت نام</div>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                        <form>
                            <div className="form-group">
                                <label htmlFor="otp">
                                    کد ارسالی به شماره
                                    <span className="bold mx-2 text-blue">{phone}</span>
                                    را وارد نمایید
                                </label>
                                <input type="text" className={'form-control ' + ((hasOtpError || generalError || customError) && 'danger')} id="otp"
                                    name="otp" ref={inputRef} value={otp ? otp : ''} onChange={e => setOtp(fixNumbers(e.target.value))} />
                            </div>

                            {Boolean(timer) && <p className="bold my-0">{timer} ثانیه</p>}


                            <div className="form-footer d-flex flex-column align-items-start">
                                <button type="submit" className="btn btn-outline-primary" ref={buttonRef} onClick={handleOtp}>
                                    <span>
                                        {isLoading ? 'لطفا صبر کنید' : 'ورود'}
                                    </span>
                                    <i className="icon-long-arrow-left"></i>
                                </button>
                                <a href="/login" className="d-inline-block text-blue opacity-hover my-3" onClick={handleEdit}>ویرایش شماره</a>
                                {!timer && <p className="my-0">
                                    کدی دریافت نکردم!
                                    <a href="/login" onClick={handleResend} className="d-inline-block text-blue opacity-hover mx-2">ارسال مجدد</a>
                                </p>}
                            </div>



                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

}
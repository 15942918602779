import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import './Cart.css';

import { UserContext } from '../../Context/UserContext';
import { AuthContext } from '../../Context/AuthContext';

import { useCookies } from 'react-cookie';


import { commas } from '../../Utils/Commas';



import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
    useParams,
    useLocation,
    Navigate
} from 'react-router-dom';

import { apiClient } from '../../Services/Api';


import alert from '../../Components/Alert';




// (query-string) from npm
const queryString = require('query-string');





export default function Cart() {

    const navigate = useNavigate();


    let location = useLocation();

    const parsed = queryString.parse(location.search);

    const [cartItems, setCartItems] = useState([]);

    const [update, setUpdate] = useState(1);

    const User = useContext(UserContext);
    const Auth = useContext(AuthContext);





    const [cookies, setCookie, removeCookie] = useCookies(['maandaanaaCart']);



    const [shipmentTogglerIsOn, setShipmentTogglerIsOn] = useState(true);

    const shipmentToggle = () => {
        setShipmentTogglerIsOn(!shipmentTogglerIsOn); // Toggle the state between true and false
    };

    const [paymentTogglerIsOn, setPaymentTogglerIsOn] = useState(true);

    const paymentToggle = () => {
        setPaymentTogglerIsOn(!paymentTogglerIsOn); // Toggle the state between true and false
    };



    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);


    const [selectedShipment, setSelectedShipment] = useState(null);

    const [selectedPayment, setSelectedPayment] = useState(null);

    const [shipmentPrice, setShipmentPrice] = useState(0);





    useEffect(() => {

        if (shipmentTypes.length) {
            if (shipmentTogglerIsOn) {
                setSelectedShipment(shipmentTypes[0].title);
                setShipmentPrice(shipmentTypes[0].price);
            } else {
                setSelectedShipment(shipmentTypes[1].title);
                setShipmentPrice(shipmentTypes[1].price);
            }
        }

    }, [shipmentTogglerIsOn, shipmentTypes]);



    useEffect(() => {

        if (paymentTypes.length) {
            if (paymentTogglerIsOn) {
                setSelectedPayment(paymentTypes[0].title);
            } else {
                setSelectedPayment(paymentTypes[1].title);
            }
        }

    }, [paymentTogglerIsOn, paymentTypes]);




    function hasVipItem(cart) {
        // Loop through all items in the cart
        for (let item of cart) {
            // Check the attributes of each item
            for (let attribute of item.attributes) {
                // Check if the attribute is "سایز" and the value is "شخصی دوز"
                if (attribute.name === "سایز" && attribute.value === "شخصی دوز") {
                    return true; // A "شخصی دوز" item is found
                }
            }
        }
        return false; // No "شخصی دوز" item found
    }




    const addCartQueryString = (newCartCount) => {
        // Parse the current query string
        const currentQuery = parsed;

        // Update the shipment_type in the query parameters
        const updatedQuery = {
            ...currentQuery,
            cart_count: newCartCount,
        };

        // Stringify the updated query parameters
        const newQueryString = queryString.stringify(updatedQuery);

        // Navigate to the current pathname with the updated query string
        navigate(`${location.pathname}?${newQueryString}`, { replace: true });
    };




    useEffect(() => {







        // setTimeout(() => {

        //     setPaymentTypes([
        //         {
        //             "id": 1,
        //             "title": "kamel",
        //             "display_title": "کامل"
        //         },
        //         {
        //             "id": 2,
        //             "title": "beyane",
        //             "display_title": "بیعانه"
        //         }
        //     ]);


        //     setShipmentTypes(
        //         [
        //             {
        //                 "id": 1,
        //                 "title": "tipax",
        //                 "display_title": "تیپاکس",
        //                 "icon": "",
        //                 "price": 0
        //             },
        //             {
        //                 "id": 2,
        //                 "title": "post",
        //                 "display_title": "پست ویژه",
        //                 "icon": "",
        //                 "price": 200000
        //             }
        //         ]
        //     );
        // }, 300);






        apiClient.get('config').then(response => {
            if (response.request.status === 200) {
                setShipmentTypes(response.data.data.shipment_types);
                setPaymentTypes(response.data.data.payment_types);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });



        // setTimeout(() => {
        //     setCartItems(
        //         [
        //             {
        //                 "item": "929e3831-90a9-4d75-94bf-caca6d629e94",
        //                 "item_type": "عمومی",
        //                 "base_price": 2000000,
        //                 "discounted_base_price": 1500000,
        //                 "is_price_changed": false,
        //                 "is_inventory_changed": false,
        //                 "quantity": 2,
        //                 "product": {
        //                     "name": "محصول 2",
        //                     "code": "product-2",
        //                     "category_title": "کت و دامن",
        //                     "category_slug": "kotvadaaman",
        //                     "price": 2000000,
        //                     "discounted_price": 1500000,
        //                     "image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U.jpg",
        //                     "extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q.jpg",
        //                     "thumbnail": "https://tailor.7modir.com/storage/product/image/YBiVRaOxCcTvxCmRWqEJeif1pZhTqwSUVU6AbL5h.jpg",
        //                     "inventory": 10
        //                 },
        //                 "attributes": [
        //                     {
        //                         "slug": "dor-ran",
        //                         "name": "دور ران",
        //                         "value": "30"
        //                     },
        //                     {
        //                         "id": "14",
        //                         "slug": "color",
        //                         "name": "رنگ",
        //                         "value": "آبی",
        //                         "extra_price": 0
        //                     },
        //                     {
        //                         "slug": "dor-kamar",
        //                         "name": "دور کمر",
        //                         "value": "40"
        //                     },
        //                     {
        //                         "id": 2,
        //                         "slug": "size",
        //                         "name": "سایز",
        //                         "value": "2",
        //                         "extra_price": 0
        //                     }
        //                 ]
        //             },
        //             {
        //                 "item": "db56477c-0fca-4718-b4a0-bc008fb3bd63",
        //                 "item_type": "شخصی",
        //                 "base_price": 1500000,
        //                 "discounted_base_price": 1300000,
        //                 "is_price_changed": false,
        //                 "is_inventory_changed": false,
        //                 "quantity": 1,
        //                 "product": {
        //                     "name": "محصول 1",
        //                     "code": "product-1",
        //                     "category_title": "دامن",
        //                     "category_slug": "daaman",
        //                     "price": 1500000,
        //                     "discounted_price": 1300000,
        //                     "image": "https://tailor.7modir.com/storage/product/image/clTRXd2gh706DQt3zQx3NDOtrM9I1bSEDlGfNxct.jpg",
        //                     "extra_image": "https://tailor.7modir.com/storage/product/image/kzlu37fcBxIHUySazsXO029xTWHyeWkxVLjtdRls.jpg",
        //                     "thumbnail": "https://tailor.7modir.com/storage/product/image/GjWU5xOornkcppWctgPr6BCrz9lNgb7XWpJqyiTp.jpg",
        //                     "inventory": 5
        //                 },
        //                 "attributes": [
        //                     {
        //                         "id": 13,
        //                         "slug": "color",
        //                         "name": "رنگ",
        //                         "value": "قرمز",
        //                         "extra_price": 0
        //                     },
        //                     {
        //                         "id": "11",
        //                         "slug": "qad-tane",
        //                         "name": "قد تنه",
        //                         "value": "70 سانت",
        //                         "extra_price": 50000
        //                     },
        //                     {
        //                         "slug": "dor-kamar",
        //                         "name": "دور کمر",
        //                         "value": "40"
        //                     },
        //                     {
        //                         "id": "8",
        //                         "slug": "qad",
        //                         "name": "قد",
        //                         "value": "120 سانت",
        //                         "extra_price": 1500000
        //                     },
        //                     {
        //                         "id": "5",
        //                         "slug": "size",
        //                         "name": "سایز",
        //                         "value": "شخصی دوز",
        //                         "extra_price": 300000
        //                     }
        //                 ]
        //             }
        //         ]
        //     );
        // }, 300);



        const token = cookies["maandaanaaCart"] && cookies["maandaanaaCart"] !== "undefined" && cookies["maandaanaaCart"] !== "null"
            ? cookies["maandaanaaCart"]
            : null;


        if (token) {


            update && setCartItems([]);

            setTimeout(() => {
                update && apiClient.get('cart?token=' + token).then(response => {
                    if (response.request.status === 200) {
                        setCartItems(response.data.data);
                    }
                }).catch(error => {
                    if (error.response) {
                        // nothing
                    }
                }).finally(() => {
                });
            }, 150);



        } else {

            update && setCartItems([]);
            setTimeout(() => {
                update && apiClient.get('cart').then(response => {
                    if (response.request.status === 200) {
                        setCartItems(response.data.data);
                    }
                }).catch(error => {
                    if (error.response) {
                        // nothing
                    }
                }).finally(() => {
                });
            }, 150);

        }


    }, [update]);





    const generateFirstPrice = cartItem => {
        let sumExtra = 0;
        cartItem.attributes.forEach(a => {
            let extraPrice = parseInt(a.extra_price);
            if (extraPrice) {
                sumExtra += extraPrice;
            }
        });

        let basePrice = parseInt(cartItem.base_price);
        return isNaN(basePrice) ? NaN : basePrice + sumExtra;
    };


    const generateFinalPrice = cartItem => {
        let sumExtra = 0;
        cartItem.attributes.forEach(a => {
            let extraPrice = parseInt(a.extra_price);
            if (extraPrice) {
                sumExtra += extraPrice;
            }
        });

        let basePrice = parseInt(cartItem.discounted_base_price) || parseInt(cartItem.base_price);
        return isNaN(basePrice) ? NaN : basePrice + sumExtra;
    };

    const generateCartFinalTotalPrice = cartItems => {
        let totalPrice = 0;

        cartItems.forEach(cartItem => {
            // Calculate the price for each item using the generateFinalPrice function
            const itemPrice = generateFinalPrice(cartItem);

            // Multiply the item price by the quantity and add it to the total price
            totalPrice += itemPrice * cartItem.quantity;
        });

        return totalPrice;
    };


    const generateCartFirstTotalPrice = cartItems => {
        let totalPrice = 0;

        cartItems.forEach(cartItem => {
            // Calculate the price for each item using the generateFinalPrice function
            const itemPrice = generateFirstPrice(cartItem);

            // Multiply the item price by the quantity and add it to the total price
            totalPrice += itemPrice * cartItem.quantity;
        });

        return totalPrice;
    };


    function getItemAttributesString(c) {
        // Map through the attributes and join them into a readable string
        return c.attributes.map(attr => {
            return ` ( ${attr.name} : ${attr.value} ) `;
        }).join(" - ");
    }






    function getDiscount(price) {
        let discount = 0;

        if (price < 500000) {
            discount = 0;
        } else if (price < 1000000) {
            discount = 75000;
        } else if (price < 3000000) {
            discount = 200000;
        } else if (price < 5000000) {
            discount = 300000;
        } else if (price >= 5000000) {
            discount = 400000;
        }

        return discount;
    }

    return (
        <CommonLAyout>
            <main className="main">
                <div className="page-header text-center" style={{ backgroundImage: "url('/images/page-header-bg.jpg')" }}>
                    <div className="container">
                        <h1 className="page-title text-center">سبد خرید<span className="text-center">مزون ماندانا</span></h1>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">خانه</Link></li>
                            <li className="breadcrumb-item"><Link to="/categories">محصولات</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">سبد خرید</li>
                        </ol>
                    </div>
                </nav>
                <div className="page-content">
                    <div className="cart">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <table className="table table-cart table-mobile">
                                        <thead>
                                            <tr>
                                                <th className="">محصول</th>
                                                <th className="text-center"> </th>
                                                <th className="text-center">قیمت</th>
                                                <th className="text-center">تعداد</th>
                                                <th className="text-center">مجموع</th>
                                                <th className="text-center"></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                cartItems.length
                                                    ?
                                                    cartItems.map(
                                                        c => (<tr>
                                                            <td className="product-col">
                                                                <div className="product">
                                                                    <figure className="product-media">
                                                                        <Link to={'/product?slug=' + c.product.code} className="opacity-hover transition">
                                                                            <img className="img-thumbnail" style={{ width: '50px', height: '50px' }} src={c.product.thumbnail} alt="تصویر محصول" />
                                                                        </Link>
                                                                    </figure>
                                                                    <h3 className="product-title">
                                                                        <Link className="font-weight-bold" to={'/product?slug=' + c.product.code}>{c.product.name}</Link>
                                                                    </h3>
                                                                </div>
                                                            </td>
                                                            <td className="product-col text-center">
                                                                <span onClick={() => {
                                                                    alert(getItemAttributesString(c), 'info', 'info', 10);
                                                                }} className="pointer-hover opacity-hover transition d-block d-lg-inline mt-1 mt-lg-0 text-center text-info font-weight-bold"><i class="icon-eye"></i> مشخصات</span>

                                                            </td>
                                                            {/* <td className="price-col text-center">
                                                                <div className="text-center small font-weight-bold text-primary d-lg-none">قیمت واحد:</div>

                                                                <div className="old-price text-center">{commas(generateFirstPrice(c))}</div>
                                                                {
                                                                    commas(generateFinalPrice(c))
                                                                }
                                                            </td> */}
                                                            <td className="price-col text-center">
                                                                <div className="d-inline-flex align-items-center mx-auto">
                                                                    <div className="pl-3 text-center small font-weight-bold text-primary d-lg-none">قیمت واحد:</div>

                                                                    <div className="pr-3 pr-lg-0">
                                                                        <div className="old-price text-center">{commas(generateFirstPrice(c))}</div>
                                                                        {
                                                                            commas(generateFinalPrice(c))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {/* <td className="quantity-col text-center">
                                                                <div className="w-100 cart-product-quantity text-center">
                                                                    <div className="text-center small font-weight-bold text-primary d-lg-none">تعداد:</div>
                                                                    {c.quantity}
                                                                </div>
                                                            </td> */}
                                                            <td className="price-col text-center">
                                                                <div className="d-inline-flex align-items-center mx-auto">
                                                                    <div className="pl-3 text-center small font-weight-bold text-primary d-lg-none">تعداد:</div>

                                                                    <div className="pr-3 pr-lg-0">
                                                                        {c.quantity}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {/* <td className="total-col text-dark text-center">

                                                                <div className="text-center small font-weight-bold text-primary d-lg-none">قیمت کل:</div>

                                                                <div className="old-price text-center">{commas(parseInt(c.quantity) * generateFirstPrice(c))}</div>

                                                                {commas(parseInt(c.quantity) * generateFinalPrice(c))}
                                                            </td> */}

                                                            <td className="price-col text-center">
                                                                <div className="d-inline-flex align-items-center mx-auto">
                                                                    <div className="pl-3 text-center small font-weight-bold text-primary d-lg-none">قیمت کل:</div>

                                                                    <div className="pr-3 pr-lg-0">
                                                                        <div className="old-price text-center">{commas(parseInt(c.quantity) * generateFirstPrice(c))}</div>
                                                                        {commas(parseInt(c.quantity) * generateFinalPrice(c))}
                                                                    </div>
                                                                </div>
                                                            </td>


                                                            <td className="remove-col text-center">
                                                                <button className="btn-remove" onClick={() => {



                                                                    const token = cookies["maandaanaaCart"] && cookies["maandaanaaCart"] !== "undefined" && cookies["maandaanaaCart"] !== "null"
                                                                        ? cookies["maandaanaaCart"]
                                                                        : null;

                                                                    if (token) {


                                                                        apiClient.delete('cart', {
                                                                            params: {
                                                                                item: c.item,
                                                                                token: cookies["maandaanaaCart"]
                                                                            }
                                                                        }).then(response => {


                                                                            alert('با موفقیت حذف شد.', 'success', 'success', 3);


                                                                            setUpdate(0);
                                                                            setTimeout(() => {
                                                                                setUpdate(1);
                                                                            }, 200);

                                                                            if (response.data.data.cart_items_count) {
                                                                                addCartQueryString(response.data.data.cart_items_count);
                                                                            }


                                                                            if (response.request.status === 200) {
                                                                            }

                                                                        }).catch(error => {
                                                                            if (error.response) {
                                                                                // nothing

                                                                                alert('حذف با خطا مواجه شد.', 'danger', 'error', 5);

                                                                            }
                                                                        }).finally(() => {
                                                                        });



                                                                    } else {

                                                                        apiClient.delete('cart', {
                                                                            params: {
                                                                                item: c.item
                                                                            }
                                                                        }).then(response => {


                                                                            alert('با موفقیت حذف شد.', 'success', 'success', 3);


                                                                            setUpdate(0);
                                                                            setTimeout(() => {
                                                                                setUpdate(1);
                                                                            }, 200);

                                                                            if (response.data.data.cart_items_count) {
                                                                                addCartQueryString(response.data.data.cart_items_count);
                                                                            }


                                                                            if (response.request.status === 200) {
                                                                            }

                                                                        }).catch(error => {
                                                                            if (error.response) {
                                                                                // nothing

                                                                                alert('حذف با خطا مواجه شد.', 'danger', 'error', 5);

                                                                            }
                                                                        }).finally(() => {
                                                                        });



                                                                    }






                                                                }}><i className="icon-close"></i></button>
                                                            </td>
                                                        </tr>)
                                                    )
                                                    :
                                                    <p className="w-100 text-center font-weight-bold py-5">سبد خرید شما خالی است.</p>
                                            }

                                        </tbody>
                                    </table>
                                    <div className="cart-bottom row">


                                        {
                                            hasVipItem
                                                ?
                                                <p className="col-12 col-md-6 col-lg-7">
                                                    <div className="text-primary font-weight-bold mb-0">** توجه **</div>
                                                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم، لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                                                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم.
                                                    لورم ایپسوم متن ساختگی
                                                    با تولید سادگی.</p>
                                                :
                                                <div className="col-12 col-md-6 col-lg-7"></div>
                                        }

                                        <div className="summary summary-cart col-12 col-md-6 col-lg-5">
                                            <h3 className="summary-title">خلاصه سبد خرید</h3>
                                            <table className="table table-summary">
                                                <tbody>
                                                    <tr className="summary-subtotal">
                                                        <td className="border-bottom-0">جمع کل (قیمت عادی) : </td>
                                                        <td className="text-left border-bottom-0">{cartItems.length ? (commas(generateCartFirstTotalPrice(cartItems)) + ' تومان') : '0 تومان'}</td>
                                                    </tr>

                                                    <tr className="summary-subtotal">
                                                        <td className="border-bottom-0">تخفیف ویژه : </td>
                                                        <td className="text-left border-bottom-0">{cartItems.length ? (commas(generateCartFirstTotalPrice(cartItems) - generateCartFinalTotalPrice(cartItems)) + ' تومان') : '0 تومان'}</td>
                                                    </tr>


                                                    <tr className="summary-subtotal">
                                                        <td className="border-bottom-0">جمع کل (قیمت ویژه) : </td>
                                                        <td className="text-left border-bottom-0">{cartItems.length ? (commas(generateCartFinalTotalPrice(cartItems)) + ' تومان') : '0 تومان'}</td>
                                                    </tr>
                                                    <tr className="summary-subtotal">
                                                        <td className="border-bottom-0">هزینه ارسال : </td>
                                                        <td className="text-left border-bottom-0">
                                                            {cartItems.length ? (commas(shipmentPrice) + ' تومان') : '0 تومان'}
                                                        </td>
                                                    </tr>

                                                    <tr className="summary-subtotal">
                                                        <td>تخفیف سفارش : </td>
                                                        <td className="text-left">{cartItems.length ? (commas(getDiscount(generateCartFinalTotalPrice(cartItems)))) : '0 تومان'}</td>
                                                    </tr>
                                                    <tr className="summary-total">
                                                        <td>مبلغ قابل پرداخت :</td>
                                                        <td className="text-left">{cartItems.length ? (commas(generateCartFinalTotalPrice(cartItems) - getDiscount(generateCartFinalTotalPrice(cartItems)) + shipmentPrice) + ' تومان') : '0 تومان'}</td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            {(cartItems && cartItems.length && paymentTypes && paymentTypes.length === 2 && hasVipItem(cartItems))
                                                ?
                                                <div className="d-flex align-items-center py-2 py-sm-3">
                                                    <div className="flex-shrink-0 small font-weight-bold">شیوه پرداخت :</div>
                                                    <div className="flex-fill d-flex align-items-center justify-content-end">
                                                        <span className="small d-block text-center" style={{ width: '50px' }}>{paymentTypes[0].display_title}</span>
                                                        <div
                                                            style={{
                                                                margin: '0 5px',
                                                                position: 'relative',
                                                                width: '30px', // Width of the switch
                                                                height: '17px', // Height of the switch
                                                                backgroundColor: paymentTogglerIsOn ? 'rgb(163, 220, 165)' : 'rgb(149, 221, 222)', // Background color based on state
                                                                borderRadius: '17px', // Rounded corners
                                                                cursor: 'pointer',
                                                                transition: 'background-color 0.2s ease-in-out',
                                                            }}
                                                            onClick={paymentToggle} // Trigger toggle on click
                                                        >
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: '13px', // Width of the knob
                                                                    height: '13px', // Height of the knob
                                                                    backgroundColor: '#fff',
                                                                    borderRadius: '50%', // Make it circular
                                                                    transition: 'transform 0.3s ease-in-out',
                                                                    transform: paymentTogglerIsOn ? 'translateX(13px)' : 'translateX(0)', // Move the knob
                                                                    top: '2px', // Vertical alignment
                                                                    left: '2px', // Initial horizontal alignment
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <span className="small d-block text-center" style={{ width: '50px' }}>{paymentTypes[1].display_title}</span>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                            }
                                            <div className="d-flex align-items-center py-2 py-sm-3 pb-sm-4">
                                                {shipmentTypes.length === 2 ? <div className="flex-shrink-0 small font-weight-bold">شیوه ارسال :</div> : ''}
                                                {shipmentTypes.length === 2 ? <div className="flex-fill d-flex align-items-center justify-content-end">
                                                    <span className="small d-block text-center" style={{ width: '50px' }}>{shipmentTypes[0].display_title}</span>
                                                    <div
                                                        style={{
                                                            margin: '0 5px',
                                                            position: 'relative',
                                                            width: '30px', // Width of the switch
                                                            height: '17px', // Height of the switch
                                                            backgroundColor: shipmentTogglerIsOn ? 'rgb(163, 220, 165)' : 'rgb(149, 221, 222)', // Background color based on state
                                                            borderRadius: '17px', // Rounded corners
                                                            cursor: 'pointer',
                                                            transition: 'background-color 0.2s ease-in-out',
                                                        }}
                                                        onClick={shipmentToggle} // Trigger toggle on click
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                width: '13px', // Width of the knob
                                                                height: '13px', // Height of the knob
                                                                backgroundColor: '#fff',
                                                                borderRadius: '50%', // Make it circular
                                                                transition: 'transform 0.3s ease-in-out',
                                                                transform: shipmentTogglerIsOn ? 'translateX(13px)' : 'translateX(0)', // Move the knob
                                                                top: '2px', // Vertical alignment
                                                                left: '2px', // Initial horizontal alignment
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <span className="small d-block text-center" style={{ width: '50px' }}>{shipmentTypes[1].display_title}</span>
                                                </div> : ''}
                                            </div>
                                            <div className="btn btn-outline-primary-2 btn-order btncart1" onClick={() => {
                                                setUpdate(0);
                                                setTimeout(() => {
                                                    setUpdate(1);
                                                }, 200);
                                            }}>
                                                <span>{update ? 'بروزرسانی' : '...'}</span><i className="icon-refresh"></i>
                                            </div>
                                            <div className="btn btn-outline-primary-2 btn-order float-left btncart2"
                                                onClick={() => {
                                                    if (cartItems.length) {
                                                        navigate('/order?shipment_type=' + selectedShipment + '&payment_type=' + selectedPayment + '&has_vip=' + hasVipItem(cartItems));
                                                    } else {
                                                        alert('سبد خرید خالی می باشد.', 'danger', 'error', 5);
                                                    }
                                                }}>
                                                <span>ادامه سفارش</span><i className="icon-long-arrow-left"></i>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </CommonLAyout>
    );
};
import { React, useState, useEffect, useRef } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
    useParams,
    useLocation,
    Navigate
} from 'react-router-dom';

import ProductImages from './ProductImages';
import ProductOptions from './ProductOptions';
import ProductDetails from './ProductDetails';
import Video from './Video';
import Suggestion from './Suggestion';

import './Product.css';

import { commas } from '../../Utils/Commas';




import { apiClient } from '../../Services/Api';




// (query-string) from npm
const queryString = require('query-string');





export default function Product() {



    let location = useLocation();
    const parsed = queryString.parse(location.search);

    const [product, setProduct] = useState({});
    const [images, setImages] = useState([]);

    const [basePrice, setBasePrice] = useState(0);
    const [additional, setAdditional] = useState(0);


    const [quantity, setQuantity] = useState(1);

    const [sewingTypes, setSewingTypes] = useState([]);


    const addToCartRef = useRef(null);

    const callAddToCart = () => {
        if (addToCartRef.current) {
          addToCartRef.current();
        }
      };



    useEffect(() => {


        // setTimeout(() => {

        //     setProduct({
        //         "name": "محصول 2",
        //         "code": "product-2",
        //         "category_title": "کت و دامن",
        //         "category_slug": "kotvadaaman",
        //         "price": 2000000,
        //         "discounted_price": 1500000,
        //         "image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U.jpg",
        //         "large_image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U_l.jpg",
        //         "medium_image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U_m.jpg",
        //         "small_image": "https://tailor.7modir.com/storage/product/image/0mFnOVCDgaoYnKCbdRD8ZlOFYemLGlRqxJNnte6U_s.jpg",
        //         "extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q.jpg",
        //         "large_extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q_l.jpg",
        //         "medium_extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q_m.jpg",
        //         "small_extra_image": "https://tailor.7modir.com/storage/product/image/6ZjIWp7kOC4rC4rnrMS4MqreyydfUEXllE1dyM2q_s.jpg",
        //         "description": "<p>لورم ایپسوم متن پیش ساخته</p>",
        //         "inventory": 8,
        //         "thumbnail": "https://tailor.7modir.com/storage/product/image/YBiVRaOxCcTvxCmRWqEJeif1pZhTqwSUVU6AbL5h.jpg",
        //         "video": null,
        //         "video_image": null,
        //         "images": [
        //           {
        //             "image": "https://tailor.7modir.com/storage/product/image/GriTkyvRjuxej6dPW98636fweFHT23T2gBb0H8fa.jpg",
        //             "large_image": "https://tailor.7modir.com/storage/product/image/GriTkyvRjuxej6dPW98636fweFHT23T2gBb0H8fa_l.jpg",
        //             "small_image": "https://tailor.7modir.com/storage/product/image/GriTkyvRjuxej6dPW98636fweFHT23T2gBb0H8fa_s.jpg"
        //           },
        //           {
        //             "image": "https://tailor.7modir.com/storage/product/image/HnWvZ0KtTTtMl1qat6ch0XNqip1h4R3FOiXiws9S.jpg",
        //             "large_image": "https://tailor.7modir.com/storage/product/image/HnWvZ0KtTTtMl1qat6ch0XNqip1h4R3FOiXiws9S_l.jpg",
        //             "small_image": "https://tailor.7modir.com/storage/product/image/HnWvZ0KtTTtMl1qat6ch0XNqip1h4R3FOiXiws9S_s.jpg"
        //           }
        //         ],
        //         "attributes": [
        //           {
        //             "name": "سایز",
        //             "slug": "size",
        //             "input_type": "single_select",
        //             "description": "سایز را انتخاب کنید",
        //             "default": null,
        //             "min_value": null,
        //             "max_value": null,
        //             "step": null,
        //             "is_common": true,
        //             "is_mandatory": false,
        //             "options": [
        //               {
        //                 "id": 5,
        //                 "value": "شخصی دوز",
        //                 "extra_price": 300000,
        //                 "is_default": false
        //               },
        //               {
        //                 "id": 4,
        //                 "value": "4",
        //                 "extra_price": 200000,
        //                 "is_default": false
        //               },
        //               {
        //                 "id": 3,
        //                 "value": "3",
        //                 "extra_price": 100000,
        //                 "is_default": false
        //               },
        //               {
        //                 "id": 2,
        //                 "value": "2",
        //                 "extra_price": 0,
        //                 "is_default": false
        //               },
        //               {
        //                 "id": 1,
        //                 "value": "1",
        //                 "extra_price": 0,
        //                 "is_default": false
        //               }
        //             ]
        //           },
        //           {
        //             "name": "دور ران",
        //             "slug": "dor-ran",
        //             "input_type": "number",
        //             "description": null,
        //             "default": "30",
        //             "min_value": 20,
        //             "max_value": 100,
        //             "step": 1,
        //             "is_common": true,
        //             "is_mandatory": true,
        //             "options": []
        //           },
        //           {
        //             "name": "رنگ",
        //             "slug": "color",
        //             "input_type": "single_select",
        //             "description": "رنگ را انتخاب کنید",
        //             "default": null,
        //             "min_value": null,
        //             "max_value": null,
        //             "step": null,
        //             "is_common": true,
        //             "is_mandatory": true,
        //             "options": [
        //               {
        //                 "id": 14,
        //                 "value": "آبی",
        //                 "extra_price": 0,
        //                 "is_default": false
        //               },
        //               {
        //                 "id": 13,
        //                 "value": "قرمز",
        //                 "extra_price": 0,
        //                 "is_default": false
        //               }
        //             ]
        //           },
        //           {
        //             "name": "قد تنه",
        //             "slug": "qad-tane",
        //             "input_type": "single_select",
        //             "description": "قد تنه را انتخاب کنید",
        //             "default": null,
        //             "min_value": null,
        //             "max_value": null,
        //             "step": null,
        //             "is_common": false,
        //             "is_mandatory": false,
        //             "options": [
        //               {
        //                 "id": 11,
        //                 "value": "70 سانت",
        //                 "extra_price": 50000,
        //                 "is_default": false
        //               },
        //               {
        //                 "id": 10,
        //                 "value": "60 سانت",
        //                 "extra_price": 0,
        //                 "is_default": false
        //               },
        //               {
        //                 "id": 9,
        //                 "value": "50 سانت",
        //                 "extra_price": 0,
        //                 "is_default": false
        //               }
        //             ]
        //           },
        //           {
        //             "name": "دور کمر",
        //             "slug": "dor-kamar",
        //             "input_type": "number",
        //             "description": null,
        //             "default": "40",
        //             "min_value": 30,
        //             "max_value": 90,
        //             "step": 1,
        //             "is_common": true,
        //             "is_mandatory": true,
        //             "options": []
        //           }
        //         ],
        //         "related_products": []
        //       });
        

        // }, 150);

        apiClient.get('product/' + parsed.slug).then(response => {
            if (response.request.status === 200) {
                setProduct(response.data.data);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });



        // setTimeout(() => {
        //     setSewingTypes(
        //         [
        //             {
        //                 "id": 1,
        //                 "title": "yes",
        //                 "display_title": "عمومی",
        //                 "price": 0
        //             },
        //             {
        //                 "id": 2,
        //                 "title": "no",
        //                 "display_title": "شخصی",
        //                 "price": 300000
        //             }
        //         ]
        //     );
        // }, 250);

        apiClient.get('config').then(response => {
            if (response.request.status === 200) {
                setSewingTypes(response.data.data.sewing_types);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });


    }, []);





    useEffect(() => {
        if (product && product.name && !images.length) {

            // setImages(
            //     oldArray =>
            //         [...oldArray,
            //         {
            //             small: product.small_image,
            //             big: product.large_image,
            //             zoom: product.image,
            //             alt: product.title,
            //         },
            //         {
            //             small: product.small_extra_image,
            //             big: product.large_extra_image,
            //             zoom: product.extra_image,
            //             alt: product.title,
            //         },
            //         product.images[0] && {
            //             small: product.images[0].small_image,
            //             big: product.images[0].large_image,
            //             zoom: product.images[0].image,
            //             alt: product.images[0].title,
            //         },
            //         product.images[1] && {
            //             small: product.images[1].small_image,
            //             big: product.images[1].large_image,
            //             zoom: product.images[1].image,
            //             alt: product.images[1].title,
            //         },
            //         ]);

            setImages(oldArray => [
                ...oldArray,
                {
                    small: product.small_image,
                    big: product.large_image,
                    zoom: product.image,
                    alt: product.title,
                },
                {
                    small: product.small_extra_image,
                    big: product.large_extra_image,
                    zoom: product.extra_image,
                    alt: product.title,
                },
                ...(product.images[0] ? [{
                    small: product.images[0].small_image,
                    big: product.images[0].large_image,
                    zoom: product.images[0].image,
                    alt: product.images[0].title,
                }] : []),
                ...(product.images[1] ? [{
                    small: product.images[1].small_image,
                    big: product.images[1].large_image,
                    zoom: product.images[1].image,
                    alt: product.images[1].title,
                }] : [])
            ]);
            

        }

        if (product && product.name && product.inventory) {
            if (product.discounted_price) {
                setBasePrice(product.discounted_price);
            } else {
                setBasePrice(product.price);
            }
        }

    }, [product]);



    // useEffect(() => {
    //     console.log(images);
    // }, [images]);










    // const images = [
    //     {
    //         small: 'https://picsum.photos/id/1/30/40',
    //         big: 'https://picsum.photos/id/1/300/400',
    //         zoom: 'https://picsum.photos/id/1/3000/4000',
    //         alt: 'Product Image 1',
    //     },
    //     {
    //         small: 'https://picsum.photos/id/2/30/40',
    //         big: 'https://picsum.photos/id/2/300/400',
    //         zoom: 'https://picsum.photos/id/2/3000/4000',
    //         alt: 'Product Image 2',
    //     },
    //     {
    //         small: 'https://picsum.photos/id/3/30/40',
    //         big: 'https://picsum.photos/id/3/300/400',
    //         zoom: 'https://picsum.photos/id/3/3000/4000',
    //         alt: 'Product Image 3',
    //     },


    // ];


    return (
        <CommonLAyout>


            <main className="main">

                <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
                    <div className="container d-flex align-items-center">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/categories">دسته بندی</Link></li>
                            <li className="breadcrumb-item active"><Link to={'/categories?slug=' + product.category_slug}>{product.category_title}</Link></li>
                            {/* <li className="breadcrumb-item active" aria-current="page">زیر دسته a-1</li> */}
                        </ol>
                    </div>
                </nav>

                <div className="page-content">
                    <div className="container">
                        <div className="product-details-top">
                            <div className="row">

                                <div className="col-md-6 col-lg-5">
                                    {images.length 
                                     ?
                                     <ProductImages images={images} />
                                     :
                                     ''
                                    }
                                </div>

                                <div className="col-md-6 col-lg-7">
                                    <ProductOptions product={product} basePrice={basePrice}
                                        additional={additional} setAdditional={setAdditional}
                                        quantity={quantity} setQuantity={setQuantity}
                                        sewingTypes={sewingTypes}
                                        triggerFunction={addToCartRef}
                                    />
                                </div>

                                <ProductDetails product={product} />

                                <Video product={product} />

                                {false && <Suggestion />}




                                <div className="sticky-bar">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-5 justify-content-start d-flex align-items-center">
                                                <figure className="product-media">
                                                    <a href="product.html">
                                                        <img src={product.thumbnail} alt="تصویر محصول" />
                                                    </a>
                                                </figure>
                                                <h4 className="product-title">{product.name}</h4>
                                            </div>

                                            <div className="col-7 justify-content-end d-flex align-items-center">
                                                {/* <div className="product-price">
                                                    84,000 تومان
                                                </div> */}
                                                {
                                                    product.inventory
                                                        ?
                                                        (
                                                            product.discounted_price
                                                                ?
                                                                <div className="product-price">
                                                                    <span className="new-price">{commas(parseInt(product.discounted_price) + parseInt(additional)) + ' تومان'}</span>
                                                                    <span className="old-price">{commas(parseInt(product.price) + parseInt(additional)) + ' تومان'}</span>
                                                                </div>
                                                                :
                                                                <div className="product-price">
                                                                    {commas(parseInt(product.price) + parseInt(additional)) + ' تومان'}
                                                                </div>
                                                        )
                                                        :
                                                        (
                                                        product&& product.name && !product.inventory
                                                        ?
                                                        <div className="product-price">
                                                            ناموجود
                                                        </div>
                                                        :
                                                        ''
                                                        )
                                                }
                                                {(product && product.inventory && (quantity + 10000000)) && <div className="product-details-quantity">
                                                    <input type="number" id="sticky-cart-qty" className="form-control" value={quantity} onChange={(e) => { setQuantity(e.target.value) }} step="1" data-decimals="0" required />
                                                </div>}

                                                <div className="product-details-action">
                                                    {(product && product.inventory) ? <a href="#" onClick={(e) => {e.preventDefault(); callAddToCart()}} className="btn-product btn-cart"><span>افزودن به سبد خرید</span></a> : ''}
                                                    <a href="#" className="btn-product btn-wishlist" title="لیست علاقه مندی"><span>افزودن به علاقه
                                                        مندی</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>


            </main>


        </CommonLAyout>
    );
};
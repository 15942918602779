import { React, useEffect, useRef } from 'react';
import { persianCharsRegExp, hasPersianChars } from '../../Utils/RegExp';
import { fixNumbers } from '../../Utils/FixNumbers';
import { Link, useLocation } from 'react-router-dom';









export default function PhoneForm(props) {



    const handlePhone = props.handlePhone;
    const phone = props.phone;
    const setPhone = props.setPhone;
    const hasPhoneError = props.hasPhoneError;
    const isLoading = props.isLoading;
    const generalError = props.generalError;
    const customError = props.customError;
    const inputRef = useRef(null);
    const buttonRef = useRef(null);
    useEffect(() => {
        inputRef.current.focus();
    }, []);
    useEffect(() => {
        if (phone.length === 11) {
            buttonRef.current.focus();
            buttonRef.current.click();
        }
    }, [phone]);



    return (




        <div className="form-box">
            <div className="form-tab">
                <ul className="nav nav-pills nav-fill" role="tablist">
                    <li className="nav-item">
                        <div className="nav-link active" id="signin-tab" to="#signin" role="tab"
                            aria-controls="signin" aria-selected="true">ورود / ثبت نام</div>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                        <form>
                            <div className="form-group">
                                <label htmlFor="phone">شماره موبایل:</label>
                                <input type="text" className={'form-control ' + ((hasPhoneError || generalError || customError) && 'danger')} id="phone"
                                    name="phone" ref={inputRef} value={phone} onChange={e => setPhone(fixNumbers(e.target.value))} />
                            </div>
                            <div className="form-footer">
                                <button type="submit" className="btn btn-outline-primary" ref={buttonRef} onClick={handlePhone}>
                                    <span>
                                        {isLoading ? 'لطفا صبر کنید' : 'دریافت کد ورود'}
                                    </span>
                                    <i className="icon-long-arrow-left"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>





    )



}
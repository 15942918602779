import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import './Faq.css';


import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  useLocation,
  Navigate
} from 'react-router-dom';




export default function Faq() {


  return (
    <CommonLAyout>



<main className="main" dir="rtl">
      <div className="page-header text-center" style={{ backgroundImage: `url('/images/page-header-bg.jpg')` }}>
        <div className="container">
          <h1 className="page-title text-center">سوالات متداول<span className="text-center">مزون ماندانا</span></h1>
        </div>
        {/* End .container */}
      </div>
      {/* End .page-header */}

      <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/index-1">خانه</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/categories">فروشگاه</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                سوالات متداول
              </li>
            </ol>
          </div>
        </nav>
      {/* End .breadcrumb-nav */}

  
      <div className="page-content">
        <div className="container">
          <h2 className="title text-center mb-3">لورم ایپسوم</h2>
          {/* End .title */}
          <div className="accordion accordion-rounded" id="accordion-1">
            {/* FAQ Card 1 */}
            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-1">
                <h2 className="card-title">
                  <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                    چگونه محصولات به من تحویل می شود؟
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div id="collapse-1" className="collapse show" aria-labelledby="heading-1" data-parent="#accordion-1">
                <div className="card-body">
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم...
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            {/* FAQ Card 2 */}
            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-2">
                <h2 className="card-title">
                  <a role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                    آیا می توانم سفارش خود را لغو کنم؟
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div id="collapse-2" className="collapse" aria-labelledby="heading-2" data-parent="#accordion-1">
                <div className="card-body">
                  بله، می توانید سفارش خود را تا قبل از ارسال لغو کنید. لطفاً با تیم پشتیبانی تماس بگیرید.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            {/* FAQ Card 3 */}
            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-3">
                <h2 className="card-title">
                  <a role="button" data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                    چگونه می توانم هزینه را پرداخت کنم؟
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div id="collapse-3" className="collapse" aria-labelledby="heading-3" data-parent="#accordion-1">
                <div className="card-body">
                  شما می توانید هزینه را از طریق درگاه های بانکی معتبر پرداخت کنید.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            {/* FAQ Card 4 */}
            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-4">
                <h2 className="card-title">
                  <a role="button" data-toggle="collapse" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                    آیا امکان بازگشت کالا وجود دارد؟
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div id="collapse-4" className="collapse" aria-labelledby="heading-4" data-parent="#accordion-1">
                <div className="card-body">
                  بله، می توانید کالا را ظرف مدت ۷ روز پس از تحویل بازگردانید، مشروط به اینکه کالا در شرایط اولیه خود باشد.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            {/* FAQ Card 5 */}
            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-5">
                <h2 className="card-title">
                  <a role="button" data-toggle="collapse" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                    چگونه می توانم با پشتیبانی مشتریان تماس بگیرم؟
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div id="collapse-5" className="collapse" aria-labelledby="heading-5" data-parent="#accordion-1">
                <div className="card-body">
                  شما می توانید از طریق صفحه تماس با ما یا با شماره تلفن‌های موجود در سایت با پشتیبانی تماس بگیرید.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}
          </div>
          {/* End .accordion */}
        </div>
        {/* End .container */}
      </div>
      {/* End .page-content */}

      <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(/images/backgrounds/cta/bg-7.jpg)` }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9 col-xl-7">
              <div className="row no-gutters flex-column flex-sm-row align-items-sm-center">
                <div className="col-8">
                  <h3 className="cta-title text-white text-right">اگر سوالات بیشتری دارید</h3>
                </div>
                {/* End .col */}
                <div className="col-auto">
                  <Link to="/contact" className="btn btn-outline-white"><span>تماس با ما</span><i className="icon-long-arrow-left"></i></Link>
                </div>
                {/* End .col-auto */}
              </div>
              {/* End .row no-gutters */}
            </div>
            {/* End .col-md-10 col-lg-9 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .cta */}
    </main>



    </CommonLAyout>
  );
};
import React from 'react';

const Video = (props) => {
  const { product } = props;
  return (
    <>
      {product.video && <div className="video-banner w-100 video-banner-poster text-center mb-5">
        <div className="w-100 text-center d-flex justify-content-center">
          <div className="row w-100 align-items-center">
            <div className="col-12 mb-3 mb-md-0">
              <h3 className="video-banner-title h3 mb-3">
                <span className="text-primary text-center">ویدیو محصول</span>
              </h3>
            </div>

            <div className="col-12">
              <div className="video-poster w-100 d-flex justify-content-center">
                <video width="450" controls poster={product.video_image} style={{ maxWidth: '450px' }}>
                  <source src={product.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Video;

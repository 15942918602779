import React, { useState, useEffect } from 'react';
import 'magnific-popup';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';





const ProductImages = ({ images }) => {


  const [activeImage, setActiveImage] = useState(images[0]);

  useEffect(() => {
    // Initialize ElevateZoom, OwlCarousel, and MagnificPopup if needed
  }, []);

  
  const handleThumbnailClick = (image) => {
    setActiveImage(image);
  };

  const openPopup = () => {
    // Logic to open MagnificPopup with gallery
  };

  return (
    <div className="product-gallery product-gallery-vertical">
      <div className="row">
        <figure className="product-main-image">
          <img
            id="product-zoom"
            src={activeImage.big}
            data-zoom-image={activeImage.zoom}
            alt={activeImage.alt}
          />
          <button id="btn-product-gallery" className="btn-product-gallery" onClick={openPopup}>
            <i className="icon-arrows"></i>
          </button>
        </figure>

        <div id="product-zoom-gallery" className="product-image-gallery">
          {images.map((image) => (
            <button
              key={image.small}
              className={`product-gallery-item ${image === activeImage ? 'active' : ''}`}
              onClick={() => handleThumbnailClick(image)}
            >
              <img src={image.small} alt={image.alt} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductImages;

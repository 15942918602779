// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Link,
//   useNavigate,
//   useParams,
//   useLocation,
//   Navigate
// } from 'react-router-dom';
// import { AuthContext } from '../Context/AuthContext';
// import React, { useContext, useEffect } from 'react';
// export default function LoggedInRoute({ children, ...rest }) {
//     const Auth = useContext(AuthContext);
//     const location = useLocation();
//     return (
//         <Route {...rest}>
//             {(Auth.maandaanaaLoggedIn) ? children : <Navigate to={`/login${location.pathname}${location.search}${location.hash}`} replace />}
//         </Route>
//     )
// }


import {
    Navigate,
    useLocation
  } from 'react-router-dom';
  import { AuthContext } from '../Context/AuthContext';
  import React, { useContext } from 'react';
  
  export default function LoggedInRoute({ element }) {
      const Auth = useContext(AuthContext);
      const location = useLocation();
  
      return Auth.maandaanaaLoggedIn 
          ? element 
        //   : <Navigate to={`/login?redirect=${location.pathname}${location.search}${location.hash}`} replace />;
          : <Navigate to={`/login${location.pathname}${location.search}${location.hash}`} replace />;
  }
  

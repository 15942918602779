import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import './Contact.css';


import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  useLocation,
  Navigate
} from 'react-router-dom';




export default function Contact() {


  return (
    <CommonLAyout>


      <main className="main">
        <div
          className="page-header text-center"
          style={{ backgroundImage: "url('/images/page-header-bg.jpg')" }}
        >
          <div className="container">
            <h1 className="page-title text-center">
              تماس با ما<span className="text-center">مزون ماندانا</span>
            </h1>
          </div>
        </div>

        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/index-1">خانه</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/categories">فروشگاه</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                تماس با ما
              </li>
            </ol>
          </div>
        </nav>

        <div className="page-content">
          <div className="col-12 d-flex mb-5">
            <div className="bg-white w-100">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.0762034011345!2d51.33938432078328!3d35.69974233666176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDQxJzU5LjEiTiA1McKwMjAnMTMuOSJF!5e0!3m2!1sfa!2s!4v1585894944267!5m2!1sfa!2s"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="contact-box text-center">
                  <h3>دفتر</h3>
                  <address>
                    تهران، میدان آزادی <br />
                    خیابان آزادی، پلاک 7
                  </address>
                </div>
              </div>

              <div className="col-md-4">
                <div className="contact-box text-center">
                  <h3>اطلاعات تماس</h3>
                  <div>
                    <a href="mailto:#">info@exaple.com</a>
                  </div>
                  <div>
                    <a href="tel:#">55667788</a>، <a href="tel:#">99887733</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="contact-box text-center">
                  <h3>شبکه های اجتماعی</h3>

                  <div className="social-icons social-icons-color justify-content-center">
                    <a
                      href="#"
                      className="social-icon social-facebook"
                      title="فیسبوک"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon-facebook-f"></i>
                    </a>
                    <a
                      href="#"
                      className="social-icon social-twitter"
                      title="توییتر"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon-twitter"></i>
                    </a>
                    <a
                      href="#"
                      className="social-icon social-instagram"
                      title="اینستاگرام"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon-instagram"></i>
                    </a>
                    <a
                      href="#"
                      className="social-icon social-youtube"
                      title="یوتیوب"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon-youtube"></i>
                    </a>
                    <a
                      href="#"
                      className="social-icon social-pinterest"
                      title="پینترست"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon-pinterest"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </CommonLAyout>
  );
};
const Provinces = [
    {
      "id": 1,
      "name": "آذربایجان شرقی",
      "cities": [
        {
          "id": 1,
          "name": "آذرشهر",
          "districts": [
            {
              "id": 840,
              "name": "آذر شهر"
            },
            {
              "id": 1021,
              "name": "تیمورلو"
            },
            {
              "id": 1054,
              "name": "گوگان"
            },
            {
              "id": 686,
              "name": "ممقان"
            }
          ]
        },
        {
          "id": 2,
          "name": "اسکو",
          "districts": [
            {
              "id": 1232,
              "name": "اسکو"
            },
            {
              "id": 807,
              "name": "ایلخچی"
            },
            {
              "id": 1631,
              "name": "شهرجدیدسهند"
            }
          ]
        },
        {
          "id": 3,
          "name": "اهر",
          "districts": [
            {
              "id": 941,
              "name": "اهر"
            }
          ]
        },
        {
          "id": 4,
          "name": "بستان آباد",
          "districts": [
            {
              "id": 714,
              "name": "بستان آباد"
            },
            {
              "id": 473,
              "name": "تیکمه داش"
            },
            {
              "id": 1297,
              "name": "کردکندی"
            }
          ]
        },
        {
          "id": 5,
          "name": "بناب",
          "districts": [
            {
              "id": 995,
              "name": "بناب"
            },
            {
              "id": 912,
              "name": "خوشه مهر"
            }
          ]
        },
        {
          "id": 6,
          "name": "تبریز",
          "districts": [
            {
              "id": 18738,
              "name": "اسفهلان"
            },
            {
              "id": 1285,
              "name": "باسمنج"
            },
            {
              "id": 950,
              "name": "تبریز"
            },
            {
              "id": 1409,
              "name": "خسروشاه"
            },
            {
              "id": 1697,
              "name": "سردرود"
            },
            {
              "id": 18739,
              "name": "لاهیجان"
            }
          ]
        },
        {
          "id": 7,
          "name": "جلفا",
          "districts": [
            {
              "id": 799,
              "name": "جلفا"
            },
            {
              "id": 1058,
              "name": "سیه رود"
            },
            {
              "id": 1175,
              "name": "هادیشهر"
            }
          ]
        },
        {
          "id": 8,
          "name": "چاراویماق",
          "districts": [
            {
              "id": 750,
              "name": "قره آغاج"
            }
          ]
        },
        {
          "id": 9,
          "name": "خدا آفرین",
          "districts": [
            {
              "id": 1837,
              "name": "خمارلو"
            },
            {
              "id": 10169,
              "name": "عاشقلو"
            },
            {
              "id": 1080,
              "name": "لاریجان"
            }
          ]
        },
        {
          "id": 10,
          "name": "سراب",
          "districts": [
            {
              "id": 541,
              "name": "دوزدوزان"
            },
            {
              "id": 1368,
              "name": "سراب"
            },
            {
              "id": 492,
              "name": "شربیان"
            },
            {
              "id": 1833,
              "name": "مهربان"
            }
          ]
        },
        {
          "id": 11,
          "name": "شبستر",
          "districts": [
            {
              "id": 1519,
              "name": "تسوج"
            },
            {
              "id": 1331,
              "name": "خامنه"
            },
            {
              "id": 1002,
              "name": "داریان"
            },
            {
              "id": 1606,
              "name": "سیس"
            },
            {
              "id": 1158,
              "name": "شبستر"
            },
            {
              "id": 1573,
              "name": "شرفخانه"
            },
            {
              "id": 1559,
              "name": "شند آباد"
            },
            {
              "id": 573,
              "name": "صوفیان"
            },
            {
              "id": 1086,
              "name": "علیشاه (علی آباد)"
            },
            {
              "id": 1816,
              "name": "کوزه کنان"
            },
            {
              "id": 764,
              "name": "وایقان"
            }
          ]
        },
        {
          "id": 12,
          "name": "عجب شیر",
          "districts": [
            {
              "id": 1141,
              "name": "جوان قلعه"
            },
            {
              "id": 1527,
              "name": "عجب شیر"
            }
          ]
        },
        {
          "id": 13,
          "name": "کلیبر",
          "districts": [
            {
              "id": 800,
              "name": "آبش احمد"
            },
            {
              "id": 624,
              "name": "کلیبر"
            }
          ]
        },
        {
          "id": 1197,
          "name": "لیلان",
          "districts": [
            {
              "id": 1044,
              "name": "لیلان"
            }
          ]
        },
        {
          "id": 14,
          "name": "مراغه",
          "districts": [
            {
              "id": 1535,
              "name": "خداجو"
            },
            {
              "id": 1295,
              "name": "مراغه"
            }
          ]
        },
        {
          "id": 15,
          "name": "مرند",
          "districts": [
            {
              "id": 1828,
              "name": "بناب مرند"
            },
            {
              "id": 1356,
              "name": "دیزج حسین بیگ"
            },
            {
              "id": 1494,
              "name": "زنوز"
            },
            {
              "id": 949,
              "name": "کشکسرای"
            },
            {
              "id": 1371,
              "name": "مرند"
            },
            {
              "id": 1398,
              "name": "یامچی"
            }
          ]
        },
        {
          "id": 16,
          "name": "ملکان",
          "districts": [
            {
              "id": 1769,
              "name": "مبارک شهر"
            },
            {
              "id": 1159,
              "name": "ملکان"
            }
          ]
        },
        {
          "id": 17,
          "name": "میانه",
          "districts": [
            {
              "id": 1661,
              "name": "آچاچی"
            },
            {
              "id": 1417,
              "name": "آقکند"
            },
            {
              "id": 1016,
              "name": "ترک"
            },
            {
              "id": 1597,
              "name": "ترکمانچای"
            },
            {
              "id": 1855,
              "name": "میانه"
            }
          ]
        },
        {
          "id": 18,
          "name": "هریس",
          "districts": [
            {
              "id": 485,
              "name": "اربطان"
            },
            {
              "id": 682,
              "name": "بخشایش"
            },
            {
              "id": 1774,
              "name": "خواجه"
            },
            {
              "id": 1790,
              "name": "زرنق"
            },
            {
              "id": 1179,
              "name": "کلوانق"
            },
            {
              "id": 705,
              "name": "هریس"
            }
          ]
        },
        {
          "id": 19,
          "name": "هشترود",
          "districts": [
            {
              "id": 617,
              "name": "نظر کهریزی"
            },
            {
              "id": 1516,
              "name": "هشترود"
            }
          ]
        },
        {
          "id": 20,
          "name": "هوراند",
          "districts": [
            {
              "id": 842,
              "name": "هوراند"
            }
          ]
        },
        {
          "id": 21,
          "name": "ورزقان",
          "districts": [
            {
              "id": 1575,
              "name": "خاروانا"
            },
            {
              "id": 1184,
              "name": "ورزقان"
            }
          ]
        }
      ]
    },
    {
      "id": 2,
      "name": "آذربایجان غربی",
      "cities": [
        {
          "id": 22,
          "name": "ارومیه",
          "districts": [
            {
              "id": 1296,
              "name": "ارومیه"
            },
            {
              "id": 1777,
              "name": "سرو"
            },
            {
              "id": 785,
              "name": "سیلوانه"
            },
            {
              "id": 691,
              "name": "قوشچی"
            },
            {
              "id": 1048,
              "name": "نوشین"
            }
          ]
        },
        {
          "id": 23,
          "name": "اشنویه",
          "districts": [
            {
              "id": 1594,
              "name": "اشنویه"
            },
            {
              "id": 1690,
              "name": "نالوس"
            }
          ]
        },
        {
          "id": 24,
          "name": "باروق",
          "districts": [
            {
              "id": 1556,
              "name": "باروق"
            }
          ]
        },
        {
          "id": 25,
          "name": "بوکان",
          "districts": [
            {
              "id": 565,
              "name": "بوکان"
            },
            {
              "id": 1867,
              "name": "سیمینه"
            }
          ]
        },
        {
          "id": 26,
          "name": "پلدشت",
          "districts": [
            {
              "id": 1545,
              "name": "پلدشت"
            },
            {
              "id": 1024,
              "name": "نازک علیا"
            }
          ]
        },
        {
          "id": 27,
          "name": "پیرانشهر",
          "districts": [
            {
              "id": 1038,
              "name": "پیرانشهر"
            },
            {
              "id": 955,
              "name": "لاجان"
            }
          ]
        },
        {
          "id": 28,
          "name": "تکاب",
          "districts": [
            {
              "id": 741,
              "name": "تخت سلیمان"
            },
            {
              "id": 1363,
              "name": "تکاب"
            }
          ]
        },
        {
          "id": 29,
          "name": "چالدران",
          "districts": [
            {
              "id": 1026,
              "name": "آواجیق"
            },
            {
              "id": 753,
              "name": "سیه چشمه"
            }
          ]
        },
        {
          "id": 30,
          "name": "چایپاره",
          "districts": [
            {
              "id": 634,
              "name": "حاجیلار"
            },
            {
              "id": 1799,
              "name": "قره ضیاءالدین"
            }
          ]
        },
        {
          "id": 31,
          "name": "چهاربرج",
          "districts": []
        },
        {
          "id": 32,
          "name": "خوی",
          "districts": [
            {
              "id": 993,
              "name": "ایواوغلی"
            },
            {
              "id": 1768,
              "name": "خوی"
            },
            {
              "id": 653,
              "name": "دیزج دیز"
            },
            {
              "id": 801,
              "name": "زرآباد"
            },
            {
              "id": 1354,
              "name": "فیرورق"
            },
            {
              "id": 1112,
              "name": "قطور"
            }
          ]
        },
        {
          "id": 33,
          "name": "سردشت",
          "districts": [
            {
              "id": 1450,
              "name": "ربط"
            },
            {
              "id": 488,
              "name": "سردشت"
            },
            {
              "id": 771,
              "name": "میرآباد"
            },
            {
              "id": 604,
              "name": "نلاس"
            }
          ]
        },
        {
          "id": 34,
          "name": "سلماس",
          "districts": [
            {
              "id": 520,
              "name": "تازه شهر"
            },
            {
              "id": 1176,
              "name": "سلماس"
            }
          ]
        },
        {
          "id": 35,
          "name": "شاهین دژ",
          "districts": [
            {
              "id": 509,
              "name": "شاهیندژ"
            },
            {
              "id": 822,
              "name": "کشاورز"
            },
            {
              "id": 1004,
              "name": "محمودآباد"
            }
          ]
        },
        {
          "id": 36,
          "name": "شوط",
          "districts": [
            {
              "id": 1254,
              "name": "شوط"
            },
            {
              "id": 1750,
              "name": "مرگنلر"
            },
            {
              "id": 1549,
              "name": "یولاگلدی"
            }
          ]
        },
        {
          "id": 37,
          "name": "ماکو",
          "districts": [
            {
              "id": 550,
              "name": "بازرگان"
            },
            {
              "id": 1446,
              "name": "ماکو"
            }
          ]
        },
        {
          "id": 38,
          "name": "مهاباد",
          "districts": [
            {
              "id": 943,
              "name": "خلیفان"
            },
            {
              "id": 1715,
              "name": "گوگ تپه"
            },
            {
              "id": 1618,
              "name": "مهاباد"
            }
          ]
        },
        {
          "id": 39,
          "name": "میاندوآب",
          "districts": [
            {
              "id": 1301,
              "name": "بکتاش"
            },
            {
              "id": 1003,
              "name": "چهار برج"
            },
            {
              "id": 518,
              "name": "میاندوآب"
            }
          ]
        },
        {
          "id": 1266,
          "name": "میرآباد",
          "districts": []
        },
        {
          "id": 40,
          "name": "نقده",
          "districts": [
            {
              "id": 1870,
              "name": "محمدیار"
            },
            {
              "id": 1063,
              "name": "نقده"
            }
          ]
        }
      ]
    },
    {
      "id": 3,
      "name": "اردبیل",
      "cities": [
        {
          "id": 41,
          "name": "اردبیل",
          "districts": [
            {
              "id": 1468,
              "name": "آراللو"
            },
            {
              "id": 597,
              "name": "اردبیل"
            },
            {
              "id": 1728,
              "name": "ثمرین"
            },
            {
              "id": 1302,
              "name": "هیر"
            }
          ]
        },
        {
          "id": 42,
          "name": "اصلاندوز",
          "districts": [
            {
              "id": 1148,
              "name": "اصلاندوز"
            },
            {
              "id": 12955,
              "name": "بران علیا"
            }
          ]
        },
        {
          "id": 1190,
          "name": "انگوت",
          "districts": [
            {
              "id": 18642,
              "name": "زیوه"
            }
          ]
        },
        {
          "id": 43,
          "name": "بیله سوار",
          "districts": [
            {
              "id": 1789,
              "name": "بیله سوار"
            },
            {
              "id": 1633,
              "name": "جعفر آباد"
            }
          ]
        },
        {
          "id": 44,
          "name": "پارس آباد",
          "districts": [
            {
              "id": 1293,
              "name": "اسلام آباد"
            },
            {
              "id": 1679,
              "name": "اولتان"
            },
            {
              "id": 855,
              "name": "پارس آباد"
            },
            {
              "id": 1634,
              "name": "مغانسر"
            }
          ]
        },
        {
          "id": 45,
          "name": "خلخال",
          "districts": [
            {
              "id": 1431,
              "name": "خلخال"
            },
            {
              "id": 730,
              "name": "کلور"
            },
            {
              "id": 1500,
              "name": "هشتجین"
            }
          ]
        },
        {
          "id": 46,
          "name": "سرعین",
          "districts": [
            {
              "id": 1433,
              "name": "اردیموسی"
            },
            {
              "id": 1217,
              "name": "سرعین"
            }
          ]
        },
        {
          "id": 47,
          "name": "کوثر",
          "districts": [
            {
              "id": 12954,
              "name": "فیروزآباد"
            },
            {
              "id": 1793,
              "name": "گیوی"
            }
          ]
        },
        {
          "id": 48,
          "name": "گرمی",
          "districts": [
            {
              "id": 1612,
              "name": "تازه کندانگوت"
            },
            {
              "id": 552,
              "name": "زهرا"
            },
            {
              "id": 1834,
              "name": "گرمی"
            }
          ]
        },
        {
          "id": 49,
          "name": "مشگین شهر",
          "districts": [
            {
              "id": 681,
              "name": "آلنی"
            },
            {
              "id": 609,
              "name": "رضی"
            },
            {
              "id": 1508,
              "name": "فخرآباد"
            },
            {
              "id": 1101,
              "name": "قصابه"
            },
            {
              "id": 533,
              "name": "لاهرود"
            },
            {
              "id": 1140,
              "name": "مرادلو"
            },
            {
              "id": 674,
              "name": "مشگین شهر"
            }
          ]
        },
        {
          "id": 50,
          "name": "نمین",
          "districts": [
            {
              "id": 1763,
              "name": "آبی بیگلو"
            },
            {
              "id": 1445,
              "name": "عنبران"
            },
            {
              "id": 1379,
              "name": "نمین"
            }
          ]
        },
        {
          "id": 51,
          "name": "نیر",
          "districts": [
            {
              "id": 1257,
              "name": "کورائیم"
            },
            {
              "id": 1470,
              "name": "نیر"
            }
          ]
        }
      ]
    },
    {
      "id": 4,
      "name": "اصفهان",
      "cities": [
        {
          "id": 52,
          "name": "آران و بیدگل",
          "districts": [
            {
              "id": 1381,
              "name": "آران و بیدگل"
            },
            {
              "id": 1622,
              "name": "ابوزیدآباد"
            },
            {
              "id": 793,
              "name": "سفیدشهر"
            },
            {
              "id": 1255,
              "name": "نوش آباد"
            }
          ]
        },
        {
          "id": 53,
          "name": "اردستان",
          "districts": [
            {
              "id": 1571,
              "name": "اردستان"
            },
            {
              "id": 1701,
              "name": "زواره"
            },
            {
              "id": 649,
              "name": "مهاباد"
            }
          ]
        },
        {
          "id": 54,
          "name": "اصفهان",
          "districts": [
            {
              "id": 539,
              "name": "اصفهان"
            },
            {
              "id": 1095,
              "name": "بهارستان"
            },
            {
              "id": 781,
              "name": "زیار"
            },
            {
              "id": 886,
              "name": "قهجاورستان"
            }
          ]
        },
        {
          "id": 55,
          "name": "برخوار",
          "districts": [
            {
              "id": 709,
              "name": "حبیب آباد"
            },
            {
              "id": 1608,
              "name": "خورزوق"
            },
            {
              "id": 635,
              "name": "دستگرد"
            },
            {
              "id": 1343,
              "name": "دولت آباد"
            },
            {
              "id": 1696,
              "name": "سین"
            },
            {
              "id": 478,
              "name": "شاپورآباد"
            },
            {
              "id": 1234,
              "name": "کمشچه"
            }
          ]
        },
        {
          "id": 56,
          "name": "بوئین میاندشت",
          "districts": [
            {
              "id": 961,
              "name": "افوس"
            },
            {
              "id": 542,
              "name": "بوئین میاندشت"
            }
          ]
        },
        {
          "id": 57,
          "name": "تیران و کرون",
          "districts": [
            {
              "id": 1730,
              "name": "تیران"
            },
            {
              "id": 778,
              "name": "رضوانشهر"
            },
            {
              "id": 1348,
              "name": "عسگران"
            }
          ]
        },
        {
          "id": 58,
          "name": "جرقویه",
          "districts": [
            {
              "id": 1852,
              "name": "حسن آباد"
            },
            {
              "id": 1771,
              "name": "محمدآباد"
            },
            {
              "id": 1018,
              "name": "نصرآباد"
            },
            {
              "id": 699,
              "name": "نیک آباد"
            }
          ]
        },
        {
          "id": 59,
          "name": "چادگان",
          "districts": [
            {
              "id": 1249,
              "name": "چادگان"
            },
            {
              "id": 1841,
              "name": "رزوه"
            }
          ]
        },
        {
          "id": 60,
          "name": "خمینی شهر",
          "districts": [
            {
              "id": 1025,
              "name": "اصغرآباد"
            },
            {
              "id": 1803,
              "name": "خمینی شهر"
            },
            {
              "id": 688,
              "name": "درچه"
            },
            {
              "id": 1271,
              "name": "کوشک"
            }
          ]
        },
        {
          "id": 61,
          "name": "خوانسار",
          "districts": [
            {
              "id": 899,
              "name": "خوانسار"
            },
            {
              "id": 745,
              "name": "ویست"
            }
          ]
        },
        {
          "id": 62,
          "name": "خور و بیابانک",
          "districts": [
            {
              "id": 1544,
              "name": "جندق"
            },
            {
              "id": 1522,
              "name": "خور"
            },
            {
              "id": 1428,
              "name": "فرخی"
            }
          ]
        },
        {
          "id": 63,
          "name": "دهاقان",
          "districts": [
            {
              "id": 703,
              "name": "دهاقان"
            },
            {
              "id": 1068,
              "name": "گلشن"
            }
          ]
        },
        {
          "id": 64,
          "name": "سمیرم",
          "districts": [
            {
              "id": 1203,
              "name": "بیده"
            },
            {
              "id": 1702,
              "name": "حنا"
            },
            {
              "id": 627,
              "name": "سمیرم"
            },
            {
              "id": 1083,
              "name": "فتح آباد"
            },
            {
              "id": 1242,
              "name": "کمه"
            },
            {
              "id": 1209,
              "name": "ونک"
            }
          ]
        },
        {
          "id": 65,
          "name": "شاهین شهر و میمه",
          "districts": [
            {
              "id": 1059,
              "name": "شاهین شهر"
            },
            {
              "id": 1775,
              "name": "گرگاب"
            },
            {
              "id": 1821,
              "name": "گزبرخوار"
            },
            {
              "id": 1011,
              "name": "لای بید"
            },
            {
              "id": 690,
              "name": "میمه"
            },
            {
              "id": 1035,
              "name": "وزوان"
            }
          ]
        },
        {
          "id": 66,
          "name": "شهرضا",
          "districts": [
            {
              "id": 1303,
              "name": "شهرضا"
            },
            {
              "id": 498,
              "name": "منظریه"
            }
          ]
        },
        {
          "id": 67,
          "name": "فریدن",
          "districts": [
            {
              "id": 1074,
              "name": "داران"
            },
            {
              "id": 1085,
              "name": "دامنه"
            }
          ]
        },
        {
          "id": 68,
          "name": "فریدونشهر",
          "districts": [
            {
              "id": 1031,
              "name": "برف انبار"
            },
            {
              "id": 757,
              "name": "فریدونشهر"
            }
          ]
        },
        {
          "id": 69,
          "name": "فلاورجان",
          "districts": [
            {
              "id": 820,
              "name": "ابریشم"
            },
            {
              "id": 1336,
              "name": "اشتر جان"
            },
            {
              "id": 1028,
              "name": "بهاران شهر"
            },
            {
              "id": 1579,
              "name": "بوستان"
            },
            {
              "id": 1131,
              "name": "پیربکران"
            },
            {
              "id": 1187,
              "name": "زازران"
            },
            {
              "id": 1069,
              "name": "طاد"
            },
            {
              "id": 1121,
              "name": "فلاورجان"
            },
            {
              "id": 625,
              "name": "قهدریجان"
            },
            {
              "id": 1061,
              "name": "کلیشادوسودرجان"
            },
            {
              "id": 1291,
              "name": "مینادشت"
            }
          ]
        },
        {
          "id": 70,
          "name": "کاشان",
          "districts": [
            {
              "id": 1153,
              "name": "برزک"
            },
            {
              "id": 766,
              "name": "جوشقان قالی"
            },
            {
              "id": 1042,
              "name": "قمصر"
            },
            {
              "id": 909,
              "name": "کاشان"
            },
            {
              "id": 1548,
              "name": "کامووچوگان"
            },
            {
              "id": 1096,
              "name": "مشکات"
            },
            {
              "id": 1419,
              "name": "نیاسر"
            }
          ]
        },
        {
          "id": 1185,
          "name": "کوهپایه",
          "districts": [
            {
              "id": 643,
              "name": "تودشک"
            },
            {
              "id": 1266,
              "name": "سگزی"
            },
            {
              "id": 1237,
              "name": "کوهپایه"
            }
          ]
        },
        {
          "id": 71,
          "name": "گلپایگان",
          "districts": [
            {
              "id": 1279,
              "name": "گلپایگان"
            },
            {
              "id": 1138,
              "name": "گلشهر"
            },
            {
              "id": 1383,
              "name": "گوگد"
            }
          ]
        },
        {
          "id": 72,
          "name": "لنجان",
          "districts": [
            {
              "id": 711,
              "name": "باغ بهادران"
            },
            {
              "id": 645,
              "name": "باغشاد"
            },
            {
              "id": 1698,
              "name": "چرمهین"
            },
            {
              "id": 1499,
              "name": "چمگردان"
            },
            {
              "id": 979,
              "name": "زاینده رود"
            },
            {
              "id": 1515,
              "name": "زرین شهر"
            },
            {
              "id": 1275,
              "name": "سده لنجان"
            },
            {
              "id": 1157,
              "name": "فولادشهر"
            },
            {
              "id": 683,
              "name": "ورنامخواست"
            }
          ]
        },
        {
          "id": 73,
          "name": "مبارکه",
          "districts": [
            {
              "id": 1818,
              "name": "دهسرخ"
            },
            {
              "id": 808,
              "name": "دیزیچه"
            },
            {
              "id": 1521,
              "name": "زیباشهر"
            },
            {
              "id": 1109,
              "name": "شهر جدید مجلسی"
            },
            {
              "id": 1435,
              "name": "طالخونچه"
            },
            {
              "id": 1139,
              "name": "کرکوند"
            },
            {
              "id": 1412,
              "name": "مبارکه"
            }
          ]
        },
        {
          "id": 74,
          "name": "نائین",
          "districts": [
            {
              "id": 1434,
              "name": "انارک"
            },
            {
              "id": 1804,
              "name": "بافران"
            },
            {
              "id": 1323,
              "name": "نائین"
            }
          ]
        },
        {
          "id": 75,
          "name": "نجف آباد",
          "districts": [
            {
              "id": 639,
              "name": "جوزدان"
            },
            {
              "id": 1444,
              "name": "دهق"
            },
            {
              "id": 1567,
              "name": "علویجه"
            },
            {
              "id": 1498,
              "name": "کهریزسنگ"
            },
            {
              "id": 1410,
              "name": "گلدشت"
            },
            {
              "id": 618,
              "name": "نجف آباد"
            }
          ]
        },
        {
          "id": 76,
          "name": "نطنز",
          "districts": [
            {
              "id": 554,
              "name": "بادرود"
            },
            {
              "id": 626,
              "name": "خالدآ باد"
            },
            {
              "id": 1707,
              "name": "طرق رود"
            },
            {
              "id": 1171,
              "name": "نطنز"
            }
          ]
        },
        {
          "id": 77,
          "name": "هرند",
          "districts": [
            {
              "id": 1795,
              "name": "اژیه"
            },
            {
              "id": 1640,
              "name": "هرند"
            }
          ]
        },
        {
          "id": 78,
          "name": "ورزنه",
          "districts": [
            {
              "id": 1853,
              "name": "ورزنه"
            }
          ]
        }
      ]
    },
    {
      "id": 5,
      "name": "البرز",
      "cities": [
        {
          "id": 79,
          "name": "اشتهارد",
          "districts": [
            {
              "id": 1151,
              "name": "اشتهارد"
            },
            {
              "id": 1192,
              "name": "پلنگ آباد"
            }
          ]
        },
        {
          "id": 80,
          "name": "چهار باغ",
          "districts": [
            {
              "id": 501,
              "name": "چهارباغ"
            }
          ]
        },
        {
          "id": 81,
          "name": "ساوجبلاغ",
          "districts": [
            {
              "id": 1292,
              "name": "شهرجدیدهشتگرد"
            },
            {
              "id": 958,
              "name": "کوهسار"
            },
            {
              "id": 1561,
              "name": "گلسار"
            },
            {
              "id": 525,
              "name": "هشتگرد"
            }
          ]
        },
        {
          "id": 82,
          "name": "طالقان",
          "districts": [
            {
              "id": 519,
              "name": "طالقان"
            }
          ]
        },
        {
          "id": 83,
          "name": "فردیس",
          "districts": [
            {
              "id": 629,
              "name": "فردیس"
            },
            {
              "id": 1020,
              "name": "مشکین دشت"
            }
          ]
        },
        {
          "id": 84,
          "name": "کرج",
          "districts": [
            {
              "id": 1190,
              "name": "آسارا"
            },
            {
              "id": 1727,
              "name": "کرج"
            },
            {
              "id": 906,
              "name": "کمالشهر"
            },
            {
              "id": 486,
              "name": "گرمدره"
            },
            {
              "id": 1583,
              "name": "ماهدشت"
            },
            {
              "id": 1693,
              "name": "محمدشهر"
            }
          ]
        },
        {
          "id": 85,
          "name": "نظرآباد",
          "districts": [
            {
              "id": 765,
              "name": "تنکمان"
            },
            {
              "id": 1282,
              "name": "نظرآباد"
            }
          ]
        }
      ]
    },
    {
      "id": 6,
      "name": "ایلام",
      "cities": [
        {
          "id": 86,
          "name": "آبدانان",
          "districts": [
            {
              "id": 670,
              "name": "آبدانان"
            },
            {
              "id": 602,
              "name": "سراب باغ"
            },
            {
              "id": 1022,
              "name": "مورموری"
            }
          ]
        },
        {
          "id": 87,
          "name": "ایلام",
          "districts": [
            {
              "id": 775,
              "name": "ایلام"
            },
            {
              "id": 669,
              "name": "جعفرآباد"
            }
          ]
        },
        {
          "id": 88,
          "name": "ایوان",
          "districts": [
            {
              "id": 671,
              "name": "ایوان"
            },
            {
              "id": 1313,
              "name": "زرنه"
            }
          ]
        },
        {
          "id": 89,
          "name": "بدره",
          "districts": [
            {
              "id": 1106,
              "name": "بدره"
            },
            {
              "id": 851,
              "name": "چشمه شیرین"
            }
          ]
        },
        {
          "id": 90,
          "name": "چرداول",
          "districts": [
            {
              "id": 1511,
              "name": "آسمان آباد"
            },
            {
              "id": 923,
              "name": "بلاوه"
            },
            {
              "id": 999,
              "name": "سرآبله"
            },
            {
              "id": 505,
              "name": "شباب"
            }
          ]
        },
        {
          "id": 1184,
          "name": "چوار",
          "districts": [
            {
              "id": 1142,
              "name": "چوار"
            }
          ]
        },
        {
          "id": 91,
          "name": "دره شهر",
          "districts": [
            {
              "id": 932,
              "name": "دره شهر"
            },
            {
              "id": 861,
              "name": "ماژین"
            }
          ]
        },
        {
          "id": 92,
          "name": "دهلران",
          "districts": [
            {
              "id": 570,
              "name": "پهله"
            },
            {
              "id": 18775,
              "name": "دشت عباس"
            },
            {
              "id": 1127,
              "name": "دهلران"
            },
            {
              "id": 638,
              "name": "موسیان"
            },
            {
              "id": 976,
              "name": "میمه"
            }
          ]
        },
        {
          "id": 93,
          "name": "سیروان",
          "districts": [
            {
              "id": 931,
              "name": "لومار"
            }
          ]
        },
        {
          "id": 94,
          "name": "ملکشاهی",
          "districts": [
            {
              "id": 1780,
              "name": "ارکواز"
            },
            {
              "id": 1162,
              "name": "دلگشا"
            },
            {
              "id": 1376,
              "name": "مهر"
            }
          ]
        },
        {
          "id": 95,
          "name": "مهران",
          "districts": [
            {
              "id": 632,
              "name": "صالح آباد"
            },
            {
              "id": 1322,
              "name": "مهران"
            }
          ]
        },
        {
          "id": 1183,
          "name": "هلیلان",
          "districts": [
            {
              "id": 1007,
              "name": "توحید"
            }
          ]
        }
      ]
    },
    {
      "id": 7,
      "name": "بوشهر",
      "cities": [
        {
          "id": 96,
          "name": "بوشهر",
          "districts": [
            {
              "id": 1259,
              "name": "بوشهر"
            },
            {
              "id": 641,
              "name": "چغادک"
            },
            {
              "id": 1740,
              "name": "خارک"
            },
            {
              "id": 584,
              "name": "عالی شهر"
            }
          ]
        },
        {
          "id": 97,
          "name": "تنگستان",
          "districts": [
            {
              "id": 580,
              "name": "آباد"
            },
            {
              "id": 1534,
              "name": "اهرم"
            },
            {
              "id": 1791,
              "name": "دلوار"
            }
          ]
        },
        {
          "id": 98,
          "name": "جم",
          "districts": [
            {
              "id": 1876,
              "name": "انارستان"
            },
            {
              "id": 1832,
              "name": "بهارستان"
            },
            {
              "id": 825,
              "name": "جم"
            },
            {
              "id": 1305,
              "name": "ریز"
            }
          ]
        },
        {
          "id": 99,
          "name": "دشتستان",
          "districts": [
            {
              "id": 980,
              "name": "آبپخش"
            },
            {
              "id": 767,
              "name": "برازجان"
            },
            {
              "id": 1195,
              "name": "بوشکان"
            },
            {
              "id": 1785,
              "name": "تنگ ارم"
            },
            {
              "id": 1389,
              "name": "دالکی"
            },
            {
              "id": 885,
              "name": "سعدآباد"
            },
            {
              "id": 1441,
              "name": "شبانکاره"
            },
            {
              "id": 1352,
              "name": "کلمه"
            },
            {
              "id": 1523,
              "name": "وحدتیه"
            }
          ]
        },
        {
          "id": 100,
          "name": "دشتی",
          "districts": [
            {
              "id": 1471,
              "name": "بادوله"
            },
            {
              "id": 577,
              "name": "خورموج"
            },
            {
              "id": 1079,
              "name": "شنبه"
            },
            {
              "id": 1099,
              "name": "کاکی"
            }
          ]
        },
        {
          "id": 101,
          "name": "دیر",
          "districts": [
            {
              "id": 1805,
              "name": "آبدان"
            },
            {
              "id": 515,
              "name": "بردخون"
            },
            {
              "id": 987,
              "name": "بردستان"
            },
            {
              "id": 532,
              "name": "بندردیر"
            },
            {
              "id": 1568,
              "name": "دوراهک"
            }
          ]
        },
        {
          "id": 102,
          "name": "دیلم",
          "districts": [
            {
              "id": 1052,
              "name": "امام حسن"
            },
            {
              "id": 1049,
              "name": "بندر دیلم"
            }
          ]
        },
        {
          "id": 103,
          "name": "عسلویه",
          "districts": [
            {
              "id": 727,
              "name": "بید خون"
            },
            {
              "id": 1677,
              "name": "چاه مبارک"
            },
            {
              "id": 967,
              "name": "عسلویه"
            },
            {
              "id": 1244,
              "name": "نخل تقی"
            }
          ]
        },
        {
          "id": 104,
          "name": "کنگان",
          "districts": [
            {
              "id": 1227,
              "name": "بندرکنگان"
            },
            {
              "id": 530,
              "name": "بنک"
            },
            {
              "id": 847,
              "name": "سیراف"
            }
          ]
        },
        {
          "id": 105,
          "name": "گناوه",
          "districts": [
            {
              "id": 804,
              "name": "بندر ریگ"
            },
            {
              "id": 1208,
              "name": "بندرگناوه"
            }
          ]
        }
      ]
    },
    {
      "id": 8,
      "name": "تهران",
      "cities": [
        {
          "id": 118,
          "name": "اسلامشهر",
          "districts": [
            {
              "id": 1243,
              "name": "اسلامشهر"
            },
            {
              "id": 1218,
              "name": "چهاردانگه"
            }
          ]
        },
        {
          "id": 119,
          "name": "بهارستان",
          "districts": [
            {
              "id": 694,
              "name": "صالحیه"
            },
            {
              "id": 1128,
              "name": "گلستان"
            },
            {
              "id": 1756,
              "name": "نسیم شهر"
            }
          ]
        },
        {
          "id": 120,
          "name": "پاکدشت",
          "districts": [
            {
              "id": 742,
              "name": "پاکدشت"
            },
            {
              "id": 1593,
              "name": "شریف آباد"
            },
            {
              "id": 1859,
              "name": "فرون آباد"
            }
          ]
        },
        {
          "id": 113,
          "name": "پردیس",
          "districts": [
            {
              "id": 1530,
              "name": "بومهن"
            },
            {
              "id": 902,
              "name": "خسرو آباد"
            },
            {
              "id": 871,
              "name": "سعیدآباد"
            },
            {
              "id": 1641,
              "name": "شهرجدیدپردیس"
            }
          ]
        },
        {
          "id": 121,
          "name": "پیشوا",
          "districts": [
            {
              "id": 1551,
              "name": "پیشوا"
            },
            {
              "id": 12956,
              "name": "جلیل آباد"
            }
          ]
        },
        {
          "id": 106,
          "name": "تهران",
          "districts": [
            {
              "id": 1051,
              "name": "تهران"
            }
          ]
        },
        {
          "id": 115,
          "name": "دماوند",
          "districts": [
            {
              "id": 1648,
              "name": "آبسرد"
            },
            {
              "id": 889,
              "name": "آبعلی"
            },
            {
              "id": 1808,
              "name": "دماوند"
            },
            {
              "id": 1765,
              "name": "رودهن"
            },
            {
              "id": 18663,
              "name": "رودهن"
            },
            {
              "id": 1675,
              "name": "کیلان"
            }
          ]
        },
        {
          "id": 123,
          "name": "رباط کریم",
          "districts": [
            {
              "id": 1623,
              "name": "رباطکریم"
            },
            {
              "id": 746,
              "name": "شهرجدیدپرند"
            },
            {
              "id": 1318,
              "name": "نصیرشهر"
            }
          ]
        },
        {
          "id": 124,
          "name": "ری",
          "districts": [
            {
              "id": 1665,
              "name": "باقر شهر"
            },
            {
              "id": 760,
              "name": "حسن آباد"
            },
            {
              "id": 1411,
              "name": "ری"
            },
            {
              "id": 1454,
              "name": "قلعه نو"
            },
            {
              "id": 790,
              "name": "قیامدشت"
            },
            {
              "id": 1621,
              "name": "کهریزک"
            },
            {
              "id": 1283,
              "name": "کوره فرنگی"
            }
          ]
        },
        {
          "id": 1194,
          "name": "شمیرانات",
          "districts": [
            {
              "id": 18644,
              "name": "تجریش"
            }
          ]
        },
        {
          "id": 128,
          "name": "شمیرانات",
          "districts": [
            {
              "id": 1776,
              "name": "تجریش"
            },
            {
              "id": 637,
              "name": "شمشک"
            },
            {
              "id": 1183,
              "name": "فشم"
            },
            {
              "id": 832,
              "name": "لواسان"
            }
          ]
        },
        {
          "id": 108,
          "name": "شهریار",
          "districts": [
            {
              "id": 1611,
              "name": "باغستان"
            },
            {
              "id": 1473,
              "name": "شاهد شهر"
            },
            {
              "id": 642,
              "name": "شهر جدید اندیشه"
            },
            {
              "id": 1801,
              "name": "شهریار"
            },
            {
              "id": 490,
              "name": "صبا شهر"
            },
            {
              "id": 863,
              "name": "فردوسیه"
            },
            {
              "id": 1542,
              "name": "وحیدیه"
            }
          ]
        },
        {
          "id": 116,
          "name": "فیروزکوه",
          "districts": [
            {
              "id": 1532,
              "name": "ارجمند"
            },
            {
              "id": 535,
              "name": "فیروزکوه"
            }
          ]
        },
        {
          "id": 110,
          "name": "قدس",
          "districts": [
            {
              "id": 933,
              "name": "قدس"
            }
          ]
        },
        {
          "id": 125,
          "name": "قرچک",
          "districts": [
            {
              "id": 877,
              "name": "قرچک"
            }
          ]
        },
        {
          "id": 111,
          "name": "ملارد",
          "districts": [
            {
              "id": 1393,
              "name": "صفادشت"
            },
            {
              "id": 18844,
              "name": "صفادشت"
            },
            {
              "id": 1644,
              "name": "ملارد"
            }
          ]
        },
        {
          "id": 126,
          "name": "ورامین",
          "districts": [
            {
              "id": 904,
              "name": "جواد آباد"
            },
            {
              "id": 511,
              "name": "ورامین"
            }
          ]
        }
      ]
    },
    {
      "id": 16,
      "name": "چهارمحال وبختیاری",
      "cities": [
        {
          "id": 129,
          "name": "اردل",
          "districts": [
            {
              "id": 506,
              "name": "اردل"
            },
            {
              "id": 743,
              "name": "دشتک"
            },
            {
              "id": 1120,
              "name": "سرخون"
            },
            {
              "id": 1280,
              "name": "کاج"
            }
          ]
        },
        {
          "id": 130,
          "name": "بروجن",
          "districts": [
            {
              "id": 1469,
              "name": "بروجن"
            },
            {
              "id": 1546,
              "name": "بلداجی"
            },
            {
              "id": 1307,
              "name": "سفید دشت"
            },
            {
              "id": 956,
              "name": "فرادنبه"
            },
            {
              "id": 1526,
              "name": "گندمان"
            },
            {
              "id": 1269,
              "name": "نقنه"
            }
          ]
        },
        {
          "id": 131,
          "name": "بن",
          "districts": [
            {
              "id": 1287,
              "name": "بن"
            },
            {
              "id": 1836,
              "name": "وردنجان"
            },
            {
              "id": 1562,
              "name": "یان چشمه"
            }
          ]
        },
        {
          "id": 132,
          "name": "خانمیرزا",
          "districts": [
            {
              "id": 685,
              "name": "آلونی"
            }
          ]
        },
        {
          "id": 133,
          "name": "سامان",
          "districts": [
            {
              "id": 1738,
              "name": "سامان"
            },
            {
              "id": 1055,
              "name": "هوره"
            }
          ]
        },
        {
          "id": 134,
          "name": "شهرکرد",
          "districts": [
            {
              "id": 834,
              "name": "سودجان"
            },
            {
              "id": 744,
              "name": "سورشجان"
            },
            {
              "id": 1760,
              "name": "شهر کرد"
            },
            {
              "id": 1592,
              "name": "طاقانک"
            },
            {
              "id": 1047,
              "name": "فرخ شهر"
            },
            {
              "id": 1005,
              "name": "کیان"
            },
            {
              "id": 696,
              "name": "نافچ"
            },
            {
              "id": 1614,
              "name": "هارونی"
            },
            {
              "id": 1414,
              "name": "هفشجان"
            }
          ]
        },
        {
          "id": 135,
          "name": "فارسان",
          "districts": [
            {
              "id": 1838,
              "name": "باباحیدر"
            },
            {
              "id": 1221,
              "name": "پردنجان"
            },
            {
              "id": 826,
              "name": "جونقان"
            },
            {
              "id": 1497,
              "name": "چلیچه"
            },
            {
              "id": 1108,
              "name": "فارسان"
            },
            {
              "id": 12842,
              "name": "فیل آباد"
            },
            {
              "id": 1397,
              "name": "گوجان"
            }
          ]
        },
        {
          "id": 1267,
          "name": "فرخشهر",
          "districts": []
        },
        {
          "id": 1187,
          "name": "فلارد",
          "districts": [
            {
              "id": 1482,
              "name": "مال خلیفه"
            }
          ]
        },
        {
          "id": 136,
          "name": "کوهرنگ",
          "districts": [
            {
              "id": 698,
              "name": "بازفت"
            },
            {
              "id": 1377,
              "name": "چلگرد"
            },
            {
              "id": 1632,
              "name": "صمصامی"
            }
          ]
        },
        {
          "id": 137,
          "name": "کیار",
          "districts": [
            {
              "id": 809,
              "name": "دستناء"
            },
            {
              "id": 957,
              "name": "شلمزار"
            },
            {
              "id": 656,
              "name": "گهرو"
            },
            {
              "id": 1246,
              "name": "ناغان"
            }
          ]
        },
        {
          "id": 138,
          "name": "لردگان",
          "districts": [
            {
              "id": 1558,
              "name": "سردشت لردگان"
            },
            {
              "id": 1017,
              "name": "لردگان"
            },
            {
              "id": 1749,
              "name": "منج"
            }
          ]
        }
      ]
    },
    {
      "id": 17,
      "name": "خراسان جنوبی",
      "cities": [
        {
          "id": 139,
          "name": "بشرویه",
          "districts": [
            {
              "id": 557,
              "name": "ارسک"
            },
            {
              "id": 1392,
              "name": "بشرویه"
            }
          ]
        },
        {
          "id": 140,
          "name": "بیرجند",
          "districts": [
            {
              "id": 1645,
              "name": "بیرجند"
            }
          ]
        },
        {
          "id": 141,
          "name": "خوسف",
          "districts": [
            {
              "id": 1062,
              "name": "خوسف"
            },
            {
              "id": 613,
              "name": "ماژان"
            },
            {
              "id": 1110,
              "name": "محمدشهر"
            }
          ]
        },
        {
          "id": 142,
          "name": "درمیان",
          "districts": [
            {
              "id": 1023,
              "name": "اسدیه"
            },
            {
              "id": 1082,
              "name": "طبس مسینا"
            },
            {
              "id": 953,
              "name": "قهستان"
            },
            {
              "id": 898,
              "name": "گزیک"
            }
          ]
        },
        {
          "id": 143,
          "name": "زیرکوه",
          "districts": [
            {
              "id": 1225,
              "name": "آبیز"
            },
            {
              "id": 1646,
              "name": "حاجی آباد"
            },
            {
              "id": 633,
              "name": "زهان"
            }
          ]
        },
        {
          "id": 144,
          "name": "سرایان",
          "districts": [
            {
              "id": 1210,
              "name": "آیسک"
            },
            {
              "id": 1350,
              "name": "سرایان"
            },
            {
              "id": 731,
              "name": "سه قلعه"
            }
          ]
        },
        {
          "id": 145,
          "name": "سربیشه",
          "districts": [
            {
              "id": 1806,
              "name": "درح"
            },
            {
              "id": 1866,
              "name": "سربیشه"
            },
            {
              "id": 1792,
              "name": "مود"
            }
          ]
        },
        {
          "id": 146,
          "name": "طبس",
          "districts": [
            {
              "id": 1735,
              "name": "دیهوک"
            },
            {
              "id": 911,
              "name": "طبس"
            },
            {
              "id": 819,
              "name": "عشق آباد"
            }
          ]
        },
        {
          "id": 147,
          "name": "فردوس",
          "districts": [
            {
              "id": 536,
              "name": "اسلامیه"
            },
            {
              "id": 700,
              "name": "باغستان"
            },
            {
              "id": 837,
              "name": "فردوس"
            }
          ]
        },
        {
          "id": 148,
          "name": "قائنات",
          "districts": [
            {
              "id": 665,
              "name": "آرین شهر"
            },
            {
              "id": 537,
              "name": "اسفدن"
            },
            {
              "id": 720,
              "name": "خضری دشت بیاض"
            },
            {
              "id": 1284,
              "name": "قاین"
            },
            {
              "id": 493,
              "name": "نیمبلوک"
            }
          ]
        },
        {
          "id": 149,
          "name": "نهبندان",
          "districts": [
            {
              "id": 1304,
              "name": "شوسف"
            },
            {
              "id": 1228,
              "name": "نهبندان"
            }
          ]
        }
      ]
    },
    {
      "id": 18,
      "name": "خراسان رضوی",
      "cities": [
        {
          "id": 150,
          "name": "باخرز",
          "districts": [
            {
              "id": 1490,
              "name": "باخرز"
            },
            {
              "id": 1557,
              "name": "قلعه نوعلیا"
            }
          ]
        },
        {
          "id": 151,
          "name": "بجستان",
          "districts": [
            {
              "id": 1215,
              "name": "بجستان"
            },
            {
              "id": 1673,
              "name": "یونسی"
            }
          ]
        },
        {
          "id": 152,
          "name": "بردسکن",
          "districts": [
            {
              "id": 922,
              "name": "انابد"
            },
            {
              "id": 1333,
              "name": "بردسکن"
            },
            {
              "id": 1289,
              "name": "شهرآباد"
            }
          ]
        },
        {
          "id": 154,
          "name": "تایباد",
          "districts": [
            {
              "id": 751,
              "name": "تایباد"
            },
            {
              "id": 937,
              "name": "کاریز"
            },
            {
              "id": 1448,
              "name": "مشهدریزه"
            }
          ]
        },
        {
          "id": 155,
          "name": "تربت جام",
          "districts": [
            {
              "id": 989,
              "name": "احمدآبادصولت"
            },
            {
              "id": 1160,
              "name": "تربت جام"
            },
            {
              "id": 1662,
              "name": "سمیع آباد"
            },
            {
              "id": 1686,
              "name": "نصرآباد"
            },
            {
              "id": 1480,
              "name": "نیل شهر"
            }
          ]
        },
        {
          "id": 156,
          "name": "تربت حیدریه",
          "districts": [
            {
              "id": 1156,
              "name": "بایک"
            },
            {
              "id": 1856,
              "name": "تربت حیدریه"
            },
            {
              "id": 1332,
              "name": "رباط سنگ"
            },
            {
              "id": 1094,
              "name": "کدکن"
            },
            {
              "id": 12948,
              "name": "نسر"
            }
          ]
        },
        {
          "id": 157,
          "name": "جغتای",
          "districts": [
            {
              "id": 1596,
              "name": "جغتای"
            },
            {
              "id": 959,
              "name": "ریواده"
            }
          ]
        },
        {
          "id": 158,
          "name": "جوین",
          "districts": [
            {
              "id": 1012,
              "name": "حکم آباد"
            },
            {
              "id": 977,
              "name": "نقاب"
            }
          ]
        },
        {
          "id": 159,
          "name": "چناران",
          "districts": [
            {
              "id": 507,
              "name": "چناران"
            },
            {
              "id": 1345,
              "name": "رادکان"
            },
            {
              "id": 587,
              "name": "سیدآباد"
            }
          ]
        },
        {
          "id": 160,
          "name": "خلیل آباد",
          "districts": [
            {
              "id": 1706,
              "name": "خلیل آباد"
            },
            {
              "id": 1513,
              "name": "کندر"
            }
          ]
        },
        {
          "id": 161,
          "name": "خواف",
          "districts": [
            {
              "id": 1778,
              "name": "خواف"
            },
            {
              "id": 917,
              "name": "سده"
            },
            {
              "id": 487,
              "name": "سلامی"
            },
            {
              "id": 1395,
              "name": "سنگان"
            },
            {
              "id": 1699,
              "name": "قاسم آباد"
            },
            {
              "id": 1420,
              "name": "نشتیفان"
            }
          ]
        },
        {
          "id": 162,
          "name": "خوشاب",
          "districts": [
            {
              "id": 1517,
              "name": "سلطان آباد"
            },
            {
              "id": 697,
              "name": "مشکان"
            },
            {
              "id": 1825,
              "name": "نوده انقلاب"
            }
          ]
        },
        {
          "id": 163,
          "name": "داورزن",
          "districts": [
            {
              "id": 1199,
              "name": "داورزن"
            },
            {
              "id": 646,
              "name": "ریوند"
            }
          ]
        },
        {
          "id": 164,
          "name": "درگز",
          "districts": [
            {
              "id": 1369,
              "name": "چاپشلو"
            },
            {
              "id": 1270,
              "name": "درگز"
            },
            {
              "id": 1314,
              "name": "لطف آباد"
            },
            {
              "id": 971,
              "name": "نوخندان"
            }
          ]
        },
        {
          "id": 165,
          "name": "رشتخوار",
          "districts": [
            {
              "id": 841,
              "name": "جنگل"
            },
            {
              "id": 1758,
              "name": "رشتخوار"
            }
          ]
        },
        {
          "id": 166,
          "name": "زاوه",
          "districts": [
            {
              "id": 1258,
              "name": "چخماق"
            },
            {
              "id": 1077,
              "name": "دولت آباد"
            }
          ]
        },
        {
          "id": 167,
          "name": "زبرخان",
          "districts": [
            {
              "id": 1186,
              "name": "اسحق آباد"
            },
            {
              "id": 712,
              "name": "خرو"
            },
            {
              "id": 1796,
              "name": "درود"
            },
            {
              "id": 962,
              "name": "قدمگاه"
            }
          ]
        },
        {
          "id": 168,
          "name": "سبزوار",
          "districts": [
            {
              "id": 1248,
              "name": "روداب"
            },
            {
              "id": 1251,
              "name": "سبزوار"
            }
          ]
        },
        {
          "id": 169,
          "name": "سرخس",
          "districts": [
            {
              "id": 667,
              "name": "سرخس"
            },
            {
              "id": 1194,
              "name": "مزدآوند"
            }
          ]
        },
        {
          "id": 170,
          "name": "ششتمد",
          "districts": [
            {
              "id": 964,
              "name": "ششتمد"
            }
          ]
        },
        {
          "id": 171,
          "name": "صالح آباد",
          "districts": [
            {
              "id": 1863,
              "name": "جنت آباد"
            },
            {
              "id": 708,
              "name": "صالح آباد"
            }
          ]
        },
        {
          "id": 153,
          "name": "طرقبه شاندیز",
          "districts": [
            {
              "id": 875,
              "name": "شاندیز"
            },
            {
              "id": 1224,
              "name": "طرقبه"
            }
          ]
        },
        {
          "id": 172,
          "name": "فریمان",
          "districts": [
            {
              "id": 852,
              "name": "سفید سنگ"
            },
            {
              "id": 1170,
              "name": "فرهادگرد"
            },
            {
              "id": 548,
              "name": "فریمان"
            },
            {
              "id": 494,
              "name": "قلندرآباد"
            }
          ]
        },
        {
          "id": 173,
          "name": "فیروزه",
          "districts": [
            {
              "id": 763,
              "name": "فیروزه"
            },
            {
              "id": 770,
              "name": "گرماب"
            },
            {
              "id": 1845,
              "name": "همت آباد"
            }
          ]
        },
        {
          "id": 174,
          "name": "قوچان",
          "districts": [
            {
              "id": 1236,
              "name": "آلماجق"
            },
            {
              "id": 1688,
              "name": "باجگیران"
            },
            {
              "id": 1091,
              "name": "شهرکهنه"
            },
            {
              "id": 636,
              "name": "قوچان"
            },
            {
              "id": 849,
              "name": "مزرج"
            }
          ]
        },
        {
          "id": 175,
          "name": "کاشمر",
          "districts": [
            {
              "id": 18666,
              "name": "فرگ قلعه"
            },
            {
              "id": 795,
              "name": "کاشمر"
            }
          ]
        },
        {
          "id": 176,
          "name": "کلات",
          "districts": [
            {
              "id": 1406,
              "name": "چنار"
            },
            {
              "id": 1261,
              "name": "شهرزو"
            },
            {
              "id": 981,
              "name": "کلات"
            }
          ]
        },
        {
          "id": 177,
          "name": "کوهسرخ",
          "districts": [
            {
              "id": 975,
              "name": "ریوش"
            }
          ]
        },
        {
          "id": 178,
          "name": "گلبهار",
          "districts": [
            {
              "id": 1751,
              "name": "گلبهار"
            },
            {
              "id": 1372,
              "name": "گلمکان"
            }
          ]
        },
        {
          "id": 179,
          "name": "گناباد",
          "districts": [
            {
              "id": 1358,
              "name": "بیدخت"
            },
            {
              "id": 1547,
              "name": "روشناوند"
            },
            {
              "id": 1019,
              "name": "کاخک"
            },
            {
              "id": 925,
              "name": "گناباد"
            }
          ]
        },
        {
          "id": 180,
          "name": "مشهد",
          "districts": [
            {
              "id": 755,
              "name": "رضویه"
            },
            {
              "id": 1723,
              "name": "مشهد"
            },
            {
              "id": 1726,
              "name": "ملک آباد"
            }
          ]
        },
        {
          "id": 181,
          "name": "مه ولات",
          "districts": [
            {
              "id": 1477,
              "name": "شادمهر"
            },
            {
              "id": 1013,
              "name": "فیض آباد"
            }
          ]
        },
        {
          "id": 1191,
          "name": "میان جلگه",
          "districts": [
            {
              "id": 470,
              "name": "عشق آباد"
            }
          ]
        },
        {
          "id": 182,
          "name": "نیشابور",
          "districts": [
            {
              "id": 870,
              "name": "بار"
            },
            {
              "id": 805,
              "name": "چکنه"
            },
            {
              "id": 1871,
              "name": "درود"
            },
            {
              "id": 1006,
              "name": "نیشابور"
            }
          ]
        }
      ]
    },
    {
      "id": 19,
      "name": "خراسان شمالی",
      "cities": [
        {
          "id": 183,
          "name": "اسفراین",
          "districts": [
            {
              "id": 1663,
              "name": "اسفراین"
            },
            {
              "id": 921,
              "name": "صفی آباد"
            }
          ]
        },
        {
          "id": 1193,
          "name": "بام و صفی آباد",
          "districts": [
            {
              "id": 18638,
              "name": "صفی آباد"
            }
          ]
        },
        {
          "id": 184,
          "name": "بجنورد",
          "districts": [
            {
              "id": 1762,
              "name": "بجنورد"
            },
            {
              "id": 879,
              "name": "چناران شهر"
            },
            {
              "id": 969,
              "name": "حصار گرمخان"
            }
          ]
        },
        {
          "id": 185,
          "name": "جاجرم",
          "districts": [
            {
              "id": 1338,
              "name": "جاجرم"
            },
            {
              "id": 1613,
              "name": "سنخواست"
            },
            {
              "id": 1745,
              "name": "شوقان"
            }
          ]
        },
        {
          "id": 186,
          "name": "راز و جرگلان",
          "districts": [
            {
              "id": 1298,
              "name": "راز"
            },
            {
              "id": 18629,
              "name": "غلامان"
            },
            {
              "id": 1034,
              "name": "یکه سعود"
            },
            {
              "id": 18628,
              "name": "یکه سعود جدید"
            }
          ]
        },
        {
          "id": 190,
          "name": "سملقان",
          "districts": [
            {
              "id": 918,
              "name": "آشخانه"
            },
            {
              "id": 1403,
              "name": "آوا"
            },
            {
              "id": 1520,
              "name": "قاضی"
            }
          ]
        },
        {
          "id": 187,
          "name": "شیروان",
          "districts": [
            {
              "id": 1325,
              "name": "خانلق"
            },
            {
              "id": 1382,
              "name": "زیارت"
            },
            {
              "id": 715,
              "name": "شیروان"
            },
            {
              "id": 752,
              "name": "قوشخانه"
            },
            {
              "id": 1130,
              "name": "لوجلی"
            }
          ]
        },
        {
          "id": 188,
          "name": "فاروج",
          "districts": [
            {
              "id": 1222,
              "name": "تیتکانلو"
            },
            {
              "id": 1681,
              "name": "فاروج"
            }
          ]
        },
        {
          "id": 189,
          "name": "گرمه",
          "districts": [
            {
              "id": 1642,
              "name": "ایور"
            },
            {
              "id": 803,
              "name": "درق"
            },
            {
              "id": 1105,
              "name": "گرمه"
            }
          ]
        },
        {
          "id": 1264,
          "name": "مانه",
          "districts": [
            {
              "id": 780,
              "name": "پیش قلعه"
            },
            {
              "id": 18832,
              "name": "پیش قلعه(جدید)"
            }
          ]
        }
      ]
    },
    {
      "id": 20,
      "name": "خوزستان",
      "cities": [
        {
          "id": 191,
          "name": "آبادان",
          "districts": [
            {
              "id": 1741,
              "name": "آبادان"
            },
            {
              "id": 1014,
              "name": "اروندکنار"
            },
            {
              "id": 566,
              "name": "چوئبده"
            }
          ]
        },
        {
          "id": 192,
          "name": "آغاجاری",
          "districts": [
            {
              "id": 880,
              "name": "آغاجاری"
            },
            {
              "id": 18643,
              "name": "جولکی"
            }
          ]
        },
        {
          "id": 193,
          "name": "امیدیه",
          "districts": [
            {
              "id": 521,
              "name": "امیدیه"
            },
            {
              "id": 610,
              "name": "جایزان"
            },
            {
              "id": 18776,
              "name": "جایزان"
            },
            {
              "id": 853,
              "name": "میانکوه"
            }
          ]
        },
        {
          "id": 194,
          "name": "اندیکا",
          "districts": [
            {
              "id": 884,
              "name": "آبژدان"
            },
            {
              "id": 591,
              "name": "زاووت"
            },
            {
              "id": 1201,
              "name": "قلعه خواجه"
            }
          ]
        },
        {
          "id": 195,
          "name": "اندیمشک",
          "districts": [
            {
              "id": 1491,
              "name": "آزادی"
            },
            {
              "id": 1479,
              "name": "اندیمشک"
            },
            {
              "id": 1660,
              "name": "بیدروبه"
            },
            {
              "id": 1605,
              "name": "چم گلک"
            },
            {
              "id": 1299,
              "name": "حسینیه"
            }
          ]
        },
        {
          "id": 196,
          "name": "اهواز",
          "districts": [
            {
              "id": 1126,
              "name": "الهائی"
            },
            {
              "id": 1111,
              "name": "اهواز"
            }
          ]
        },
        {
          "id": 197,
          "name": "ایذه",
          "districts": [
            {
              "id": 883,
              "name": "ایذه"
            }
          ]
        },
        {
          "id": 198,
          "name": "باغ ملک",
          "districts": [
            {
              "id": 1651,
              "name": "باغ ملک"
            },
            {
              "id": 1566,
              "name": "قلعه تل"
            },
            {
              "id": 1272,
              "name": "میداود"
            }
          ]
        },
        {
          "id": 199,
          "name": "باوی",
          "districts": [
            {
              "id": 806,
              "name": "شیبان"
            },
            {
              "id": 1553,
              "name": "ملاثانی"
            },
            {
              "id": 1335,
              "name": "ویس"
            }
          ]
        },
        {
          "id": 200,
          "name": "بندرماهشهر",
          "districts": [
            {
              "id": 684,
              "name": "بندرامام خمینی"
            },
            {
              "id": 997,
              "name": "بندرماهشهر"
            },
            {
              "id": 1616,
              "name": "چمران"
            }
          ]
        },
        {
          "id": 201,
          "name": "بهبهان",
          "districts": [
            {
              "id": 707,
              "name": "بهبهان"
            },
            {
              "id": 1857,
              "name": "تشان"
            },
            {
              "id": 1452,
              "name": "سردشت"
            },
            {
              "id": 914,
              "name": "منصوریه"
            }
          ]
        },
        {
          "id": 202,
          "name": "حمیدیه",
          "districts": [
            {
              "id": 1066,
              "name": "حمیدیه"
            }
          ]
        },
        {
          "id": 203,
          "name": "خرمشهر",
          "districts": [
            {
              "id": 1070,
              "name": "خرمشهر"
            },
            {
              "id": 1202,
              "name": "مینوشهر"
            }
          ]
        },
        {
          "id": 1189,
          "name": "دزپارت",
          "districts": [
            {
              "id": 564,
              "name": "دهدز"
            }
          ]
        },
        {
          "id": 204,
          "name": "دزفول",
          "districts": [
            {
              "id": 1367,
              "name": "جندی شاپور"
            },
            {
              "id": 1046,
              "name": "چغامیش"
            },
            {
              "id": 1324,
              "name": "حمزه"
            },
            {
              "id": 824,
              "name": "دزفول"
            },
            {
              "id": 1193,
              "name": "سالند"
            },
            {
              "id": 1387,
              "name": "سیاه منصور"
            },
            {
              "id": 1462,
              "name": "شمس آباد"
            },
            {
              "id": 589,
              "name": "شهرامام"
            },
            {
              "id": 1188,
              "name": "صفی آباد"
            },
            {
              "id": 1708,
              "name": "منتظران"
            },
            {
              "id": 1229,
              "name": "میانرود"
            }
          ]
        },
        {
          "id": 205,
          "name": "دشت آزادگان",
          "districts": [
            {
              "id": 1319,
              "name": "ابوحمیظه"
            },
            {
              "id": 991,
              "name": "بستان"
            },
            {
              "id": 972,
              "name": "سوسنگرد"
            },
            {
              "id": 1136,
              "name": "کوت سیدنعیم"
            }
          ]
        },
        {
          "id": 206,
          "name": "رامشیر",
          "districts": [
            {
              "id": 1362,
              "name": "رامشیر"
            },
            {
              "id": 1072,
              "name": "مشراگه"
            }
          ]
        },
        {
          "id": 207,
          "name": "رامهرمز",
          "districts": [
            {
              "id": 657,
              "name": "باوج"
            },
            {
              "id": 726,
              "name": "رامهرمز"
            },
            {
              "id": 1540,
              "name": "رود زرد ماشین"
            },
            {
              "id": 579,
              "name": "سلطان آباد"
            }
          ]
        },
        {
          "id": 208,
          "name": "شادگان",
          "districts": [
            {
              "id": 1576,
              "name": "خنافره"
            },
            {
              "id": 651,
              "name": "دارخوین"
            },
            {
              "id": 1355,
              "name": "شادگان"
            }
          ]
        },
        {
          "id": 209,
          "name": "شوش",
          "districts": [
            {
              "id": 829,
              "name": "حر"
            },
            {
              "id": 1819,
              "name": "شوش"
            },
            {
              "id": 926,
              "name": "فتح المبین"
            }
          ]
        },
        {
          "id": 210,
          "name": "شوشتر",
          "districts": [
            {
              "id": 1538,
              "name": "سرداران"
            },
            {
              "id": 1703,
              "name": "شرافت"
            },
            {
              "id": 595,
              "name": "شوشتر"
            },
            {
              "id": 938,
              "name": "گوریه"
            }
          ]
        },
        {
          "id": 1231,
          "name": "صیدون",
          "districts": [
            {
              "id": 1064,
              "name": "صیدون"
            }
          ]
        },
        {
          "id": 211,
          "name": "کارون",
          "districts": [
            {
              "id": 590,
              "name": "شیرین شهر"
            },
            {
              "id": 1342,
              "name": "کوت عبدالله"
            },
            {
              "id": 18733,
              "name": "کوت عبدالله"
            }
          ]
        },
        {
          "id": 212,
          "name": "کرخه",
          "districts": [
            {
              "id": 1274,
              "name": "الوان"
            },
            {
              "id": 761,
              "name": "شاوور"
            }
          ]
        },
        {
          "id": 213,
          "name": "گتوند",
          "districts": [
            {
              "id": 1405,
              "name": "ترکالکی"
            },
            {
              "id": 1493,
              "name": "جنت مکان"
            },
            {
              "id": 1653,
              "name": "سماله"
            },
            {
              "id": 558,
              "name": "صالح شهر"
            },
            {
              "id": 1672,
              "name": "گتوند"
            }
          ]
        },
        {
          "id": 214,
          "name": "لالی",
          "districts": [
            {
              "id": 560,
              "name": "لالی"
            }
          ]
        },
        {
          "id": 215,
          "name": "مسجدسلیمان",
          "districts": [
            {
              "id": 978,
              "name": "عنبر"
            },
            {
              "id": 1668,
              "name": "گلگیر"
            },
            {
              "id": 1506,
              "name": "مسجدسلیمان"
            }
          ]
        },
        {
          "id": 216,
          "name": "هفتگل",
          "districts": [
            {
              "id": 1181,
              "name": "هفتگل"
            }
          ]
        },
        {
          "id": 217,
          "name": "هندیجان",
          "districts": [
            {
              "id": 1391,
              "name": "زهره"
            },
            {
              "id": 614,
              "name": "هندیجان"
            }
          ]
        },
        {
          "id": 218,
          "name": "هویزه",
          "districts": [
            {
              "id": 1321,
              "name": "رفیع"
            },
            {
              "id": 1144,
              "name": "هویزه"
            }
          ]
        }
      ]
    },
    {
      "id": 21,
      "name": "زنجان",
      "cities": [
        {
          "id": 219,
          "name": "ابهر",
          "districts": [
            {
              "id": 836,
              "name": "ابهر"
            },
            {
              "id": 1129,
              "name": "صائین قلعه"
            },
            {
              "id": 648,
              "name": "هیدج"
            }
          ]
        },
        {
          "id": 220,
          "name": "ایجرود",
          "districts": [
            {
              "id": 1659,
              "name": "حلب"
            },
            {
              "id": 704,
              "name": "زرین آباد"
            }
          ]
        },
        {
          "id": 221,
          "name": "خدابنده",
          "districts": [
            {
              "id": 1260,
              "name": "زرین رود"
            },
            {
              "id": 1247,
              "name": "سجاس"
            },
            {
              "id": 583,
              "name": "سهرورد"
            },
            {
              "id": 695,
              "name": "قیدار"
            },
            {
              "id": 1390,
              "name": "کرسف"
            },
            {
              "id": 1329,
              "name": "گرماب"
            },
            {
              "id": 996,
              "name": "نوربهار"
            }
          ]
        },
        {
          "id": 222,
          "name": "خرمدره",
          "districts": [
            {
              "id": 1167,
              "name": "خرمدره"
            }
          ]
        },
        {
          "id": 223,
          "name": "زنجان",
          "districts": [
            {
              "id": 647,
              "name": "ارمغانخانه"
            },
            {
              "id": 1360,
              "name": "زنجان"
            },
            {
              "id": 848,
              "name": "نیکپی"
            }
          ]
        },
        {
          "id": 224,
          "name": "سلطانیه",
          "districts": [
            {
              "id": 907,
              "name": "سلطانیه"
            }
          ]
        },
        {
          "id": 225,
          "name": "طارم",
          "districts": [
            {
              "id": 495,
              "name": "آب بر"
            },
            {
              "id": 543,
              "name": "چورزق"
            }
          ]
        },
        {
          "id": 226,
          "name": "ماهنشان",
          "districts": [
            {
              "id": 1786,
              "name": "دندی"
            },
            {
              "id": 668,
              "name": "ماهنشان"
            }
          ]
        }
      ]
    },
    {
      "id": 22,
      "name": "سمنان",
      "cities": [
        {
          "id": 227,
          "name": "آرادان",
          "districts": [
            {
              "id": 839,
              "name": "آرادان"
            },
            {
              "id": 984,
              "name": "کهن آباد"
            }
          ]
        },
        {
          "id": 228,
          "name": "دامغان",
          "districts": [
            {
              "id": 1719,
              "name": "امیریه"
            },
            {
              "id": 1475,
              "name": "دامغان"
            },
            {
              "id": 891,
              "name": "دیباج"
            },
            {
              "id": 544,
              "name": "کلاته"
            }
          ]
        },
        {
          "id": 229,
          "name": "سرخه",
          "districts": [
            {
              "id": 1755,
              "name": "سرخه"
            }
          ]
        },
        {
          "id": 230,
          "name": "سمنان",
          "districts": [
            {
              "id": 1039,
              "name": "سمنان"
            }
          ]
        },
        {
          "id": 231,
          "name": "شاهرود",
          "districts": [
            {
              "id": 555,
              "name": "بسطام"
            },
            {
              "id": 812,
              "name": "بیارجمند"
            },
            {
              "id": 1027,
              "name": "رویان"
            },
            {
              "id": 815,
              "name": "شاهرود"
            },
            {
              "id": 901,
              "name": "کلاته خیج"
            },
            {
              "id": 553,
              "name": "مجن"
            }
          ]
        },
        {
          "id": 232,
          "name": "گرمسار",
          "districts": [
            {
              "id": 908,
              "name": "ایوانکی"
            },
            {
              "id": 592,
              "name": "گرمسار"
            }
          ]
        },
        {
          "id": 233,
          "name": "مهدی شهر",
          "districts": [
            {
              "id": 1488,
              "name": "درجزین"
            },
            {
              "id": 968,
              "name": "شهمیرزاد"
            },
            {
              "id": 1748,
              "name": "مهدیشهر"
            }
          ]
        },
        {
          "id": 234,
          "name": "میامی",
          "districts": [
            {
              "id": 772,
              "name": "رضوان"
            },
            {
              "id": 882,
              "name": "میامی"
            }
          ]
        }
      ]
    },
    {
      "id": 23,
      "name": "سیستان وبلوچستان",
      "cities": [
        {
          "id": 235,
          "name": "ایرانشهر",
          "districts": [
            {
              "id": 596,
              "name": "ایرانشهر"
            },
            {
              "id": 659,
              "name": "بزمان"
            }
          ]
        },
        {
          "id": 236,
          "name": "بمپور",
          "districts": [
            {
              "id": 1404,
              "name": "بمپور"
            },
            {
              "id": 12863,
              "name": "قاسم آباد"
            },
            {
              "id": 1847,
              "name": "محمدان"
            }
          ]
        },
        {
          "id": 237,
          "name": "تفتان",
          "districts": [
            {
              "id": 662,
              "name": "نوک آباد"
            }
          ]
        },
        {
          "id": 238,
          "name": "چابهار",
          "districts": [
            {
              "id": 12864,
              "name": "پلان"
            },
            {
              "id": 1467,
              "name": "چابهار"
            }
          ]
        },
        {
          "id": 239,
          "name": "خاش",
          "districts": [
            {
              "id": 12865,
              "name": "اسماعیل آباد"
            },
            {
              "id": 1076,
              "name": "خاش"
            },
            {
              "id": 1784,
              "name": "ده رئیس"
            },
            {
              "id": 1599,
              "name": "نوک آباد"
            }
          ]
        },
        {
          "id": 240,
          "name": "دشتیاری",
          "districts": [
            {
              "id": 8755,
              "name": "بریس"
            },
            {
              "id": 8685,
              "name": "نگور"
            }
          ]
        },
        {
          "id": 241,
          "name": "دلگان",
          "districts": [
            {
              "id": 12866,
              "name": "چگرد"
            },
            {
              "id": 1267,
              "name": "گلمورتی"
            }
          ]
        },
        {
          "id": 242,
          "name": "راسک",
          "districts": [
            {
              "id": 1067,
              "name": "پارود"
            },
            {
              "id": 1033,
              "name": "پیشین"
            },
            {
              "id": 531,
              "name": "راسک"
            }
          ]
        },
        {
          "id": 243,
          "name": "زابل",
          "districts": [
            {
              "id": 748,
              "name": "بنجار"
            },
            {
              "id": 869,
              "name": "زابل"
            }
          ]
        },
        {
          "id": 244,
          "name": "زاهدان",
          "districts": [
            {
              "id": 1607,
              "name": "زاهدان"
            },
            {
              "id": 551,
              "name": "سرجنگل"
            },
            {
              "id": 1430,
              "name": "نصرت آباد"
            }
          ]
        },
        {
          "id": 245,
          "name": "زرآباد",
          "districts": [
            {
              "id": 1720,
              "name": "زرآباد"
            }
          ]
        },
        {
          "id": 246,
          "name": "زهک",
          "districts": [
            {
              "id": 1165,
              "name": "جزینک"
            },
            {
              "id": 1509,
              "name": "زهک"
            }
          ]
        },
        {
          "id": 247,
          "name": "سراوان",
          "districts": [
            {
              "id": 13135,
              "name": "اسفندک"
            },
            {
              "id": 833,
              "name": "سراوان"
            },
            {
              "id": 823,
              "name": "سیرکان"
            },
            {
              "id": 924,
              "name": "گشت"
            },
            {
              "id": 586,
              "name": "محمدی"
            }
          ]
        },
        {
          "id": 248,
          "name": "سرباز",
          "districts": [
            {
              "id": 1239,
              "name": "سرباز"
            }
          ]
        },
        {
          "id": 249,
          "name": "سیب و سوران",
          "districts": [
            {
              "id": 1384,
              "name": "سوران"
            },
            {
              "id": 1770,
              "name": "سیب"
            },
            {
              "id": 1206,
              "name": "هیدوچ"
            }
          ]
        },
        {
          "id": 250,
          "name": "فنوج",
          "districts": [
            {
              "id": 1238,
              "name": "فنوج"
            },
            {
              "id": 1065,
              "name": "گتیج"
            }
          ]
        },
        {
          "id": 251,
          "name": "قصرقند",
          "districts": [
            {
              "id": 890,
              "name": "ساربوک"
            },
            {
              "id": 1286,
              "name": "قصرقند"
            }
          ]
        },
        {
          "id": 252,
          "name": "کنارک",
          "districts": [
            {
              "id": 1413,
              "name": "کنارک"
            }
          ]
        },
        {
          "id": 253,
          "name": "گلشن",
          "districts": [
            {
              "id": 974,
              "name": "جالق"
            }
          ]
        },
        {
          "id": 1148,
          "name": "لاشار",
          "districts": [
            {
              "id": 499,
              "name": "اسپکه"
            }
          ]
        },
        {
          "id": 254,
          "name": "مهرستان",
          "districts": [
            {
              "id": 8776,
              "name": "آشار"
            },
            {
              "id": 759,
              "name": "زابلی"
            }
          ]
        },
        {
          "id": 255,
          "name": "میرجاوه",
          "districts": [
            {
              "id": 1308,
              "name": "ریگ ملک"
            },
            {
              "id": 1009,
              "name": "لادیز"
            },
            {
              "id": 1288,
              "name": "میرجاوه"
            }
          ]
        },
        {
          "id": 256,
          "name": "نیک شهر",
          "districts": [
            {
              "id": 1496,
              "name": "بنت"
            },
            {
              "id": 8779,
              "name": "چانف"
            },
            {
              "id": 942,
              "name": "نیکشهر"
            }
          ]
        },
        {
          "id": 257,
          "name": "نیمروز",
          "districts": [
            {
              "id": 1346,
              "name": "ادیمی"
            }
          ]
        },
        {
          "id": 258,
          "name": "هامون",
          "districts": [
            {
              "id": 1585,
              "name": "علی اکبر"
            },
            {
              "id": 514,
              "name": "محمدآباد"
            }
          ]
        },
        {
          "id": 259,
          "name": "هیرمند",
          "districts": [
            {
              "id": 1714,
              "name": "دوست محمد"
            },
            {
              "id": 1669,
              "name": "قرقری"
            }
          ]
        }
      ]
    },
    {
      "id": 24,
      "name": "فارس",
      "cities": [
        {
          "id": 260,
          "name": "آباده",
          "districts": [
            {
              "id": 747,
              "name": "آباده"
            },
            {
              "id": 988,
              "name": "ایزدخواست"
            },
            {
              "id": 1689,
              "name": "بهمن"
            },
            {
              "id": 862,
              "name": "سورمق"
            },
            {
              "id": 640,
              "name": "صغاد"
            }
          ]
        },
        {
          "id": 261,
          "name": "ارسنجان",
          "districts": [
            {
              "id": 1090,
              "name": "ارسنجان"
            }
          ]
        },
        {
          "id": 262,
          "name": "استهبان",
          "districts": [
            {
              "id": 951,
              "name": "استهبان"
            },
            {
              "id": 1827,
              "name": "ایج"
            },
            {
              "id": 913,
              "name": "رونیز"
            }
          ]
        },
        {
          "id": 263,
          "name": "اقلید",
          "districts": [
            {
              "id": 1180,
              "name": "اقلید"
            },
            {
              "id": 1565,
              "name": "حسن آباد"
            },
            {
              "id": 1123,
              "name": "دژکرد"
            },
            {
              "id": 1839,
              "name": "سده"
            }
          ]
        },
        {
          "id": 264,
          "name": "اوز",
          "districts": [
            {
              "id": 1459,
              "name": "اوز"
            },
            {
              "id": 1107,
              "name": "کوره"
            }
          ]
        },
        {
          "id": 265,
          "name": "بختگان",
          "districts": [
            {
              "id": 593,
              "name": "آباده طشک"
            }
          ]
        },
        {
          "id": 266,
          "name": "بوانات",
          "districts": [
            {
              "id": 1277,
              "name": "بوانات"
            },
            {
              "id": 813,
              "name": "مزایجان"
            }
          ]
        },
        {
          "id": 267,
          "name": "بیضا",
          "districts": [
            {
              "id": 1416,
              "name": "بیضا"
            }
          ]
        },
        {
          "id": 268,
          "name": "پاسارگاد",
          "districts": [
            {
              "id": 986,
              "name": "سعادت شهر"
            },
            {
              "id": 1766,
              "name": "مادرسلیمان"
            }
          ]
        },
        {
          "id": 269,
          "name": "جهرم",
          "districts": [
            {
              "id": 676,
              "name": "جهرم"
            },
            {
              "id": 678,
              "name": "دوزه"
            },
            {
              "id": 1536,
              "name": "قطب آباد"
            }
          ]
        },
        {
          "id": 270,
          "name": "جویم",
          "districts": [
            {
              "id": 1635,
              "name": "جویم"
            }
          ]
        },
        {
          "id": 271,
          "name": "خرامه",
          "districts": [
            {
              "id": 787,
              "name": "خرامه"
            },
            {
              "id": 928,
              "name": "خیراباد"
            },
            {
              "id": 1440,
              "name": "سلطان شهر"
            },
            {
              "id": 713,
              "name": "معزابادجابری"
            }
          ]
        },
        {
          "id": 272,
          "name": "خرم بید",
          "districts": [
            {
              "id": 1868,
              "name": "صفاشهر"
            },
            {
              "id": 973,
              "name": "قادرآباد"
            }
          ]
        },
        {
          "id": 273,
          "name": "خفر",
          "districts": [
            {
              "id": 1265,
              "name": "باب انار"
            },
            {
              "id": 1125,
              "name": "خاوران"
            }
          ]
        },
        {
          "id": 274,
          "name": "خنج",
          "districts": [
            {
              "id": 1219,
              "name": "خنج"
            },
            {
              "id": 729,
              "name": "محمله"
            }
          ]
        },
        {
          "id": 275,
          "name": "داراب",
          "districts": [
            {
              "id": 1813,
              "name": "پاسخن"
            },
            {
              "id": 1010,
              "name": "جنت شهر"
            },
            {
              "id": 1460,
              "name": "داراب"
            },
            {
              "id": 631,
              "name": "دوبرجی"
            },
            {
              "id": 474,
              "name": "رستاق"
            },
            {
              "id": 1615,
              "name": "فدامی"
            }
          ]
        },
        {
          "id": 276,
          "name": "رستم",
          "districts": [
            {
              "id": 1759,
              "name": "کوپن"
            },
            {
              "id": 1449,
              "name": "مصیری"
            }
          ]
        },
        {
          "id": 277,
          "name": "زرقان",
          "districts": [
            {
              "id": 528,
              "name": "رحمت آباد"
            },
            {
              "id": 1637,
              "name": "زرقان"
            },
            {
              "id": 1685,
              "name": "لپویی"
            }
          ]
        },
        {
          "id": 278,
          "name": "زرین دشت",
          "districts": [
            {
              "id": 788,
              "name": "حاجی آباد"
            },
            {
              "id": 1168,
              "name": "دبیران"
            },
            {
              "id": 1757,
              "name": "شهرپیر"
            }
          ]
        },
        {
          "id": 279,
          "name": "سپیدان",
          "districts": [
            {
              "id": 1598,
              "name": "اردکان"
            },
            {
              "id": 1290,
              "name": "هماشهر"
            }
          ]
        },
        {
          "id": 280,
          "name": "سرچهان",
          "districts": [
            {
              "id": 12952,
              "name": "باغ صفا"
            },
            {
              "id": 1294,
              "name": "حسامی"
            },
            {
              "id": 1729,
              "name": "کره ای"
            }
          ]
        },
        {
          "id": 281,
          "name": "سروستان",
          "districts": [
            {
              "id": 1147,
              "name": "سروستان"
            },
            {
              "id": 1200,
              "name": "کوهنجان"
            }
          ]
        },
        {
          "id": 282,
          "name": "شیراز",
          "districts": [
            {
              "id": 1843,
              "name": "خانه زنیان"
            },
            {
              "id": 1361,
              "name": "داریان"
            },
            {
              "id": 1328,
              "name": "شهرجدیدصدرا"
            },
            {
              "id": 916,
              "name": "شیراز"
            }
          ]
        },
        {
          "id": 283,
          "name": "فراشبند",
          "districts": [
            {
              "id": 644,
              "name": "دهرم"
            },
            {
              "id": 1826,
              "name": "فراشبند"
            },
            {
              "id": 500,
              "name": "نوجین"
            }
          ]
        },
        {
          "id": 284,
          "name": "فسا",
          "districts": [
            {
              "id": 1245,
              "name": "زاهدشهر"
            },
            {
              "id": 1205,
              "name": "ششده"
            },
            {
              "id": 845,
              "name": "فسا"
            },
            {
              "id": 1810,
              "name": "قره بلاغ"
            },
            {
              "id": 1649,
              "name": "میانشهر"
            },
            {
              "id": 1375,
              "name": "نوبندگان"
            }
          ]
        },
        {
          "id": 285,
          "name": "فیروزآباد",
          "districts": [
            {
              "id": 830,
              "name": "فیروزآباد"
            },
            {
              "id": 603,
              "name": "میمند"
            }
          ]
        },
        {
          "id": 286,
          "name": "قیروکارزین",
          "districts": [
            {
              "id": 1071,
              "name": "افزر"
            },
            {
              "id": 725,
              "name": "امام شهر"
            },
            {
              "id": 1092,
              "name": "قیر"
            },
            {
              "id": 1084,
              "name": "کارزین"
            },
            {
              "id": 1146,
              "name": "مبارک آباد"
            }
          ]
        },
        {
          "id": 287,
          "name": "کازرون",
          "districts": [
            {
              "id": 1647,
              "name": "بالاده"
            },
            {
              "id": 1643,
              "name": "خشت"
            },
            {
              "id": 1809,
              "name": "کازرون"
            },
            {
              "id": 740,
              "name": "کنار تخته"
            }
          ]
        },
        {
          "id": 288,
          "name": "کوار",
          "districts": [
            {
              "id": 1150,
              "name": "اکبرآباد"
            },
            {
              "id": 1543,
              "name": "طسوج"
            },
            {
              "id": 948,
              "name": "کوار"
            },
            {
              "id": 1098,
              "name": "مظفری"
            }
          ]
        },
        {
          "id": 289,
          "name": "کوه چنار",
          "districts": [
            {
              "id": 1115,
              "name": "قائمیه"
            },
            {
              "id": 739,
              "name": "نودان"
            }
          ]
        },
        {
          "id": 290,
          "name": "گراش",
          "districts": [
            {
              "id": 858,
              "name": "ارد"
            },
            {
              "id": 1463,
              "name": "گراش"
            }
          ]
        },
        {
          "id": 291,
          "name": "لارستان",
          "districts": [
            {
              "id": 576,
              "name": "بنارویه"
            },
            {
              "id": 1650,
              "name": "بیرم"
            },
            {
              "id": 1533,
              "name": "خور"
            },
            {
              "id": 1797,
              "name": "دهکویه"
            },
            {
              "id": 1878,
              "name": "عمادشهر"
            },
            {
              "id": 963,
              "name": "لار"
            },
            {
              "id": 992,
              "name": "لطیفی"
            }
          ]
        },
        {
          "id": 292,
          "name": "لامرد",
          "districts": [
            {
              "id": 1666,
              "name": "اشکنان"
            },
            {
              "id": 1873,
              "name": "اهل"
            },
            {
              "id": 1824,
              "name": "چاه ورز"
            },
            {
              "id": 782,
              "name": "خیرگو"
            },
            {
              "id": 859,
              "name": "علامرودشت"
            },
            {
              "id": 934,
              "name": "لامرد"
            }
          ]
        },
        {
          "id": 293,
          "name": "مرودشت",
          "districts": [
            {
              "id": 1365,
              "name": "خانیمن"
            },
            {
              "id": 1353,
              "name": "رامجرد"
            },
            {
              "id": 1300,
              "name": "زنگی آباد"
            },
            {
              "id": 1164,
              "name": "سیدان"
            },
            {
              "id": 608,
              "name": "فاروق"
            },
            {
              "id": 1667,
              "name": "فتح آباد"
            },
            {
              "id": 702,
              "name": "کامفیروز"
            },
            {
              "id": 1423,
              "name": "مرودشت"
            }
          ]
        },
        {
          "id": 294,
          "name": "ممسنی",
          "districts": [
            {
              "id": 1602,
              "name": "بابامنیر"
            },
            {
              "id": 791,
              "name": "خومه زار"
            },
            {
              "id": 1864,
              "name": "نور آباد"
            }
          ]
        },
        {
          "id": 295,
          "name": "مهر",
          "districts": [
            {
              "id": 846,
              "name": "اسیر"
            },
            {
              "id": 1846,
              "name": "خوزی"
            },
            {
              "id": 1132,
              "name": "فال"
            },
            {
              "id": 1337,
              "name": "گله دار"
            },
            {
              "id": 529,
              "name": "مهر"
            },
            {
              "id": 1400,
              "name": "وراوی"
            }
          ]
        },
        {
          "id": 296,
          "name": "نی ریز",
          "districts": [
            {
              "id": 784,
              "name": "قطرویه"
            },
            {
              "id": 484,
              "name": "مشکان"
            },
            {
              "id": 1306,
              "name": "نی ریز"
            }
          ]
        }
      ]
    },
    {
      "id": 25,
      "name": "قزوین",
      "cities": [
        {
          "id": 297,
          "name": "آبیک",
          "districts": [
            {
              "id": 1351,
              "name": "آبیک"
            },
            {
              "id": 1807,
              "name": "خاکعلی"
            },
            {
              "id": 572,
              "name": "زیاران"
            },
            {
              "id": 475,
              "name": "قشلاق"
            }
          ]
        },
        {
          "id": 298,
          "name": "آوج",
          "districts": [
            {
              "id": 857,
              "name": "آبگرم"
            },
            {
              "id": 1588,
              "name": "آوج"
            }
          ]
        },
        {
          "id": 299,
          "name": "البرز",
          "districts": [
            {
              "id": 1722,
              "name": "الوند"
            },
            {
              "id": 843,
              "name": "بیدستان"
            },
            {
              "id": 1733,
              "name": "شریفیه"
            },
            {
              "id": 1438,
              "name": "محمدیه"
            },
            {
              "id": 18777,
              "name": "مهرگان"
            }
          ]
        },
        {
          "id": 300,
          "name": "بوئین زهرا",
          "districts": [
            {
              "id": 722,
              "name": "ارداق"
            },
            {
              "id": 920,
              "name": "بوئین زهرا"
            },
            {
              "id": 585,
              "name": "دانسفهان"
            },
            {
              "id": 1781,
              "name": "سگزآباد"
            },
            {
              "id": 620,
              "name": "شال"
            },
            {
              "id": 601,
              "name": "عصمت آباد"
            }
          ]
        },
        {
          "id": 301,
          "name": "تاکستان",
          "districts": [
            {
              "id": 717,
              "name": "اسفرورین"
            },
            {
              "id": 1694,
              "name": "تاکستان"
            },
            {
              "id": 1724,
              "name": "خرمدشت"
            },
            {
              "id": 797,
              "name": "ضیاءآباد"
            },
            {
              "id": 874,
              "name": "نرجه"
            }
          ]
        },
        {
          "id": 302,
          "name": "قزوین",
          "districts": [
            {
              "id": 1357,
              "name": "اقبالیه"
            },
            {
              "id": 1060,
              "name": "رازمیان"
            },
            {
              "id": 1317,
              "name": "سیردان"
            },
            {
              "id": 1510,
              "name": "قزوین"
            },
            {
              "id": 936,
              "name": "کوهین"
            },
            {
              "id": 575,
              "name": "محمودآبادنمونه"
            },
            {
              "id": 1437,
              "name": "معلم کلایه"
            }
          ]
        }
      ]
    },
    {
      "id": 26,
      "name": "قم",
      "cities": [
        {
          "id": 1195,
          "name": "جعفرآباد",
          "districts": [
            {
              "id": 628,
              "name": "جعفریه"
            },
            {
              "id": 18735,
              "name": "قاهان"
            }
          ]
        },
        {
          "id": 303,
          "name": "قم",
          "districts": [
            {
              "id": 561,
              "name": "دستجرد"
            },
            {
              "id": 1311,
              "name": "سلفچگان"
            },
            {
              "id": 18791,
              "name": "سلفچگان"
            },
            {
              "id": 1691,
              "name": "قم"
            },
            {
              "id": 556,
              "name": "قنوات"
            }
          ]
        },
        {
          "id": 1196,
          "name": "کهک",
          "districts": [
            {
              "id": 827,
              "name": "کهک"
            }
          ]
        }
      ]
    },
    {
      "id": 27,
      "name": "کردستان",
      "cities": [
        {
          "id": 304,
          "name": "بانه",
          "districts": [
            {
              "id": 1349,
              "name": "آرمرده"
            },
            {
              "id": 1518,
              "name": "بانه"
            },
            {
              "id": 1474,
              "name": "بوئین سفلی"
            },
            {
              "id": 1073,
              "name": "کانی سور"
            }
          ]
        },
        {
          "id": 305,
          "name": "بیجار",
          "districts": [
            {
              "id": 1595,
              "name": "بابارشانی"
            },
            {
              "id": 1743,
              "name": "بیجار"
            },
            {
              "id": 1235,
              "name": "پیرتاج"
            },
            {
              "id": 952,
              "name": "توپ آغاج"
            },
            {
              "id": 1829,
              "name": "یاسوکند"
            }
          ]
        },
        {
          "id": 306,
          "name": "دهگلان",
          "districts": [
            {
              "id": 1198,
              "name": "بلبان آباد"
            },
            {
              "id": 1078,
              "name": "دهگلان"
            }
          ]
        },
        {
          "id": 307,
          "name": "دیواندره",
          "districts": [
            {
              "id": 1399,
              "name": "دیواندره"
            },
            {
              "id": 563,
              "name": "زرینه"
            },
            {
              "id": 1524,
              "name": "هزارکانیان"
            }
          ]
        },
        {
          "id": 308,
          "name": "سروآباد",
          "districts": [
            {
              "id": 615,
              "name": "اورامان تخت"
            },
            {
              "id": 930,
              "name": "سروآباد"
            }
          ]
        },
        {
          "id": 309,
          "name": "سقز",
          "districts": [
            {
              "id": 1116,
              "name": "سقز"
            },
            {
              "id": 502,
              "name": "سنته"
            },
            {
              "id": 1862,
              "name": "صاحب"
            }
          ]
        },
        {
          "id": 310,
          "name": "سنندج",
          "districts": [
            {
              "id": 1705,
              "name": "حسین آباد"
            },
            {
              "id": 1823,
              "name": "سنندج"
            },
            {
              "id": 1787,
              "name": "شویشه"
            }
          ]
        },
        {
          "id": 311,
          "name": "قروه",
          "districts": [
            {
              "id": 1700,
              "name": "دزج"
            },
            {
              "id": 1717,
              "name": "دلبران"
            },
            {
              "id": 1447,
              "name": "سریش آباد"
            },
            {
              "id": 1197,
              "name": "قروه"
            },
            {
              "id": 738,
              "name": "مالوجه"
            }
          ]
        },
        {
          "id": 312,
          "name": "کامیاران",
          "districts": [
            {
              "id": 1364,
              "name": "کامیاران"
            },
            {
              "id": 1316,
              "name": "موچش"
            }
          ]
        },
        {
          "id": 313,
          "name": "مریوان",
          "districts": [
            {
              "id": 1704,
              "name": "برده رشه"
            },
            {
              "id": 838,
              "name": "چناره"
            },
            {
              "id": 1794,
              "name": "کانی دینار"
            },
            {
              "id": 1802,
              "name": "مریوان"
            }
          ]
        }
      ]
    },
    {
      "id": 28,
      "name": "کرمان",
      "cities": [
        {
          "id": 314,
          "name": "ارزوئیه",
          "districts": [
            {
              "id": 1451,
              "name": "ارزوئیه"
            },
            {
              "id": 611,
              "name": "علی آباد"
            }
          ]
        },
        {
          "id": 315,
          "name": "انار",
          "districts": [
            {
              "id": 915,
              "name": "امین شهر"
            },
            {
              "id": 540,
              "name": "انار"
            }
          ]
        },
        {
          "id": 316,
          "name": "بافت",
          "districts": [
            {
              "id": 776,
              "name": "بافت"
            },
            {
              "id": 508,
              "name": "بزنجان"
            },
            {
              "id": 1426,
              "name": "کشکوئیه"
            }
          ]
        },
        {
          "id": 317,
          "name": "بردسیر",
          "districts": [
            {
              "id": 1687,
              "name": "بردسیر"
            },
            {
              "id": 828,
              "name": "دشتکار"
            },
            {
              "id": 1603,
              "name": "گلزار"
            },
            {
              "id": 630,
              "name": "لاله زار"
            },
            {
              "id": 900,
              "name": "نگار"
            }
          ]
        },
        {
          "id": 318,
          "name": "بم",
          "districts": [
            {
              "id": 1154,
              "name": "بروات"
            },
            {
              "id": 1851,
              "name": "بم"
            }
          ]
        },
        {
          "id": 1265,
          "name": "جازموریان",
          "districts": [
            {
              "id": 758,
              "name": "زهکلوت"
            }
          ]
        },
        {
          "id": 319,
          "name": "جیرفت",
          "districts": [
            {
              "id": 1143,
              "name": "بلوک"
            },
            {
              "id": 1683,
              "name": "جبالبارز"
            },
            {
              "id": 1366,
              "name": "جیرفت"
            },
            {
              "id": 945,
              "name": "درب بهشت"
            },
            {
              "id": 598,
              "name": "علی آباد"
            }
          ]
        },
        {
          "id": 320,
          "name": "رابر",
          "districts": [
            {
              "id": 721,
              "name": "رابر"
            },
            {
              "id": 489,
              "name": "هنزا"
            }
          ]
        },
        {
          "id": 321,
          "name": "راور",
          "districts": [
            {
              "id": 568,
              "name": "راور"
            },
            {
              "id": 1658,
              "name": "هجدک"
            }
          ]
        },
        {
          "id": 322,
          "name": "رفسنجان",
          "districts": [
            {
              "id": 1429,
              "name": "بهرمان"
            },
            {
              "id": 1486,
              "name": "جوادیه الهیه"
            },
            {
              "id": 1220,
              "name": "رفسنجان"
            },
            {
              "id": 866,
              "name": "صفائیه"
            },
            {
              "id": 1854,
              "name": "کشکوئیه"
            },
            {
              "id": 480,
              "name": "مس سرچشمه"
            }
          ]
        },
        {
          "id": 323,
          "name": "رودبارجنوب",
          "districts": [
            {
              "id": 1081,
              "name": "رودبار"
            }
          ]
        },
        {
          "id": 324,
          "name": "ریگان",
          "districts": [
            {
              "id": 1695,
              "name": "رحمت آباد"
            },
            {
              "id": 1211,
              "name": "عباس آبادسردار"
            },
            {
              "id": 481,
              "name": "گنبکی"
            },
            {
              "id": 1263,
              "name": "محمدآباد"
            }
          ]
        },
        {
          "id": 325,
          "name": "زرند",
          "districts": [
            {
              "id": 897,
              "name": "خانوک"
            },
            {
              "id": 1334,
              "name": "ریحان"
            },
            {
              "id": 1505,
              "name": "زرند"
            },
            {
              "id": 1718,
              "name": "سیریز"
            },
            {
              "id": 471,
              "name": "یزدان شهر"
            }
          ]
        },
        {
          "id": 326,
          "name": "سیرجان",
          "districts": [
            {
              "id": 810,
              "name": "بلورد"
            },
            {
              "id": 1812,
              "name": "پاریز"
            },
            {
              "id": 605,
              "name": "خواجوشهر"
            },
            {
              "id": 1402,
              "name": "زیدآباد"
            },
            {
              "id": 998,
              "name": "سیرجان"
            },
            {
              "id": 1609,
              "name": "نجف شهر"
            },
            {
              "id": 1476,
              "name": "هماشهر"
            }
          ]
        },
        {
          "id": 327,
          "name": "شهربابک",
          "districts": [
            {
              "id": 1320,
              "name": "جوزم"
            },
            {
              "id": 1001,
              "name": "خاتون آباد"
            },
            {
              "id": 1739,
              "name": "خورسند"
            },
            {
              "id": 534,
              "name": "دهج"
            },
            {
              "id": 1742,
              "name": "شهربابک"
            }
          ]
        },
        {
          "id": 328,
          "name": "عنبرآباد",
          "districts": [
            {
              "id": 892,
              "name": "دوساری"
            },
            {
              "id": 1262,
              "name": "عنبرآباد"
            },
            {
              "id": 479,
              "name": "مردهک"
            }
          ]
        },
        {
          "id": 329,
          "name": "فاریاب",
          "districts": [
            {
              "id": 522,
              "name": "حور پاسفید"
            },
            {
              "id": 1100,
              "name": "فاریاب"
            }
          ]
        },
        {
          "id": 330,
          "name": "فهرج",
          "districts": [
            {
              "id": 1087,
              "name": "دهنو اسلام اباد"
            },
            {
              "id": 947,
              "name": "فهرج"
            }
          ]
        },
        {
          "id": 331,
          "name": "قلعه گنج",
          "districts": [
            {
              "id": 1747,
              "name": "چاه دادخدا"
            },
            {
              "id": 517,
              "name": "رمشک"
            },
            {
              "id": 732,
              "name": "قلعه گنج"
            }
          ]
        },
        {
          "id": 332,
          "name": "کرمان",
          "districts": [
            {
              "id": 831,
              "name": "اختیارآباد"
            },
            {
              "id": 1815,
              "name": "اندوهجرد"
            },
            {
              "id": 1487,
              "name": "باغین"
            },
            {
              "id": 1849,
              "name": "جوپار"
            },
            {
              "id": 768,
              "name": "چترود"
            },
            {
              "id": 1214,
              "name": "راین"
            },
            {
              "id": 817,
              "name": "زنگی آباد"
            },
            {
              "id": 677,
              "name": "شهداد"
            },
            {
              "id": 1439,
              "name": "کاظم آباد"
            },
            {
              "id": 526,
              "name": "کرمان"
            },
            {
              "id": 1041,
              "name": "گلباف"
            },
            {
              "id": 929,
              "name": "ماهان"
            },
            {
              "id": 666,
              "name": "محی آباد"
            }
          ]
        },
        {
          "id": 333,
          "name": "کهنوج",
          "districts": [
            {
              "id": 814,
              "name": "چاه مرید"
            },
            {
              "id": 762,
              "name": "دهکهان"
            },
            {
              "id": 1137,
              "name": "کهنوج"
            }
          ]
        },
        {
          "id": 334,
          "name": "کوهبنان",
          "districts": [
            {
              "id": 960,
              "name": "کوهبنان"
            },
            {
              "id": 1875,
              "name": "کیانشهر"
            }
          ]
        },
        {
          "id": 335,
          "name": "منوجان",
          "districts": [
            {
              "id": 1721,
              "name": "منوجان"
            },
            {
              "id": 477,
              "name": "نودژ"
            }
          ]
        },
        {
          "id": 336,
          "name": "نرماشیر",
          "districts": [
            {
              "id": 1670,
              "name": "نرماشیر"
            },
            {
              "id": 1394,
              "name": "نظام شهر"
            }
          ]
        }
      ]
    },
    {
      "id": 29,
      "name": "کرمانشاه",
      "cities": [
        {
          "id": 337,
          "name": "اسلام آباد غرب",
          "districts": [
            {
              "id": 818,
              "name": "اسلام آبادغرب"
            },
            {
              "id": 802,
              "name": "حمیل"
            }
          ]
        },
        {
          "id": 338,
          "name": "پاوه",
          "districts": [
            {
              "id": 1639,
              "name": "بان وره"
            },
            {
              "id": 1682,
              "name": "باینگان"
            },
            {
              "id": 1230,
              "name": "پاوه"
            },
            {
              "id": 546,
              "name": "نودشه"
            },
            {
              "id": 1327,
              "name": "نوسود"
            }
          ]
        },
        {
          "id": 339,
          "name": "ثلاث باباجانی",
          "districts": [
            {
              "id": 773,
              "name": "ازگله"
            },
            {
              "id": 1089,
              "name": "تازه آباد"
            },
            {
              "id": 1268,
              "name": "میرآباد"
            }
          ]
        },
        {
          "id": 340,
          "name": "جوانرود",
          "districts": [
            {
              "id": 664,
              "name": "جوانرود"
            },
            {
              "id": 1779,
              "name": "شروینه"
            }
          ]
        },
        {
          "id": 341,
          "name": "دالاهو",
          "districts": [
            {
              "id": 1767,
              "name": "ریجاب"
            },
            {
              "id": 1552,
              "name": "کرندغرب"
            },
            {
              "id": 1453,
              "name": "گهواره"
            }
          ]
        },
        {
          "id": 342,
          "name": "روانسر",
          "districts": [
            {
              "id": 1710,
              "name": "روانسر"
            },
            {
              "id": 983,
              "name": "شاهو"
            }
          ]
        },
        {
          "id": 343,
          "name": "سرپل ذهاب",
          "districts": [
            {
              "id": 1512,
              "name": "سرپل ذهاب"
            }
          ]
        },
        {
          "id": 344,
          "name": "سنقر",
          "districts": [
            {
              "id": 1432,
              "name": "سطر"
            },
            {
              "id": 1664,
              "name": "سنقر"
            }
          ]
        },
        {
          "id": 345,
          "name": "صحنه",
          "districts": [
            {
              "id": 1732,
              "name": "صحنه"
            },
            {
              "id": 1169,
              "name": "میان راهان"
            }
          ]
        },
        {
          "id": 346,
          "name": "قصرشیرین",
          "districts": [
            {
              "id": 1388,
              "name": "سومار"
            },
            {
              "id": 1711,
              "name": "قصرشیرین"
            }
          ]
        },
        {
          "id": 347,
          "name": "کرمانشاه",
          "districts": [
            {
              "id": 497,
              "name": "رباط"
            },
            {
              "id": 1163,
              "name": "قلعه"
            },
            {
              "id": 607,
              "name": "کرمانشاه"
            },
            {
              "id": 18699,
              "name": "کرمانشاه"
            },
            {
              "id": 1421,
              "name": "کوزران"
            },
            {
              "id": 864,
              "name": "هلشی"
            }
          ]
        },
        {
          "id": 348,
          "name": "کنگاور",
          "districts": [
            {
              "id": 1716,
              "name": "کنگاور"
            },
            {
              "id": 1572,
              "name": "گودین"
            }
          ]
        },
        {
          "id": 349,
          "name": "گیلانغرب",
          "districts": [
            {
              "id": 1731,
              "name": "سرمست"
            },
            {
              "id": 946,
              "name": "گیلانغرب"
            }
          ]
        },
        {
          "id": 350,
          "name": "هرسین",
          "districts": [
            {
              "id": 1422,
              "name": "بیستون"
            },
            {
              "id": 1736,
              "name": "هرسین"
            }
          ]
        }
      ]
    },
    {
      "id": 30,
      "name": "کهگیلویه وبویراحمد",
      "cities": [
        {
          "id": 351,
          "name": "باشت",
          "districts": [
            {
              "id": 1104,
              "name": "باشت"
            },
            {
              "id": 504,
              "name": "بوستان"
            }
          ]
        },
        {
          "id": 352,
          "name": "بهمئی",
          "districts": [
            {
              "id": 1015,
              "name": "لیکک"
            }
          ]
        },
        {
          "id": 353,
          "name": "بویراحمد",
          "districts": [
            {
              "id": 1172,
              "name": "چیتاب"
            },
            {
              "id": 18857,
              "name": "سپیدار"
            },
            {
              "id": 582,
              "name": "گراب سفلی"
            },
            {
              "id": 1481,
              "name": "مادوان"
            },
            {
              "id": 1264,
              "name": "یاسوج"
            }
          ]
        },
        {
          "id": 354,
          "name": "چرام",
          "districts": [
            {
              "id": 1088,
              "name": "چرام"
            },
            {
              "id": 910,
              "name": "سرفاریاب"
            }
          ]
        },
        {
          "id": 355,
          "name": "دنا",
          "districts": [
            {
              "id": 1753,
              "name": "پاتاوه"
            },
            {
              "id": 1601,
              "name": "سی سخت"
            }
          ]
        },
        {
          "id": 356,
          "name": "کهگیلویه",
          "districts": [
            {
              "id": 905,
              "name": "دهدشت"
            },
            {
              "id": 1053,
              "name": "دیشموک"
            },
            {
              "id": 571,
              "name": "سوق"
            },
            {
              "id": 524,
              "name": "قلعه رییسی"
            }
          ]
        },
        {
          "id": 357,
          "name": "گچساران",
          "districts": [
            {
              "id": 622,
              "name": "دوگنبدان"
            }
          ]
        },
        {
          "id": 358,
          "name": "لنده",
          "districts": [
            {
              "id": 600,
              "name": "لنده"
            }
          ]
        },
        {
          "id": 1188,
          "name": "مارگون",
          "districts": [
            {
              "id": 1057,
              "name": "مارگون"
            },
            {
              "id": 12911,
              "name": "مارگون"
            }
          ]
        }
      ]
    },
    {
      "id": 31,
      "name": "گلستان",
      "cities": [
        {
          "id": 359,
          "name": "آزادشهر",
          "districts": [
            {
              "id": 1678,
              "name": "آزاد شهر"
            },
            {
              "id": 523,
              "name": "نگین شهر"
            },
            {
              "id": 578,
              "name": "نوده خاندوز"
            }
          ]
        },
        {
          "id": 360,
          "name": "آق قلا",
          "districts": [
            {
              "id": 1570,
              "name": "آق قلا"
            },
            {
              "id": 1492,
              "name": "انبار آلوم"
            }
          ]
        },
        {
          "id": 361,
          "name": "بندرگز",
          "districts": [
            {
              "id": 1309,
              "name": "بندر گز"
            },
            {
              "id": 1620,
              "name": "نوکنده"
            }
          ]
        },
        {
          "id": 362,
          "name": "ترکمن",
          "districts": [
            {
              "id": 1502,
              "name": "بندرترکمن"
            },
            {
              "id": 12817,
              "name": "سیجوال"
            }
          ]
        },
        {
          "id": 363,
          "name": "رامیان",
          "districts": [
            {
              "id": 1671,
              "name": "تاتارعلیا"
            },
            {
              "id": 1713,
              "name": "خان ببین"
            },
            {
              "id": 1407,
              "name": "دلند"
            },
            {
              "id": 1754,
              "name": "رامیان"
            }
          ]
        },
        {
          "id": 364,
          "name": "علی آباد کتول",
          "districts": [
            {
              "id": 1425,
              "name": "سنگدوین"
            },
            {
              "id": 672,
              "name": "علی آباد"
            },
            {
              "id": 1233,
              "name": "فاضل آباد"
            },
            {
              "id": 1374,
              "name": "مزرعه"
            }
          ]
        },
        {
          "id": 365,
          "name": "کردکوی",
          "districts": [
            {
              "id": 1456,
              "name": "کردکوی"
            }
          ]
        },
        {
          "id": 366,
          "name": "کلاله",
          "districts": [
            {
              "id": 1783,
              "name": "فراغی"
            },
            {
              "id": 965,
              "name": "کلاله"
            }
          ]
        },
        {
          "id": 367,
          "name": "گالیکش",
          "districts": [
            {
              "id": 1582,
              "name": "صادق آباد"
            },
            {
              "id": 621,
              "name": "گالیکش"
            },
            {
              "id": 18639,
              "name": "ینقاق"
            }
          ]
        },
        {
          "id": 368,
          "name": "گرگان",
          "districts": [
            {
              "id": 894,
              "name": "جلین"
            },
            {
              "id": 1436,
              "name": "سرخنکلاته"
            },
            {
              "id": 1861,
              "name": "قرق"
            },
            {
              "id": 1207,
              "name": "گرگان"
            }
          ]
        },
        {
          "id": 369,
          "name": "گمیشان",
          "districts": [
            {
              "id": 559,
              "name": "سیمین شهر"
            },
            {
              "id": 1591,
              "name": "گمیش تپه"
            }
          ]
        },
        {
          "id": 370,
          "name": "گنبدکاووس",
          "districts": [
            {
              "id": 1850,
              "name": "اینچه برون"
            },
            {
              "id": 1489,
              "name": "کرند"
            },
            {
              "id": 1684,
              "name": "گنبد کاووس"
            }
          ]
        },
        {
          "id": 371,
          "name": "مراوه تپه",
          "districts": [
            {
              "id": 850,
              "name": "گلیداغ"
            },
            {
              "id": 1442,
              "name": "مراوه تپه"
            }
          ]
        },
        {
          "id": 372,
          "name": "مینودشت",
          "districts": [
            {
              "id": 18843,
              "name": "القجر"
            },
            {
              "id": 990,
              "name": "دوزین"
            },
            {
              "id": 1483,
              "name": "مینو دشت"
            }
          ]
        }
      ]
    },
    {
      "id": 32,
      "name": "گیلان",
      "cities": [
        {
          "id": 373,
          "name": "آستارا",
          "districts": [
            {
              "id": 1250,
              "name": "آستارا"
            },
            {
              "id": 1114,
              "name": "لوندویل"
            }
          ]
        },
        {
          "id": 374,
          "name": "آستانه اشرفیه",
          "districts": [
            {
              "id": 1045,
              "name": "آستانه اشرفیه"
            },
            {
              "id": 1744,
              "name": "کیاشهر"
            }
          ]
        },
        {
          "id": 375,
          "name": "املش",
          "districts": [
            {
              "id": 944,
              "name": "املش"
            },
            {
              "id": 1029,
              "name": "رانکوه"
            }
          ]
        },
        {
          "id": 376,
          "name": "بندر انزلی",
          "districts": [
            {
              "id": 1472,
              "name": "بندرانزلی"
            }
          ]
        },
        {
          "id": 1182,
          "name": "خمام",
          "districts": [
            {
              "id": 798,
              "name": "خمام"
            }
          ]
        },
        {
          "id": 377,
          "name": "رشت",
          "districts": [
            {
              "id": 1008,
              "name": "پیربازار"
            },
            {
              "id": 728,
              "name": "خشکبیجار"
            },
            {
              "id": 1872,
              "name": "رشت"
            },
            {
              "id": 873,
              "name": "سنگر"
            },
            {
              "id": 1507,
              "name": "کوچصفهان"
            },
            {
              "id": 1541,
              "name": "لشت نشاء"
            },
            {
              "id": 1173,
              "name": "لولمان"
            }
          ]
        },
        {
          "id": 378,
          "name": "رضوانشهر",
          "districts": [
            {
              "id": 1273,
              "name": "پره سر"
            },
            {
              "id": 1155,
              "name": "رضوانشهر"
            }
          ]
        },
        {
          "id": 379,
          "name": "رودبار",
          "districts": [
            {
              "id": 1226,
              "name": "بره سر"
            },
            {
              "id": 1528,
              "name": "توتکابن"
            },
            {
              "id": 1725,
              "name": "جیرنده"
            },
            {
              "id": 903,
              "name": "رستم آباد"
            },
            {
              "id": 1213,
              "name": "رودبار"
            },
            {
              "id": 1638,
              "name": "لوشان"
            },
            {
              "id": 1339,
              "name": "منجیل"
            }
          ]
        },
        {
          "id": 380,
          "name": "رودسر",
          "districts": [
            {
              "id": 619,
              "name": "چابکسر"
            },
            {
              "id": 1712,
              "name": "رحیم آباد"
            },
            {
              "id": 1401,
              "name": "رودسر"
            },
            {
              "id": 588,
              "name": "کلاچای"
            },
            {
              "id": 1149,
              "name": "واجارگاه"
            }
          ]
        },
        {
          "id": 381,
          "name": "سیاهکل",
          "districts": [
            {
              "id": 783,
              "name": "دیلمان"
            },
            {
              "id": 1652,
              "name": "سیاهکل"
            }
          ]
        },
        {
          "id": 382,
          "name": "شفت",
          "districts": [
            {
              "id": 1315,
              "name": "احمد سر گوراب"
            },
            {
              "id": 1848,
              "name": "شفت"
            }
          ]
        },
        {
          "id": 383,
          "name": "صومعه سرا",
          "districts": [
            {
              "id": 1578,
              "name": "صومعه سرا"
            },
            {
              "id": 661,
              "name": "گوراب زرمیخ"
            },
            {
              "id": 1610,
              "name": "مرجقل"
            }
          ]
        },
        {
          "id": 384,
          "name": "طوالش",
          "districts": [
            {
              "id": 1772,
              "name": "اسالم"
            },
            {
              "id": 1056,
              "name": "چوبر"
            },
            {
              "id": 1124,
              "name": "حویق"
            },
            {
              "id": 1586,
              "name": "لیسار"
            },
            {
              "id": 1443,
              "name": "هشتپر"
            }
          ]
        },
        {
          "id": 385,
          "name": "فومن",
          "districts": [
            {
              "id": 939,
              "name": "فومن"
            },
            {
              "id": 476,
              "name": "ماسوله"
            },
            {
              "id": 1782,
              "name": "ماکلوان"
            }
          ]
        },
        {
          "id": 386,
          "name": "لاهیجان",
          "districts": [
            {
              "id": 737,
              "name": "رودبنه"
            },
            {
              "id": 1478,
              "name": "لاهیجان"
            }
          ]
        },
        {
          "id": 387,
          "name": "لنگرود",
          "districts": [
            {
              "id": 811,
              "name": "اطاقور"
            },
            {
              "id": 1310,
              "name": "چاف وچمخاله"
            },
            {
              "id": 706,
              "name": "شلمان"
            },
            {
              "id": 1102,
              "name": "کومله"
            },
            {
              "id": 733,
              "name": "لنگرود"
            }
          ]
        },
        {
          "id": 388,
          "name": "ماسال",
          "districts": [
            {
              "id": 1359,
              "name": "بازار جمعه"
            },
            {
              "id": 794,
              "name": "ماسال"
            }
          ]
        }
      ]
    },
    {
      "id": 33,
      "name": "لرستان",
      "cities": [
        {
          "id": 389,
          "name": "ازنا",
          "districts": [
            {
              "id": 718,
              "name": "ازنا"
            },
            {
              "id": 835,
              "name": "مومن آباد"
            }
          ]
        },
        {
          "id": 390,
          "name": "الیگودرز",
          "districts": [
            {
              "id": 1800,
              "name": "الیگودرز"
            },
            {
              "id": 660,
              "name": "چمن سلطان"
            },
            {
              "id": 1840,
              "name": "شاپور آباد"
            },
            {
              "id": 1241,
              "name": "شول آباد"
            }
          ]
        },
        {
          "id": 391,
          "name": "بروجرد",
          "districts": [
            {
              "id": 652,
              "name": "اشترینان"
            },
            {
              "id": 1122,
              "name": "بروجرد"
            },
            {
              "id": 1344,
              "name": "ونایی"
            }
          ]
        },
        {
          "id": 392,
          "name": "پلدختر",
          "districts": [
            {
              "id": 1036,
              "name": "پلدختر"
            },
            {
              "id": 1574,
              "name": "سراب حمام"
            },
            {
              "id": 769,
              "name": "معمولان"
            }
          ]
        },
        {
          "id": 393,
          "name": "چگنی",
          "districts": [
            {
              "id": 1581,
              "name": "چم پلک"
            },
            {
              "id": 940,
              "name": "سراب دوره"
            },
            {
              "id": 865,
              "name": "ویسیان"
            }
          ]
        },
        {
          "id": 394,
          "name": "خرم آباد",
          "districts": [
            {
              "id": 1240,
              "name": "بیران شهر"
            },
            {
              "id": 868,
              "name": "خرم آباد"
            },
            {
              "id": 594,
              "name": "زاغه"
            },
            {
              "id": 1312,
              "name": "سپید دشت"
            },
            {
              "id": 1514,
              "name": "مسکن مهر فاز2"
            }
          ]
        },
        {
          "id": 395,
          "name": "دلفان",
          "districts": [
            {
              "id": 12793,
              "name": "برخوردار"
            },
            {
              "id": 1093,
              "name": "نورآباد"
            },
            {
              "id": 1554,
              "name": "هفت چشمه"
            }
          ]
        },
        {
          "id": 396,
          "name": "دورود",
          "districts": [
            {
              "id": 1564,
              "name": "چالانچولان"
            },
            {
              "id": 549,
              "name": "دورود"
            },
            {
              "id": 12941,
              "name": "ناصرالدین"
            }
          ]
        },
        {
          "id": 397,
          "name": "رومشکان",
          "districts": [
            {
              "id": 1370,
              "name": "چقابل"
            },
            {
              "id": 9991,
              "name": "سوری"
            }
          ]
        },
        {
          "id": 398,
          "name": "سلسله",
          "districts": [
            {
              "id": 1000,
              "name": "الشتر"
            },
            {
              "id": 881,
              "name": "فیروز آباد"
            }
          ]
        },
        {
          "id": 399,
          "name": "کوهدشت",
          "districts": [
            {
              "id": 1550,
              "name": "درب گنبد"
            },
            {
              "id": 1455,
              "name": "کوهدشت"
            },
            {
              "id": 1589,
              "name": "کوهنانی"
            },
            {
              "id": 1465,
              "name": "گراب"
            }
          ]
        }
      ]
    },
    {
      "id": 34,
      "name": "مازندران",
      "cities": [
        {
          "id": 400,
          "name": "آمل",
          "districts": [
            {
              "id": 1655,
              "name": "آمل"
            },
            {
              "id": 895,
              "name": "امام زاده عبدالله"
            },
            {
              "id": 754,
              "name": "بابکان"
            },
            {
              "id": 1189,
              "name": "دابودشت"
            },
            {
              "id": 1737,
              "name": "رینه"
            },
            {
              "id": 527,
              "name": "گزنک"
            }
          ]
        },
        {
          "id": 401,
          "name": "بابل",
          "districts": [
            {
              "id": 927,
              "name": "امیرکلا"
            },
            {
              "id": 1177,
              "name": "بابل"
            },
            {
              "id": 985,
              "name": "خوشرودپی"
            },
            {
              "id": 512,
              "name": "زرگر"
            },
            {
              "id": 1457,
              "name": "گتاب"
            },
            {
              "id": 1103,
              "name": "گلوگاه"
            },
            {
              "id": 1587,
              "name": "مرزی کلا"
            }
          ]
        },
        {
          "id": 402,
          "name": "بابلسر",
          "districts": [
            {
              "id": 491,
              "name": "بابلسر"
            },
            {
              "id": 1152,
              "name": "بهنمیر"
            },
            {
              "id": 1692,
              "name": "هادی شهر"
            }
          ]
        },
        {
          "id": 403,
          "name": "بهشهر",
          "districts": [
            {
              "id": 1380,
              "name": "بهشهر"
            },
            {
              "id": 1464,
              "name": "خلیل شهر"
            },
            {
              "id": 735,
              "name": "رستمکلا"
            }
          ]
        },
        {
          "id": 404,
          "name": "تنکابن",
          "districts": [
            {
              "id": 1869,
              "name": "تنکابن"
            },
            {
              "id": 1504,
              "name": "خرم آباد"
            },
            {
              "id": 1341,
              "name": "شیرود"
            },
            {
              "id": 513,
              "name": "نشتارود"
            }
          ]
        },
        {
          "id": 405,
          "name": "جویبار",
          "districts": [
            {
              "id": 1773,
              "name": "جویبار"
            },
            {
              "id": 1844,
              "name": "کوهی خیل"
            }
          ]
        },
        {
          "id": 406,
          "name": "چالوس",
          "districts": [
            {
              "id": 1584,
              "name": "چالوس"
            },
            {
              "id": 821,
              "name": "مرزن آباد"
            },
            {
              "id": 893,
              "name": "هچیرود"
            }
          ]
        },
        {
          "id": 407,
          "name": "رامسر",
          "districts": [
            {
              "id": 12953,
              "name": "دالخانی"
            },
            {
              "id": 1185,
              "name": "رامسر"
            },
            {
              "id": 1216,
              "name": "کتالم وسادات شهر"
            }
          ]
        },
        {
          "id": 408,
          "name": "ساری",
          "districts": [
            {
              "id": 1484,
              "name": "آکند"
            },
            {
              "id": 786,
              "name": "پایین هولار"
            },
            {
              "id": 1231,
              "name": "ساری"
            },
            {
              "id": 779,
              "name": "فرح آباد"
            },
            {
              "id": 734,
              "name": "فریم"
            },
            {
              "id": 538,
              "name": "کیاسر"
            }
          ]
        },
        {
          "id": 409,
          "name": "سوادکوه",
          "districts": [
            {
              "id": 1539,
              "name": "آلاشت"
            },
            {
              "id": 1674,
              "name": "پل سفید"
            },
            {
              "id": 562,
              "name": "زیر آب"
            }
          ]
        },
        {
          "id": 410,
          "name": "سوادکوه شمالی",
          "districts": [
            {
              "id": 1330,
              "name": "شیرگاه"
            }
          ]
        },
        {
          "id": 411,
          "name": "سیمرغ",
          "districts": [
            {
              "id": 1629,
              "name": "کیاکلا"
            }
          ]
        },
        {
          "id": 412,
          "name": "عباس آباد",
          "districts": [
            {
              "id": 1761,
              "name": "سلمانشهر"
            },
            {
              "id": 1680,
              "name": "عباس آباد"
            },
            {
              "id": 1569,
              "name": "کلارآباد"
            }
          ]
        },
        {
          "id": 413,
          "name": "فریدونکنار",
          "districts": [
            {
              "id": 483,
              "name": "آستانه سرا"
            },
            {
              "id": 1628,
              "name": "فریدونکنار"
            }
          ]
        },
        {
          "id": 414,
          "name": "قائم شهر",
          "districts": [
            {
              "id": 606,
              "name": "ارطه"
            },
            {
              "id": 1814,
              "name": "قائم شهر"
            }
          ]
        },
        {
          "id": 415,
          "name": "کلاردشت",
          "districts": [
            {
              "id": 1212,
              "name": "کلاردشت"
            }
          ]
        },
        {
          "id": 416,
          "name": "گلوگاه",
          "districts": [
            {
              "id": 675,
              "name": "گلوگاه"
            }
          ]
        },
        {
          "id": 417,
          "name": "محمودآباد",
          "districts": [
            {
              "id": 1860,
              "name": "سرخ رود"
            },
            {
              "id": 1654,
              "name": "محمودآباد"
            }
          ]
        },
        {
          "id": 418,
          "name": "میاندورود",
          "districts": [
            {
              "id": 545,
              "name": "سورک"
            },
            {
              "id": 1161,
              "name": "طبقده"
            }
          ]
        },
        {
          "id": 419,
          "name": "نکا",
          "districts": [
            {
              "id": 736,
              "name": "نکا"
            }
          ]
        },
        {
          "id": 420,
          "name": "نور",
          "districts": [
            {
              "id": 567,
              "name": "ایزدشهر"
            },
            {
              "id": 1630,
              "name": "بلده"
            },
            {
              "id": 1278,
              "name": "چمستان"
            },
            {
              "id": 1604,
              "name": "رویان"
            },
            {
              "id": 1874,
              "name": "نور"
            }
          ]
        },
        {
          "id": 421,
          "name": "نوشهر",
          "districts": [
            {
              "id": 1174,
              "name": "پول"
            },
            {
              "id": 1537,
              "name": "کجور"
            },
            {
              "id": 1396,
              "name": "نوشهر"
            }
          ]
        }
      ]
    },
    {
      "id": 35,
      "name": "مرکزی",
      "cities": [
        {
          "id": 422,
          "name": "آشتیان",
          "districts": [
            {
              "id": 1746,
              "name": "آشتیان"
            }
          ]
        },
        {
          "id": 423,
          "name": "اراک",
          "districts": [
            {
              "id": 994,
              "name": "اراک"
            },
            {
              "id": 789,
              "name": "داودآباد"
            },
            {
              "id": 1223,
              "name": "ساروق"
            },
            {
              "id": 599,
              "name": "شهرجدید امیرکبیر"
            },
            {
              "id": 919,
              "name": "کارچان"
            }
          ]
        },
        {
          "id": 424,
          "name": "تفرش",
          "districts": [
            {
              "id": 1577,
              "name": "تفرش"
            }
          ]
        },
        {
          "id": 425,
          "name": "خمین",
          "districts": [
            {
              "id": 1326,
              "name": "خمین"
            },
            {
              "id": 1461,
              "name": "قورچی باشی"
            }
          ]
        },
        {
          "id": 426,
          "name": "خنداب",
          "districts": [
            {
              "id": 872,
              "name": "جاورسیان"
            },
            {
              "id": 1378,
              "name": "خنداب"
            }
          ]
        },
        {
          "id": 427,
          "name": "دلیجان",
          "districts": [
            {
              "id": 1050,
              "name": "دلیجان"
            },
            {
              "id": 1656,
              "name": "نراق"
            }
          ]
        },
        {
          "id": 428,
          "name": "زرندیه",
          "districts": [
            {
              "id": 673,
              "name": "پرندک"
            },
            {
              "id": 1256,
              "name": "خشکرود"
            },
            {
              "id": 982,
              "name": "رازقان"
            },
            {
              "id": 1858,
              "name": "زاویه"
            },
            {
              "id": 1178,
              "name": "مامونیه"
            }
          ]
        },
        {
          "id": 429,
          "name": "ساوه",
          "districts": [
            {
              "id": 1820,
              "name": "آوه"
            },
            {
              "id": 1617,
              "name": "ساوه"
            },
            {
              "id": 1097,
              "name": "غرق آباد"
            },
            {
              "id": 844,
              "name": "نوبران"
            }
          ]
        },
        {
          "id": 430,
          "name": "شازند",
          "districts": [
            {
              "id": 663,
              "name": "آستانه"
            },
            {
              "id": 1135,
              "name": "توره"
            },
            {
              "id": 719,
              "name": "شازند"
            },
            {
              "id": 689,
              "name": "شهباز"
            },
            {
              "id": 1555,
              "name": "شهرجدیدمهاجران"
            },
            {
              "id": 1877,
              "name": "هندودر"
            }
          ]
        },
        {
          "id": 431,
          "name": "فراهان",
          "districts": [
            {
              "id": 687,
              "name": "تلخاب"
            },
            {
              "id": 679,
              "name": "خنجین"
            },
            {
              "id": 966,
              "name": "فرمهین"
            }
          ]
        },
        {
          "id": 432,
          "name": "کمیجان",
          "districts": [
            {
              "id": 716,
              "name": "کمیجان"
            },
            {
              "id": 547,
              "name": "میلاجرد"
            }
          ]
        },
        {
          "id": 433,
          "name": "محلات",
          "districts": [
            {
              "id": 756,
              "name": "محلات"
            },
            {
              "id": 1133,
              "name": "نیمور"
            }
          ]
        }
      ]
    },
    {
      "id": 36,
      "name": "هرمزگان",
      "cities": [
        {
          "id": 434,
          "name": "ابوموسی",
          "districts": [
            {
              "id": 854,
              "name": "ابوموسی"
            }
          ]
        },
        {
          "id": 435,
          "name": "بستک",
          "districts": [
            {
              "id": 1253,
              "name": "بستک"
            },
            {
              "id": 496,
              "name": "جناح"
            },
            {
              "id": 1032,
              "name": "کوخردهرنگ"
            },
            {
              "id": 693,
              "name": "کوهیچ"
            },
            {
              "id": 970,
              "name": "هنگویه"
            }
          ]
        },
        {
          "id": 436,
          "name": "بشاگرد",
          "districts": [
            {
              "id": 723,
              "name": "سردشت"
            },
            {
              "id": 1182,
              "name": "گوهران"
            }
          ]
        },
        {
          "id": 437,
          "name": "بندرعباس",
          "districts": [
            {
              "id": 581,
              "name": "بندرعباس"
            },
            {
              "id": 816,
              "name": "تازیان پایین"
            },
            {
              "id": 1466,
              "name": "تخت"
            },
            {
              "id": 1600,
              "name": "فین"
            },
            {
              "id": 1495,
              "name": "قلعه قاضی"
            },
            {
              "id": 1590,
              "name": "هرمز"
            }
          ]
        },
        {
          "id": 438,
          "name": "بندرلنگه",
          "districts": [
            {
              "id": 1619,
              "name": "بندرلنگه"
            },
            {
              "id": 710,
              "name": "چارک"
            },
            {
              "id": 856,
              "name": "کنگ"
            },
            {
              "id": 1625,
              "name": "کیش"
            },
            {
              "id": 1075,
              "name": "لمزان"
            }
          ]
        },
        {
          "id": 439,
          "name": "پارسیان",
          "districts": [
            {
              "id": 1113,
              "name": "پارسیان"
            },
            {
              "id": 1196,
              "name": "دشتی"
            },
            {
              "id": 1503,
              "name": "کوشکنار"
            }
          ]
        },
        {
          "id": 440,
          "name": "جاسک",
          "districts": [
            {
              "id": 749,
              "name": "بندرجاسک"
            },
            {
              "id": 1204,
              "name": "لیردف"
            }
          ]
        },
        {
          "id": 441,
          "name": "حاجی آباد",
          "districts": [
            {
              "id": 887,
              "name": "حاجی آباد"
            },
            {
              "id": 1340,
              "name": "سرگز"
            },
            {
              "id": 896,
              "name": "فارغان"
            }
          ]
        },
        {
          "id": 442,
          "name": "خمیر",
          "districts": [
            {
              "id": 1627,
              "name": "پل"
            },
            {
              "id": 1822,
              "name": "خمیر"
            },
            {
              "id": 1485,
              "name": "رویدر"
            },
            {
              "id": 774,
              "name": "شهر جدیدعلوی"
            }
          ]
        },
        {
          "id": 443,
          "name": "رودان",
          "districts": [
            {
              "id": 1386,
              "name": "بالاشهر"
            },
            {
              "id": 510,
              "name": "بیکاه"
            },
            {
              "id": 1134,
              "name": "دهبارز"
            },
            {
              "id": 1636,
              "name": "زیارتعلی"
            }
          ]
        },
        {
          "id": 444,
          "name": "سیریک",
          "districts": [
            {
              "id": 1424,
              "name": "سیریک"
            },
            {
              "id": 503,
              "name": "کوهستک"
            },
            {
              "id": 1347,
              "name": "گروک"
            }
          ]
        },
        {
          "id": 445,
          "name": "قشم",
          "districts": [
            {
              "id": 1624,
              "name": "درگهان"
            },
            {
              "id": 867,
              "name": "رمکان"
            },
            {
              "id": 1501,
              "name": "سوزا"
            },
            {
              "id": 1842,
              "name": "طبل"
            },
            {
              "id": 1030,
              "name": "قشم"
            }
          ]
        },
        {
          "id": 446,
          "name": "میناب",
          "districts": [
            {
              "id": 1626,
              "name": "بندزرک"
            },
            {
              "id": 1529,
              "name": "تیرور"
            },
            {
              "id": 1734,
              "name": "زهوکی"
            },
            {
              "id": 616,
              "name": "سندرک"
            },
            {
              "id": 569,
              "name": "کرگان"
            },
            {
              "id": 792,
              "name": "میناب"
            },
            {
              "id": 1118,
              "name": "هشتبندی"
            }
          ]
        }
      ]
    },
    {
      "id": 37,
      "name": "همدان",
      "cities": [
        {
          "id": 447,
          "name": "اسدآباد",
          "districts": [
            {
              "id": 1580,
              "name": "آجین"
            },
            {
              "id": 1798,
              "name": "اسدآباد"
            },
            {
              "id": 1764,
              "name": "پالیز"
            }
          ]
        },
        {
          "id": 448,
          "name": "بهار",
          "districts": [
            {
              "id": 1830,
              "name": "بهار"
            },
            {
              "id": 658,
              "name": "صالح آباد"
            },
            {
              "id": 1119,
              "name": "لالجین"
            },
            {
              "id": 1657,
              "name": "مهاجران"
            }
          ]
        },
        {
          "id": 449,
          "name": "تویسرکان",
          "districts": [
            {
              "id": 876,
              "name": "تویسرکان"
            },
            {
              "id": 680,
              "name": "سرکان"
            },
            {
              "id": 1831,
              "name": "فرسفج"
            }
          ]
        },
        {
          "id": 450,
          "name": "درگزین",
          "districts": [
            {
              "id": 18662,
              "name": "شاهنجرین"
            },
            {
              "id": 1252,
              "name": "قروه درگزین"
            },
            {
              "id": 1531,
              "name": "کرفس"
            }
          ]
        },
        {
          "id": 451,
          "name": "رزن",
          "districts": [
            {
              "id": 1709,
              "name": "دمق"
            },
            {
              "id": 1043,
              "name": "رزن"
            }
          ]
        },
        {
          "id": 452,
          "name": "فامنین",
          "districts": [
            {
              "id": 1458,
              "name": "فامنین"
            }
          ]
        },
        {
          "id": 453,
          "name": "کبودرآهنگ",
          "districts": [
            {
              "id": 1281,
              "name": "شیرین سو"
            },
            {
              "id": 1418,
              "name": "کبودرآهنگ"
            },
            {
              "id": 1166,
              "name": "گل تپه"
            }
          ]
        },
        {
          "id": 454,
          "name": "ملایر",
          "districts": [
            {
              "id": 954,
              "name": "ازندریان"
            },
            {
              "id": 1563,
              "name": "اسلامشهر آق گل"
            },
            {
              "id": 1835,
              "name": "جوکار"
            },
            {
              "id": 796,
              "name": "زنگنه"
            },
            {
              "id": 1408,
              "name": "سامن"
            },
            {
              "id": 1191,
              "name": "ملایر"
            }
          ]
        },
        {
          "id": 455,
          "name": "نهاوند",
          "districts": [
            {
              "id": 878,
              "name": "برزول"
            },
            {
              "id": 1040,
              "name": "فیروزان"
            },
            {
              "id": 724,
              "name": "گیان"
            },
            {
              "id": 1752,
              "name": "نهاوند"
            }
          ]
        },
        {
          "id": 456,
          "name": "همدان",
          "districts": [
            {
              "id": 1373,
              "name": "جورقان"
            },
            {
              "id": 777,
              "name": "قهاوند"
            },
            {
              "id": 1427,
              "name": "مریانج"
            },
            {
              "id": 650,
              "name": "همدان"
            }
          ]
        }
      ]
    },
    {
      "id": 38,
      "name": "یزد",
      "cities": [
        {
          "id": 457,
          "name": "ابرکوه",
          "districts": [
            {
              "id": 1817,
              "name": "ابرکوه"
            },
            {
              "id": 612,
              "name": "مهردشت"
            }
          ]
        },
        {
          "id": 458,
          "name": "اردکان",
          "districts": [
            {
              "id": 888,
              "name": "احمدآباد"
            },
            {
              "id": 692,
              "name": "اردکان"
            },
            {
              "id": 1415,
              "name": "خرانق"
            },
            {
              "id": 1560,
              "name": "عقدا"
            }
          ]
        },
        {
          "id": 459,
          "name": "اشکذر",
          "districts": [
            {
              "id": 935,
              "name": "اشکذر"
            },
            {
              "id": 860,
              "name": "خضرآباد"
            },
            {
              "id": 12812,
              "name": "مجومرد"
            }
          ]
        },
        {
          "id": 460,
          "name": "بافق",
          "districts": [
            {
              "id": 482,
              "name": "بافق"
            }
          ]
        },
        {
          "id": 461,
          "name": "بهاباد",
          "districts": [
            {
              "id": 701,
              "name": "بهاباد"
            }
          ]
        },
        {
          "id": 462,
          "name": "تفت",
          "districts": [
            {
              "id": 574,
              "name": "بخ"
            },
            {
              "id": 1276,
              "name": "تفت"
            },
            {
              "id": 472,
              "name": "نیر"
            }
          ]
        },
        {
          "id": 463,
          "name": "خاتم",
          "districts": [
            {
              "id": 654,
              "name": "هرات"
            }
          ]
        },
        {
          "id": 1192,
          "name": "زارچ",
          "districts": [
            {
              "id": 1788,
              "name": "زارچ"
            }
          ]
        },
        {
          "id": 464,
          "name": "مروست",
          "districts": [
            {
              "id": 1525,
              "name": "مروست"
            }
          ]
        },
        {
          "id": 465,
          "name": "مهریز",
          "districts": [
            {
              "id": 1037,
              "name": "مهریز"
            }
          ]
        },
        {
          "id": 466,
          "name": "میبد",
          "districts": [
            {
              "id": 655,
              "name": "بفروئیه"
            },
            {
              "id": 1865,
              "name": "میبد"
            },
            {
              "id": 623,
              "name": "ندوشن"
            }
          ]
        },
        {
          "id": 467,
          "name": "یزد",
          "districts": [
            {
              "id": 1117,
              "name": "حمیدیا"
            },
            {
              "id": 1385,
              "name": "شاهدیه"
            },
            {
              "id": 1811,
              "name": "یزد"
            }
          ]
        }
      ]
    }
  ];

  export default Provinces;
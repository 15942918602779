import {React, useEffect, useState} from 'react';

import Description from './Description';


function ProductDetails(props) {
    const {product} = props;

    const [content, setContent] = useState(null);    

    useEffect(() => {
        if (product) {
            product.name &&
            product.description &&
            setContent(product.description);
        }
    }, [product]);



    return (
        <div className="product-details-tab w-100">
            <ul className="nav nav-pills justify-content-center" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="product-desc-link" data-toggle="tab"
                        href="#product-desc-tab" role="tab" aria-controls="product-desc-tab"
                        aria-selected="true">توضیحات</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="product-info-link" data-toggle="tab" href="#product-info-tab"
                        role="tab" aria-controls="product-info-tab" aria-selected="false">راهنمای سایز</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="product-shipping-link" data-toggle="tab"
                        href="#product-shipping-tab" role="tab" aria-controls="product-shipping-tab"
                        aria-selected="false">ارسال و بازگشت</a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="product-desc-tab" role="tabpanel"
                    aria-labelledby="product-desc-link">
                    <div className="product-desc-content w-100">
                        <h3>اطلاعات محصول</h3>

                        {content && <Description content={content} />}
                    </div>
                </div>
                <div className="tab-pane fade" id="product-info-tab" role="tabpanel"
                    aria-labelledby="product-info-link">
                    <div className="product-desc-content">
                        <h3>راهنمای سایز</h3>
                        <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید
                            سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی نامفهوملورم ایپسوم متن
                            ساختگی با تولید سادگی نامفهوملورم ایپسوم متن ساختگی با تولید سادگی نامفهوملورم
                            ایپسوم متن ساختگی با تولید سادگی نامفهوم. </p>

                        <h3>اطلاعات بیشتر</h3>
                        <ul>
                            <li>لورم ایپسوم متن ساختگی</li>
                            <li>لورم ایپسوم متن ساختگی با تولید سادگی</li>
                            <li>لورم ایپسوم</li>
                            <li>لورم ایپسوم متن ساختگی </li>
                            <li>لورم ایپسوم متن ساختگی با تولید سادگی</li>
                            <li> ارتفاع: 31سانتی متر; عرض: 32سانتی متر; عمق: 12سانتی متر</li>
                        </ul>

                        <h3>سایز</h3>
                        <p>تک سایز</p>
                    </div>
                </div>
                <div className="tab-pane fade" id="product-shipping-tab" role="tabpanel"
                    aria-labelledby="product-shipping-link">
                    <div className="product-desc-content">
                        <h3>ارسال و بازگشت</h3>
                        <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید
                            سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن
                            ساختگی با تولید سادگی نامفهوم<br />
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید
                            سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی نامفهوملورم ایپسوم متن
                            ساختگی با تولید سادگی نامفهوملورم ایپسوم متن ساختگی با تولید سادگی نامفهوم.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetails;
import { React, useState, useEffect, } from 'react';
import {
    Link
} from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';


import { apiClient } from '../../Services/Api';




export default function Slider() {


    const [slides, setSlides] = useState([]);



    useEffect(() => {


        // setTimeout(() => {
        //     setSlides(
        //         [
        //             {
        //                 "title": "اسلاید 2",
        //                 "link": "https://tailor.7modir.com/product?slug=product-2",
        //                 "description": null,
        //                 "image": "https://tailor.7modir.com/storage/slider/uI55kposq78UVmy71XVxNdeLyMqY5Vik9qEfLEQL.jpg"
        //             },
        //             {
        //                 "title": "اسلاید 1",
        //                 "link": null,
        //                 "description": "عنوان اسلاید 1",
        //                 "image": "https://tailor.7modir.com/storage/slider/Tv5KwRYn0KJtaHNhXKR6qSmB1xscmgkq42787q91.jpg"
        //             }
        //         ]
        //     );
        // }, 150);


        apiClient.get('slider').then(response => {
            if (response.request.status === 200) {
                setSlides(response.data.data);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });



    }, []);




    return (


        <div className="intro-slider-container">
            {slides.length ? <OwlCarousel options={{
                items: 1,
                dots: true,
                nav: false,
                rtl: true,
                loop: true, // Enable continuous loop
                autoplay: true, // Enable autoplay
                autoplayTimeout: 3000, // Time in milliseconds between each slide (e.g., 3000ms = 3 seconds)
                autoplayHoverPause: true, // Pause autoplay when mouse is over the carousel    
                responsive: {
                    992: {
                        nav: false
                    }
                }
            }}>



                {
                    (slides && slides.length)
                    ?
                    slides.map(
                        s => (
                            <div key={s.title} className="intro-slide" style={{ backgroundImage: `url(${s.image})` }}>
                                <div className="container intro-content text-center">
                                    <h3 className="intro-subtitle text-white">{s.description}</h3>
                                    <h1 className="intro-title text-white">{s.title}</h1>
                                    {s.link && <Link to={s.link} className="btn btn-outline-white-4">
                                        <span>مشاهده</span>
                                    </Link>}
                                </div>
                            </div>
                        )
                    )
                    :
                    ''
                }


                {/* <div className="intro-slide" style={{ backgroundImage: "url(https://picsum.photos/1600/900?random=100)" }}>
                    <div className="container intro-content text-center">
                        <h3 className="intro-subtitle text-white">عالی به نظر برسید</h3>
                        <h1 className="intro-title text-white">محصولات جدید</h1>
                        <Link to="category.html" className="btn btn-outline-white-4">
                            <span>مشاهده</span>
                        </Link>
                    </div>
                </div>
                <div className="intro-slide" style={{ backgroundImage: "url(https://picsum.photos/1600/900?random=200)" }}>
                    <div className="container intro-content text-center">
                        <h3 className="intro-subtitle text-white">از دست ندهید</h3>
                        <h1 className="intro-title text-white">پیشنهاد های شگفت انگیز</h1>
                        <Link to="category.html" className="btn btn-outline-white-4">
                            <span>مشاهده</span>
                        </Link>
                    </div>
                </div>
                <div className="intro-slide" style={{ backgroundImage: "url(https://picsum.photos/1600/900?random=300)" }}>
                    <div className="container intro-content text-center">
                        <h3 className="intro-subtitle text-white">سوم</h3>
                        <h1 className="intro-title text-white">سوووووووووووووووم</h1>
                        <Link to="category.html" className="btn btn-outline-white-4">
                            <span>مشاهده</span>
                        </Link>
                    </div>
                </div>
 */}

            </OwlCarousel>
            :
            ''
            }
            <span className="slider-loader"></span>
        </div>

    );
};
import { React, useState, useEffect, useContext, useRef } from 'react';
import './CommonLayout1.css';
import './CommonLayout2.css';


import { AuthContext } from '../../Context/AuthContext';
import { UserContext } from '../../Context/UserContext';

import { useCookies } from 'react-cookie';



import { apiClient } from '../../Services/Api';


import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
    useParams,
    useLocation,
    Navigate
  } from 'react-router-dom';



  
// (query-string) from npm
const queryString = require('query-string');





export default function CommonLayout({ children }) {

    const Auth = useContext(AuthContext);
    const User = useContext(UserContext);

    const [categories, setCategories] = useState([]);
    const [cartCount, setCartCount] = useState(null);
    const [showMobSuberMenu, setShowMobSuberMenu] = useState(false);
    const [showMobMenu, setShowMobMenu] = useState(false);
    const [showMobSubMenu, setShowMobSubMenu] = useState(false);

    const buttonRef = useRef(null);



    const navigate = useNavigate();


    let location = useLocation();

    const parsed = queryString.parse(location.search);

    const [cookies, setCookie, removeCookie] = useCookies(['maandaanaaCart']);



    const addCartQueryString = (newCartCount) => {
        // Parse the current query string
        const currentQuery = parsed;

        // Update the shipment_type in the query parameters
        const updatedQuery = {
            ...currentQuery,
            cart_count: newCartCount,
        };

        // Stringify the updated query parameters
        const newQueryString = queryString.stringify(updatedQuery);

        // Navigate to the current pathname with the updated query string
        navigate(`${location.pathname}?${newQueryString}`, { replace: true });
    };


    const removeCartQueryString = () => {
        // Parse the current query string
        const currentQuery = parsed;
    
        // Remove the 'cart_count' from the query parameters
        const { cart_count, ...updatedQuery } = currentQuery;
    
        // Stringify the updated query parameters without 'cart_count'
        const newQueryString = queryString.stringify(updatedQuery);
    
        // Navigate to the current pathname with the updated query string
        navigate(`${location.pathname}?${newQueryString}`, { replace: true });
    };
    

    // useEffect(() => {

    //     setTimeout(() => {
    //         addCartQueryString(5);
    //     }, 5000);

    // }, []);


    useEffect(() => {

        if (parsed.cart_count) {
            setCartCount(parsed.cart_count);
            removeCartQueryString('cart_count')
        }


    }, [location]);



    useEffect(() => {


        // setTimeout(() => {
        //     setCategories(
        //         [
        //             {
        //                 "title": "ست مهمانی",
        //                 "slug": "party",
        //                 "attributes": [],
        //                 "children": [
        //                     {
        //                         "title": "کت و دامن",
        //                         "slug": "kotvadaaman",
        //                         "attributes": [
        //                             {
        //                                 "name": "قد شلوار",
        //                                 "slug": "qad-shalvar",
        //                                 "input_type": "number",
        //                                 "description": "قد شلوار",
        //                                 "default": "50",
        //                                 "min_value": 40,
        //                                 "max_value": 100,
        //                                 "step": 5,
        //                                 "is_common": false,
        //                                 "is_mandatory": true,
        //                                 "options": []
        //                             },
        //                             {
        //                                 "name": "دور کمر",
        //                                 "slug": "dor-kamar",
        //                                 "input_type": "single_select",
        //                                 "description": null,
        //                                 "default": null,
        //                                 "min_value": null,
        //                                 "max_value": null,
        //                                 "step": null,
        //                                 "is_common": true,
        //                                 "is_mandatory": true,
        //                                 "options": [
        //                                     {
        //                                         "value": "100 سانت",
        //                                         "price_coefficient": 100000,
        //                                         "is_default": false
        //                                     },
        //                                     {
        //                                         "value": "80 سانت",
        //                                         "price_coefficient": 100000,
        //                                         "is_default": true
        //                                     }
        //                                 ]
        //                             }
        //                         ]
        //                     },
        //                     {
        //                         "title": "پیراهن مجلسی",
        //                         "slug": "pirahan",
        //                         "attributes": [
        //                             {
        //                                 "name": "دور کمر",
        //                                 "slug": "dor-kamar",
        //                                 "input_type": "single_select",
        //                                 "description": null,
        //                                 "default": null,
        //                                 "min_value": null,
        //                                 "max_value": null,
        //                                 "step": null,
        //                                 "is_common": true,
        //                                 "is_mandatory": true,
        //                                 "options": [
        //                                     {
        //                                         "value": "100 سانت",
        //                                         "price_coefficient": 100000,
        //                                         "is_default": false
        //                                     },
        //                                     {
        //                                         "value": "80 سانت",
        //                                         "price_coefficient": 100000,
        //                                         "is_default": true
        //                                     }
        //                                 ]
        //                             }
        //                         ]
        //                     }
        //                 ]
        //             },
        //             {
        //                 "title": "شلوار",
        //                 "slug": "shalvar",
        //                 "attributes": [
        //                     {
        //                         "name": "قد شلوار",
        //                         "slug": "qad-shalvar",
        //                         "input_type": "number",
        //                         "description": "قد شلوار",
        //                         "default": "50",
        //                         "min_value": 40,
        //                         "max_value": 100,
        //                         "step": 5,
        //                         "is_common": false,
        //                         "is_mandatory": true,
        //                         "options": []
        //                     },
        //                     {
        //                         "name": "دور کمر",
        //                         "slug": "dor-kamar",
        //                         "input_type": "single_select",
        //                         "description": null,
        //                         "default": null,
        //                         "min_value": null,
        //                         "max_value": null,
        //                         "step": null,
        //                         "is_common": true,
        //                         "is_mandatory": true,
        //                         "options": [
        //                             {
        //                                 "value": "100 سانت",
        //                                 "price_coefficient": 100000,
        //                                 "is_default": false
        //                             },
        //                             {
        //                                 "value": "80 سانت",
        //                                 "price_coefficient": 100000,
        //                                 "is_default": true
        //                             }
        //                         ]
        //                     }
        //                 ],
        //                 "children": []
        //             },
        //             {
        //                 "title": "مانتو",
        //                 "slug": "maanto",
        //                 "attributes": [
        //                     {
        //                         "name": "قد شلوار",
        //                         "slug": "qad-shalvar",
        //                         "input_type": "number",
        //                         "description": "قد شلوار",
        //                         "default": "50",
        //                         "min_value": 40,
        //                         "max_value": 100,
        //                         "step": 5,
        //                         "is_common": false,
        //                         "is_mandatory": true,
        //                         "options": []
        //                     }
        //                 ],
        //                 "children": []
        //             }
        //         ]
        //     );
        // }, 150);



        apiClient.get('category').then(response => {
            if (response.request.status === 200) {
                setCategories(response.data.data);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });



    }, []);


    useEffect(() => {

        if (Auth.maandaanaaLoggedIn) {

            apiClient.get('config').then(response => {
                if (response.request.status === 200) {
                    setCartCount(response.data.data.cart_items_count);
                }
            }).catch(error => {
                if (error.response) {
                    // nothing
                }
            }).finally(() => {
            });

        } else {

            const token = cookies["maandaanaaCart"] && cookies["maandaanaaCart"] !== "undefined" && cookies["maandaanaaCart"] !== "null"
            ? cookies["maandaanaaCart"]
            : null;

            token && apiClient.get('config?token=' + token).then(response => {
                if (response.request.status === 200) {
                    setCartCount(response.data.data.cart_items_count);
                }
            }).catch(error => {
                if (error.response) {
                    // nothing
                }
            }).finally(() => {
            });


        }



    }, [Auth.maandaanaaLoggedIn]);



    return (
        <div className={'page-wrapper ' + (showMobMenu ? 'mmenu-active' : '')}>
            <header className="header header-6">
                <div className="header-top py-2">
                    <div className="container">
                        <div className="header-left">
                            <ul className="top-menu top-link-menu d-none d-md-block">
                                <li>
                                    <Link to="#">لینک ها</Link>
                                    <ul>
                                        <li><Link to="tel:+989128785961"><i className="icon-phone"></i>شماره تماس : 09128785961</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="header-right">
                            <div className="social-icons social-icons-color">
                                <Link to="https://www.t.me/+989128785961" className="social-icon social-telegram" title="تلگرام" target="_blank"><i className="icon-telegram"></i></Link>
                                <Link to="https://www.wa.me/+989128785961" className="social-icon social-whatsapp" title="واتسپ" target="_blank"><i className="icon-whatsapp"></i></Link>
                                <Link to="https://www.instagram.com/maandaanaa_collection" className="social-icon social-instagram" title="اینستاگرام" target="_blank"><i className="icon-instagram"></i></Link>
                            </div>
                            <ul className="top-menu top-link-menu">
                                <li>
                                    <Link to="#">لینک ها</Link>
                                    <ul>
                                        {
                                            Auth.maandaanaaLoggedIn
                                                ?
                                                <>
                                                    <li><Link to="/login"><i className="icon-bars"></i>داشبورد</Link></li>
                                                    <li onClick={() => { Auth.logout() }}><Link ><i className="icon-arrow-left"></i>خروج</Link></li>
                                                </>
                                                :
                                                <li><Link to="/login"><i className="icon-user"></i>ورود</Link></li>
                                        }
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-middle">
                    <div className="container">
                        <div className="header-left">
                            <div className="header-search header-search-extended header-search-visible d-none d-lg-block">
                                <Link to="#" className="search-toggle" role="button"><i className="icon-search"></i></Link>
                                <form action="#" method="get">
                                    <div className="header-search-wrapper search-wrapper-wide">
                                        <label htmlFor="q" className="sr-only">جستجو</label>
                                        <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                                        <input type="search" className="form-control" name="q" id="q" placeholder="جستجوی محصول ..." required />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="header-center">
                            <Link to="/" className="logo">
                                <img src="../../images/demos/demo-6/logo.png" alt="Maandaanaa-Collection Logo" width="82" height="20" />
                            </Link>
                        </div>
                        <div className="header-right">
                            <Link to="#" className="wishlist-link">
                                <i className="icon-heart-o"></i>
                                {/* <span className="wishlist-count">3</span> */}
                                <span className="wishlist-txt">علاقه مندی</span>
                            </Link>
                            <Link to="/cart" className="cart-dropdown">
                                <div className="dropdown-toggle">
                                    <i className="icon-shopping-cart"></i>
                                    {cartCount ? <span className="cart-count">{cartCount}</span> : ''}
                                    <span className="cart-txt">سبد خرید</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="header-bottom sticky-header">
                    <div className="container">
                        <div className="header-left">
                            <nav className="main-nav">
                                <ul className="menu sf-arrows">
                                    <li className={'link ' + ((location.pathname === '/' || location.pathname === '/home' || location.pathname === '/index') ? 'active' : '')}>
                                        <Link to="/" className="sf-without-ul">خانه</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="sf-with-ul">محصولات </Link>


                                        <ul>
                                            {
                                                (categories && categories.length)
                                                    ?
                                                    categories.map(
                                                        c => {
                                                            if (c.children && c.children.length) {
                                                                return <li key={c.slug}>
                                                                    <Link to="#" className="sf-with-ul">{c.title}</Link>
                                                                    <ul>

                                                                        {
                                                                            c.children.map(
                                                                                ch => (
                                                                                    <li key={ch.slug}><Link to={'/categories?slug=' + ch.slug}>{ch.title}</Link></li>
                                                                                )
                                                                            )
                                                                        }

                                                                    </ul>
                                                                </li>
                                                            } else {
                                                                return <li key={c.slug}><Link to={'/categories?slug=' + c.slug}>{c.title}</Link></li>
                                                            }
                                                        }
                                                    )
                                                    :
                                                    ''
                                            }
                                        </ul>






                                    </li>
                                    <li className={'link ' + ((location.pathname === '/about') ? 'active' : '')}>
                                        <Link to="/about" className="sf-without-ul">درباره ما</Link>
                                    </li>
                                    <li className={'link ' + ((location.pathname === '/contact') ? 'active' : '')}>
                                        <Link to="/contact" className="sf-without-ul">تماس با ما</Link>
                                    </li>
                                    <li className={'link ' + ((location.pathname === '/faq') ? 'active' : '')}>
                                        <Link to="/faq" className="sf-without-ul">سوالات متداول</Link>
                                    </li>
                                    {/* <li className={'link ' + ((location.pathname === '/blog') ? 'active' : '')}>
                                        <Link to="/blog" className="sf-without-ul">وبلاگ</Link>
                                    </li> */}
                                </ul>
                            </nav>
                            <button className="mobile-menu-toggler" onClick={() => {setShowMobMenu(true)}}>
                                <span className="sr-only">فهرست</span>
                                <i className="icon-bars"></i>
                            </button>
                        </div>
                        <div className="header-right">
                            <i className="la la-lightbulb-o"></i>
                            <p>خرید تا 30 درصد تخفیف</p>
                        </div>
                    </div>
                </div>
            </header>
            {
                children
            }

            <footer className="footer">
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-lg-3">
                                <div className="widget widget-about">
                                    <h4 className="widget-title">درباره ما</h4>
                                    <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم لورم ایپسوم متن ساختگی با تولید سادگی
                                        نامفهوم. </p>
                                    <div className="social-icons">
                                        <Link to="https://www.instagram.com/maandaanaa_collection" className="social-icon" title="اینستاگرام" target="_blank"><i
                                            className="icon-instagram"></i></Link>
                                        <Link to="https://www.wa.me/+989128785961" className="social-icon" title="واتسپ" target="_blank"><i
                                            className="icon-whatsapp"></i></Link>
                                        <Link to="https://www.t.me/+989128785961" className="social-icon" title="تلگرام" target="_blank"><i
                                            className="icon-telegram"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title">لینک های مفید</h4>
                                    <ul className="widget-list">
                                        <li><Link to="/categories">محصولات</Link></li>
                                        <li><Link to="/about">درباره ما</Link></li>
                                        <li><Link to="/contact">تماس با ما</Link></li>
                                        <li><Link to="/faq">سوالات متدوال</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title">حساب کاربری</h4>
                                    <ul className="widget-list">
                                        <li><Link to="/login">ورود</Link></li>
                                        <li><Link to="/cart">سبد خرید</Link></li>
                                        {/* <li><Link to="/wishlist">لیست علاقه مندی ها</Link></li> */}
                                        <li><Link to="/dashboard">پیگیری سفارشات</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title">مجوز ها</h4>
                                    <ul className="widget-list">
                                        {/* Add your license content here */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <img src="../images/demos/demo-6/logo-footer.png" alt="Maandaanaa-Collection Logo" width="82" height="25" />
                        <p className="footer-copyright text-center">کپی رایت © 2023 تمامی حقوق محفوظ است.<br />Powered by webbies.ir</p>
                    </div>
                </div>
            </footer>


            <div className="mobile-menu-overlay"></div>
            <div className="mobile-menu-container">
                <div className="mobile-menu-wrapper">
                    <span className="mobile-menu-close"onClick={() => {setShowMobMenu(false)}}><i className="icon-close"></i></span>
                    <form action="#" method="get" className="mobile-search">
                        <label htmlFor="mobile-search" className="sr-only">جستجو</label>
                        <input type="search" className="form-control" name="mobile-search" id="mobile-search"
                            placeholder="جستجو در ..." required />
                        <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                    </form>
                    <ul className="mt-2 nav nav-pills-mobile nav-border-anim" role="tablist">
                        <li className="nav-item">
                            <Link className="d-none nav-link active" id="mobile-menu-link" to="#mobile-menu-tab"
                                role="tab" aria-controls="mobile-menu-tab" aria-selected="true">منو</Link>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="mobile-menu-tab" role="tabpanel"
                            aria-labelledby="mobile-menu-link">
                            <nav className="mobile-nav">
                                <ul className="mobile-menu">
                                    <li className={'link ' + ((location.pathname === '/' || location.pathname === '/home' || location.pathname === '/index') ? 'active' : '')}>
                                        <Link to="/">خانه</Link>
                                    </li>
                                    
                                    <li className={'' + (showMobSubMenu ? 'open' : '')} onClick={() => {}}>
                                    {/* <li> */}
                                        <Link to="#" onClick={() => {buttonRef.current.click();setShowMobSubMenu(!showMobSubMenu);} }>محصولات
                                        <span className="mmenu-btn" ref={buttonRef}></span>
                                        </Link>
                                        <ul className={'transition ' + (showMobSubMenu ? 'd-block' : 'd-block')} style={{overflow: 'hidden', height: (showMobSubMenu ? 'auto' : '0'), borderBottom: (showMobSubMenu ? '0.1rem solid rgba(255, 255, 255, 0.08)' : '0'), padding: (showMobSubMenu ? '.7rem 0 .9rem' : '0')}}>
                                            {
                                                categories.length
                                                ?
                                                categories.map(
                                                    c => {
                                                        if (c.children && c.children.length) {
                                                            return <li key={c.slug} className={showMobSuberMenu ? 'open' : ''}>
                                                            <Link to="#" className="sf-with-ul" onClick={() => setShowMobSuberMenu(!showMobSuberMenu)}>{c.title}
                                                            <span className="mmenu-btn"></span>
                                                            </Link>
                                                            <ul style={{transition: 'all .3s ease-in-out', display: showMobSuberMenu ? 'block' : 'block', height: showMobSuberMenu ? 'auto' : '0', padding: showMobSuberMenu ? '.7rem 0 .9rem' : '0', opacity: showMobSuberMenu ? '1' : '0' }}>
                                                                {
                                                                    c.children.map(
                                                                        ch => {
                                                                            return <li key={ch.slug}><Link to={'/categories?slug=' + ch.slug}>{ch.title}</Link></li>
                                                                        }
                                                                    )
                                                                }
                                                            </ul>
                                                        </li>
                                                        } else {
                                                            return <li key={c.slug}><Link to={'/categories?slug=' + c.slug}>{c.title}</Link></li>
                                                        }
                                                    }
                                                )
                                                :
                                                ''
                                            }
                                        </ul> 


                                    </li>


                                    <li className={'link ' + ((location.pathname === '/about') ? 'active' : '')}>
                                        <Link to="/about">درباه ما</Link>
                                    </li>
                                    <li className={'link ' + ((location.pathname === '/about') ? 'active' : '')}>
                                        <Link to="/about">تماس با ما</Link>
                                    </li>
                                    <li className={'link ' + ((location.pathname === '/faq') ? 'active' : '')}>
                                        <Link to="/faq">سوالات متداول</Link>
                                    </li>
                                    {/* <li className={'link ' + ((location.pathname === '/blog') ? 'active' : '')}>
                                        <Link to="/blog">وبلاگ</Link>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="social-icons">
                        <Link to="https://www.instagram.com/maandaanaa_collection" className="social-icon" target="_blank" title="اینستاگرام"><i className="icon-instagram"></i></Link>
                        <Link to="https://www.wa.me/+989128785961" className="social-icon" target="_blank" title="واتسپ"><i className="icon-whatsapp"></i></Link>
                        <Link to="https://www.t.me/+989128785961" className="social-icon" target="_blank" title="تلگرام"><i className="icon-telegram"></i></Link>
                    </div>
                </div>
            </div>



        </div>

    );
};
import { React, useState, useEffect, useContext } from 'react';
import CommonLAyout from '../../Layouts/CommonLayout';
import './Order.css';


import Provinces from '../../Utils/Provinces';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
    useParams,
    useLocation,
    Navigate
} from 'react-router-dom';




import { apiClient } from '../../Services/Api';


import { AuthContext } from '../../Context/AuthContext';


import { fixNumbers } from '../../Utils/FixNumbers';
import { commas } from '../../Utils/Commas';


import alert from '../../Components/Alert';



// (query-string) from npm
const queryString = require('query-string');




export default function Order() {


    const navigate = useNavigate();


    let location = useLocation();

    const parsed = queryString.parse(location.search);




    const Auth = useContext(AuthContext);

    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");

    const [details, setDetails] = useState("");
    const [postalCode, setPostalCode] = useState("");


    const [userAddresses, setUserAddresses] = useState([]);



    const [update, setUpdate] = useState(1);
    const [isLoading, setIsLoading] = useState(false);


    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedShipment, setSelectedShipment] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);


    const [shipmentTypes, setShipmentTypes] = useState([]);

    const [paymentTypes, setPaymentTypes] = useState([]);





    const changeShipmentQueryString = (newShipmentType) => {
        // Parse the current query string
        const currentQuery = parsed;

        // Update the shipment_type in the query parameters
        const updatedQuery = {
            ...currentQuery,
            shipment_type: newShipmentType,
        };

        // Stringify the updated query parameters
        const newQueryString = queryString.stringify(updatedQuery);

        // Navigate to the current pathname with the updated query string
        navigate(`${location.pathname}?${newQueryString}`, { replace: true });
    };

    const changePaymentQueryString = (newPaymentType) => {
        // Parse the current query string
        const currentQuery = parsed;

        // Update the shipment_type in the query parameters
        const updatedQuery = {
            ...currentQuery,
            payment_type: newPaymentType,
        };

        // Stringify the updated query parameters
        const newQueryString = queryString.stringify(updatedQuery);

        // Navigate to the current pathname with the updated query string
        navigate(`${location.pathname}?${newQueryString}`, { replace: true });
    };




    useEffect(() => {
        console.log('shipment: ' + selectedShipment + ', payment: ' + selectedPayment);
    }, [selectedShipment, selectedShipment]);


    useEffect(() => {
        // console.log('hasVip: ' + parsed.has_vip + ' , ' + typeof (parsed.has_vip));
        // console.log('' + parsed.shipment_type);
        if (shipmentTypes.length) {
            shipmentTypes.map(shipment => {
                if (shipment.title === parsed.shipment_type) {
                    setSelectedShipment(shipment.id.toString());
                }
            })
        }
    }, [location, shipmentTypes]);

    useEffect(() => {
        // console.log('' + parsed.payment_type);
        if (paymentTypes.length) {
            paymentTypes.map(payment => {
                if (payment.title === parsed.payment_type) {
                    setSelectedPayment(payment.id.toString());
                }
            })
        }
    }, [location, paymentTypes]);


    useEffect(() => {
        // console.log('shipID: ' + selectedShipment);
        if (shipmentTypes.length) {
            shipmentTypes.map(shipment => {
                if (shipment.id.toString() === selectedShipment) {
                    changeShipmentQueryString(shipment.title);
                }
            })
        }
    }, [selectedShipment]);

    useEffect(() => {
        // console.log('payID: ' + selectedPayment);
        if (paymentTypes.length) {
            paymentTypes.map(payment => {
                if (payment.id.toString() === selectedPayment) {
                    changePaymentQueryString(payment.title);
                }
            })
        }
    }, [selectedPayment]);


    const handleProvinceChange = (e) => {
        setProvince(e.target.value);
        setCity(""); // Reset city when province changes
    };
    const selectedProvince = Provinces.find(p => p.name === province);



    const handleOrder = () => {


        if (selectedAddress && selectedShipment && paymentTypes.length) {

            apiClient.post('order', {
                address: selectedAddress,
                shipment_type: selectedShipment,
                payment_type: selectedPayment ? selectedPayment : paymentTypes[0].id
            }).then(response => {
                if (response.request.status >= 200 || response.request.status <= 204) {
                    // 
                    alert(response.data.message, 'success', 'success', 3);
                    // response.data.data.payment_url
                    setTimeout(() => {
                        navigate('/dashboard#tab-orders', { replace: true });
                        window.location.assign(response.data.data.payment_url);
                    }, 2000);


                }
            }).catch(error => {
                alert('ثبت با خطا مواجه شد.', 'danger', 'error', 5);

                if (error.response) {
                    // nothing
                }
            }).finally(() => {
            });

        } else {
            alert('لطفا آدرس و شیوه ارسال را انتخاب کنید کنید.', 'danger', 'error', 5);
        }



    }



    useEffect(() => {


        // setTimeout(() => {

        //     setPaymentTypes([
        //         {
        //             "id": 1,
        //             "title": "kamel",
        //             "display_title": "کامل"
        //         },
        //         {
        //             "id": 2,
        //             "title": "beyane",
        //             "display_title": "بیعانه"
        //         }
        //     ]);

        //     setShipmentTypes(
        //         [
        //             {
        //                 "id": 1,
        //                 "title": "tipax",
        //                 "display_title": "تیپاکس",
        //                 "icon": "",
        //                 "price": 0
        //             },
        //             {
        //                 "id": 2,
        //                 "title": "post",
        //                 "display_title": "پست ویژه",
        //                 "icon": "",
        //                 "price": 200000
        //             }
        //         ]
        //     );
        // }, 300);


        // apiClient.get('order').then(response => {
        //     if (response.request.status >= 200 || response.request.status <= 204) {
        //         // 
        //         console.log(response.data.data);
        //     }
        // }).catch(error => {
        //     if (error.response) {
        //         // nothing
        //     }
        // }).finally(() => {
        // });





        apiClient.get('config').then(response => {
            if (response.request.status === 200) {
                setShipmentTypes(response.data.data.shipment_types);
                setPaymentTypes(response.data.data.payment_types);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });


    }, []);

    useEffect(() => {


        // setTimeout(() => {

        //     setUserAddresses(
        //         [
        //             {
        //                 id: 1,
        //                 address: 'ایلام - ایلام - سعدی - تختی',
        //                 postal_code: 12323123434
        //             },
        //             {
        //                 id: 2,
        //                 address: 'ایلام - ایلام - انقلاب - شمشاد',
        //                 postal_code: 8786867543
        //             },
        //         ]
        //     );

        // }, 300);

        Auth.maandaanaaLoggedIn && apiClient.get('address').then(response => {
            if (response.request.status === 200) {
                setUserAddresses(response.data.data);
            }
        }).catch(error => {
            if (error.response) {
                // nothing
            }
        }).finally(() => {
        });

    }, [Auth.maandaanaaLoggedIn, update]);



    const handleAddress = () => {

        if (province.length && city.length && (details.length > 5 && details.length < 150) && (postalCode.length === 10)) {

            // alert('ثبت موفق.', 'success', 'success', 3);

            apiClient.post('address', {
                address: province + ' - ' + city + ' - ' + details,
                postal_code: postalCode
            }).then(response => {
                if (response.request.status >= 200 || response.request.status <= 204) {

                    alert('با موفقیت ثبت شد.', 'success', 'success', 3);


                    setUpdate(setUpdate + 1);
                    setSelectedAddress((response.data.data.id).toString());

                    setDetails("");
                    setPostalCode("");
                }
            }).catch(error => {

                alert('ثبت با خطا مواجه شد.', 'danger', 'error', 5);

                if (error.response) {
                    // nothing
                }
            }).finally(() => {
            });


        } else {
            alert('لطفا اطلاعات را بررسی کنید. استان و شهر انتخاب شود. آدرس بیش از 5 کاراکتر، کدپستی 10 رقمی و بدون خط تیره باشد.', 'danger', 'error', 7);
        }

    }






    return (
        <CommonLAyout>


            <main className="main">
                <div className="page-header text-center" style={{ backgroundImage: "url('/images/page-header-bg.jpg')" }}>
                    <div className="container">
                        <h1 className="page-title text-center">ثبت سفارش<span className="text-center">مزون ماندانا</span></h1>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">خانه</Link></li>
                            <li className="breadcrumb-item"><Link to="/cart">سبد خرید</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">ثبت سفارش</li>
                        </ol>
                    </div>
                </nav>
                <div className="page-content">
                    <div className="cart">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h6 className="font-weight-bold px-3">انتخاب آدرس:</h6>
                                    <ul className="list-group">
                                        {
                                            userAddresses && userAddresses.length
                                                ?
                                                userAddresses.map((a, i) => {
                                                    return (<li key={a.id} className={'list-group-item d-flex align-items-center justify-content-between ' + ((selectedAddress !== (a.id).toString()) ? 'list-group-item-light' : 'list-group-item-secondary')}>
                                                        <div className="d-flex flex-column">
                                                            <span>{a.address}</span>
                                                            <span className="font-weight-bold">{'کدپستی: ' + a.postal_code}</span>
                                                        </div>
                                                        <div class="btn btn-primary btn-rounded" style={{ minWidth: '40px', padding: '.5rem' }} onClick={() => {
                                                            setSelectedAddress((a.id).toString());
                                                        }}>
                                                            {
                                                                (selectedAddress !== (a.id).toString())
                                                                    ?
                                                                    'انتخاب'
                                                                    :
                                                                    '✔'
                                                            }
                                                        </div>
                                                    </li>)
                                                })
                                                :
                                                <p className="text-center font-weight-bold my-2">آدرس ثبت شده ای وجود ندارد.</p>
                                        }
                                    </ul>



                                    <div>


                                        <div className="accordion accordion-plus pl-0 pr-3" id="accordion-2">
                                            <div className="card px-0">
                                                <div className="card-header px-0" id="heading2-2">
                                                    <h2 className="card-title font-weight-bold">
                                                        <a
                                                            className="collapsed px-0"
                                                            role="button"
                                                            data-toggle="collapse"
                                                            href="#collapse2-2"
                                                            aria-expanded="false"
                                                            aria-controls="collapse2-2"
                                                        >
                                                            ثبت آدرس جدید:
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div
                                                    id="collapse2-2"
                                                    className="collapse"
                                                    aria-labelledby="heading2-2"
                                                    data-parent="#accordion-2"
                                                >
                                                    <div className="card-body px-0">

                                                        <div className="details-filter-row">
                                                            <label htmlFor="province" style={{width: '70px'}}>استان:</label>
                                                            <div className="select-custom ml-4">
                                                                <select name="province" id="province" defaultValue="" className="form-control" onChange={handleProvinceChange}>
                                                                    <option value="" disabled>انتخاب کنید</option>
                                                                    {
                                                                        Provinces.map(province => (
                                                                            <option key={'province-' + province.id} value={province.name}>{province.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="details-filter-row">
                                                            <label htmlFor="city" style={{width: '70px'}}>شهرستان:</label>
                                                            <div className="select-custom ml-4">
                                                                <select name="city" id="city" value={city} className="form-control" onChange={(e) => { setCity(e.target.value) }}>
                                                                    <option value="" disabled>انتخاب کنید</option>
                                                                    {
                                                                        selectedProvince ? selectedProvince.cities.map(city => (
                                                                            <option key={'city-' + city.id} value={city.name}>{city.name}</option>
                                                                        )) : null
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>آدرس:</label>
                                                                <input type="text" value={details} onChange={e => setDetails(e.target.value)} className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>کدپستی:</label>
                                                                <input type="text" value={postalCode} onChange={e => setPostalCode(fixNumbers(e.target.value))} className="form-control" />
                                                            </div>
                                                        </div>

                                                        <button type="button" class="btn btn-primary btn-rounded mx-auto" onClick={handleAddress}>ثبت آدرس</button>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div className="mt-3">
                                            <h4>ثبت آدرس جدید:</h4>
                                        </div> */}


                                    </div>


                                </div>





                                <div className="col-lg-6">

                                    <h6 className="font-weight-bold px-3">انتخاب شیوه ارسال:</h6>

                                    <ul className="list-group">
                                        {
                                            shipmentTypes && shipmentTypes.length
                                                ?
                                                shipmentTypes.map((a, i) => {
                                                    return (<li key={a.id} className={'list-group-item d-flex align-items-center justify-content-between ' + ((selectedShipment !== (a.id).toString()) ? 'list-group-item-light' : 'list-group-item-secondary')}>
                                                        <div className="d-flex flex-column">
                                                            <span>{a.display_title}</span>
                                                            <span className="font-weight-bold">{'هزینه: ' + (a.price ? (commas(a.price) + ' تومان') : 'پس پرداخت')}</span>
                                                        </div>
                                                        <div class="btn btn-primary btn-rounded" style={{ minWidth: '40px', padding: '.5rem' }} onClick={() => {
                                                            setSelectedShipment((a.id).toString());
                                                        }}>
                                                            {
                                                                (selectedShipment !== (a.id).toString())
                                                                    ?
                                                                    'انتخاب'
                                                                    :
                                                                    '✔'
                                                            }
                                                        </div>
                                                    </li>)
                                                })
                                                :
                                                <p className="text-center font-weight-bold my-2">شیوه ارسال فعالی وجود ندارد.</p>
                                        }
                                    </ul>



                                    {
                                        parsed.has_vip === 'true'
                                            ?
                                            <>


                                                <h6 className="font-weight-bold mt-2 px-3">انتخاب شیوه پرداخت:</h6>

                                                <ul className="list-group">
                                                    {
                                                        paymentTypes && paymentTypes.length
                                                            ?
                                                            paymentTypes.map((a, i) => {
                                                                return (<li key={a.id} className={'list-group-item d-flex align-items-center justify-content-between ' + ((selectedPayment !== (a.id).toString()) ? 'list-group-item-light' : 'list-group-item-secondary')}>
                                                                    <div className="d-flex flex-column">
                                                                        <span>{a.display_title}</span>
                                                                    </div>
                                                                    <div class="btn btn-primary btn-rounded" style={{ minWidth: '40px', padding: '.5rem' }} onClick={() => {
                                                                        setSelectedPayment((a.id).toString());
                                                                    }}>
                                                                        {
                                                                            (selectedPayment !== (a.id).toString())
                                                                                ?
                                                                                'انتخاب'
                                                                                :
                                                                                '✔'
                                                                        }
                                                                    </div>
                                                                </li>)
                                                            })
                                                            :
                                                            <p className="text-center font-weight-bold my-2">شیوه ارسال فعالی وجود ندارد.</p>
                                                    }
                                                </ul>


                                            </>
                                            :
                                            ''
                                    }




                                    {/* 
                                    <div className="d-flex align-items-center py-1 py-sm-3">
                                        {paymentTypes.length === 2 ? <div className="flex-shrink-0 small font-weight-bold">شیوه پرداخت :</div> : ''}
                                        {paymentTypes.length === 2 ? <div className="flex-fill d-flex align-items-center justify-content-end">
                                            <span className="small d-block text-center" style={{ width: '50px' }}>{paymentTypes[0].display_title}</span>
                                            <div
                                                style={{
                                                    margin: '0 5px',
                                                    position: 'relative',
                                                    width: '30px', // Width of the switch
                                                    height: '17px', // Height of the switch
                                                    backgroundColor: paymentTogglerIsOn ? 'rgb(163, 220, 165)' : 'rgb(149, 221, 222)', // Background color based on state
                                                    borderRadius: '17px', // Rounded corners
                                                    cursor: 'pointer',
                                                    transition: 'background-color 0.2s ease-in-out',
                                                }}
                                                onClick={paymentToggle} // Trigger toggle on click
                                            >
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        width: '13px', // Width of the knob
                                                        height: '13px', // Height of the knob
                                                        backgroundColor: '#fff',
                                                        borderRadius: '50%', // Make it circular
                                                        transition: 'transform 0.3s ease-in-out',
                                                        transform: paymentTogglerIsOn ? 'translateX(13px)' : 'translateX(0)', // Move the knob
                                                        top: '2px', // Vertical alignment
                                                        left: '2px', // Initial horizontal alignment
                                                    }}
                                                ></div>
                                            </div>
                                            <span className="small d-block text-center" style={{ width: '50px' }}>{paymentTypes[1].display_title}</span>
                                        </div> : ''}
                                    </div> */}

                                    <div className="btn-wrap d-flex justify-content-end mt-2">
                                        <div className="btn btn-outline-primary btn-rounded" onClick={handleOrder}><span>ثبت سفارش</span><i
                                            className="icon-long-arrow-left"></i></div>
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </main>






        </CommonLAyout>
    );
};
import { React, useState, useEffect, useContext } from 'react';
import { apiClient } from '../../Services/Api';
import { commas } from '../../Utils/Commas';
import {
    useLocation,
    Link
} from 'react-router-dom';
import moment from 'jalali-moment';
// (query-string) from npm
const queryString = require('query-string');
export default function BankResult(props) {

    let location = useLocation();
    const parsed = queryString.parse(location.search);
    const [paymentId, setPaymentId] = useState(null);
    const [details, setDetails] = useState(null);
    const [failed, setFailed] = useState(false);
    useEffect(() => {
        if (parsed && parsed.payment_id) {
            setPaymentId(parsed.payment_id);
        }
    }, [location]);
    useEffect(() => {
        if (paymentId) {
            apiClient.get('transaction/' + paymentId).then(response => {
                if (response.request.status === 200) {
                    setDetails(response.data.data);
                } else {
                    setFailed(true);
                }
            }).catch(error => {
                setFailed(true);
            });
        }
    }, [paymentId]);
    return (
        <div className="container py-5">
            <div className="py-5 mx-auto" style={{ maxWidth: '400px' }}>
                {
                    failed &&
                    !details &&
                    <div className="mt-5">
                        <div className="text-center bold text-red">
                            درخواست با خطا مواجه شد.
                        </div>
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            <Link to="/dashboard" className="btn btn-outline-primary-2 btn-order float-left btncart2">داشبورد</Link>
                        </div>
                    </div>
                }
                {
                    !failed &&
                    !details &&
                    <div className="mt-5 d-flex justify-content-center">
                        {/* <img src="/img/spinner.gif" style={{ width: '100px' }} /> */}
                        <div className="text-center">لطفا صبر کنید...</div>
                    </div>
                }
                {
                    details &&
                    <div>
                        {
                            details.is_successful
                                ?
                                <div className="text-center bold text-blue">
                                    عملیات موفق
                                </div>
                                :
                                <div className="text-center text-red">
                                    عملیات ناموفق
                                </div>
                        }
                        <div className="mt-2 bg-gray rounded rounded-sm py-3 px-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="text-dark-blue small bold px-1">
                                    شماره فاکتور:
                                </div>
                                <div className="text-dark-blue small bold px-1">
                                    {details.invoice_number}
                                </div>
                            </div>
                            {
                                details.reference_id &&
                                <div>
                                    <hr className="my-2" />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="text-dark-blue small bold px-1">
                                            شماره مرجع:
                                        </div>
                                        <div className="text-dark-blue small bold px-1">
                                            {details.reference_id}
                                        </div>
                                    </div>
                                </div>
                            }
                            <hr className="my-2" />
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="text-dark-blue small bold px-1">
                                    مبلغ:
                                </div>
                                <div className="text-dark-blue small bold px-1">
                                    {commas(details.amount) + ' تومان'}
                                </div>
                            </div>
                            {
                                details.paid_at &&
                                Boolean(details.paid_at.length) &&
                                <div>
                                    <hr className="my-2" />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="text-dark-blue small bold px-1">
                                            تاریخ:
                                        </div>
                                        <div className="text-dark-blue small bold px-1">
                                            {
                                                moment(
                                                    new Date(moment.from(details.paid_at, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss') + ' UTC').getFullYear() + '-' +
                                                    (new Date(moment.from(details.paid_at, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss') + ' UTC').getMonth() + 1) + '-' +
                                                    new Date(moment.from(details.paid_at, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss') + ' UTC').getDate()
                                                    , 'YYYY-MM-DD').locale('fa').format('YYYY/MM/DD') + ' - ' +
                                                new Date(moment.from(details.paid_at, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss') + ' UTC').getHours() + ':' +
                                                new Date(moment.from(details.paid_at, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss') + ' UTC').getMinutes()
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                details.description &&
                                Boolean(details.description.length) &&
                                <div>
                                    <hr className="my-2" />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="text-dark-blue small bold px-1">
                                            توضیحات:
                                        </div>
                                        <div className="text-dark-blue small bold px-1">
                                            {details.description}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            <Link to="/dashboard" className="btn btn-outline-primary-2 btn-order float-left btncart2">داشبورد</Link>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
